import AssessmentLayout from "@/components/Layout/Assessment";
import ContentBar from "@/components/ContentBar";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import MetricsTable from "@/features/report/components/ad-reports/tables/metrics/MetricsTable";
import BiddingStrategiesTable from "@/features/report/components/ad-reports/tables/metrics/BiddingStrategiesTable";
import Breadcrumb from "@/components/breadcrumb/breadcrumb";

const MetricsPage = () => {
  const { t } = useTranslation();
  const { projectId, clientId, reportId } = useParams();

  return (
    <div>
      <AssessmentLayout>
        <ContentBar title={t("metrics")} />
        <Breadcrumb reportId={reportId} clientId={clientId} projectId={projectId} />
        <div>
          <MetricsTable tableId="metrics" />
          <BiddingStrategiesTable tableId="bidingStrategies" />
        </div>
      </AssessmentLayout>
    </div>
  );
};

export default MetricsPage;
