import React, { createContext, useState, ReactNode } from "react";

export interface GlobalState {
  searchQuery?: string;
}

// Context'in tipini tanımlama
interface GlobalContextType {
  globalState: GlobalState;
  setGlobalState: React.Dispatch<React.SetStateAction<GlobalState>>;
}

// Başlangıç değerlerini tanımlama
const defaultState: GlobalState = {
  searchQuery: "",
};

export const GlobalContext = createContext<GlobalContextType>({
  globalState: defaultState,
  setGlobalState: () => {},
});

// Provider bileşeni
export const GlobalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [globalState, setGlobalState] = useState<GlobalState>(defaultState);

  return (
    <GlobalContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </GlobalContext.Provider>
  );
};
