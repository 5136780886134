import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";
import { fetchStatus } from "@/api/analytics/googleAnalytics";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import PopupModal from "@/components/PopupModal/PopupModal";
import { createIntegrationEvent } from "@/analytics/mixpanel";
import { userAtom } from "@/store/atoms/user.atoms";
import { useRecoilState } from "recoil";
import { fetchProjectData, postProjectData } from "@/api/project";
import { ProjectDataType } from "@/models/projectDataType";
import Select from "react-select";
import { postNewProject, postSelectProperty } from "@/api/eAnl";
import { t } from "i18next";

const AnalyticsPropertiesPage = () => {
  const { projectId, isLoading: isLoadingIds } = useFetchingProjectIds();
  const [isPopup, setIsPopup] = useState(true);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showError, setShowError] = React.useState("");

  const [selectAccount, setSelectAccount] = useState<any>("");
  const [selectProperty, setSelectProperty] = useState<any>("");
  const [user] = useRecoilState(userAtom);

  const { mutateAsync, isLoading } = useMutation(postSelectProperty, {
    retry: false,
  });

  const { data, isLoading: isLoadingProperties } = useQuery(
    "fetchGoogleAnalyticsProperties",
    () => postNewProject(projectId),
    {
      retry: false,
      enabled: !!projectId,
      refetchOnWindowFocus: false,
    }
  );

  const [selectPropertyId, setSelectPropertyId] = useState<number | null>(null);
  const handleSelect = async () => {
    setShowError("");
    if (selectProperty && projectId && selectPropertyId) {
      const argument = {
        projectId: projectId,
        propertyName: selectProperty,
        propertyId: selectPropertyId
      };
      try {
        await mutateAsync(argument);
        handleFinishTask("connectAccount");
      } catch (e: any) {
        const errMessage = e.response?.data;
        setShowError(errMessage?.message || errMessage?.detail || "Something went wrong");
      }
    } else {
      setShowError("Please select all fields.");
    }
  };

  const { mutateAsync: postMutate } = useMutation("postProjectDomainData", postProjectData, {
    retry: false,
  });

  const handleFinishTask = (key: string) => {
    fetchProjectData({ projectId: projectId, name: "GETTING_STARTED" })
      .then((res) => {
        const data = res.data.value;
        // object key count
        const count = Object.keys(data).length;
        if (count === 3) {
          //   setIsOpenTable(true);
        }
        postMutate({
          projectId,
          project: {
            datatype_name: ProjectDataType.GETTING_STARTED,
            data: {
              value: {
                ...data,
                [key]: true,
              },
            },
          },
        });
      })
      .catch(() => {
        postMutate({
          projectId,
          project: {
            datatype_name: ProjectDataType.GETTING_STARTED,
            data: {
              value: {
                [key]: true,
              },
            },
          },
        });
      })
      .finally(() => {
        setShowSuccess(true);
        createIntegrationEvent({
          connectionStatus: "success",
          integrationName: "google_analytics",
          userId: user?.id.toString() || "",
          email: user?.email || "",
        });
      });
  };

  const {
    data: status,
    isSuccess,
    isLoading: isLoadingStatus,
  } = useQuery("fetchStatus", () => fetchStatus(projectId), {
    retry: false,
    enabled: !!projectId,
  });

  useEffect(() => {
    if (isSuccess)
      if (status?.data?.googleAnalytics && status?.data?.integration) {
        window.location.href = "/integrations";
      }
  }, [isSuccess]);

  return (
    <div className="flex justify-center items-center mt-10 flex-col w-full mb-10">
      <div className="w-full pl-16 text-gray-700 italic underline ">
        <a href="/integrations">{"<"} {t("backToIntegrationsPage")} </a>
      </div>
      <div>
        <h1 className="text-3xl font-bold text-gray-700 mt-2">Google Analytics</h1>
        <div>
          <DefaultButton
            className="mt-10"
            onClick={() => {
              setIsPopup(true);
              setSelectAccount("");
              setSelectProperty("");
            }}>
            View Accounts and Properties
          </DefaultButton>
        </div>
      </div>
      <div className="w-full flex justify-center flex-col items-center">
        {isLoadingProperties || isLoadingStatus || isLoadingIds ? (
          <LoadingSpinner />
        ) : (
          <PopupModal closeAction={() => setIsPopup(false)} isPopup={isPopup}>
            <div className="capitalize border-2 border-gray-200 py-6 px-12 rounded-lg flex flex-col gap-3">
              <div className="mb-2 font-medium text-lg ">Google Analytics Properties</div>

              <div>
                <div>Account:</div>
                <Select
                  onChange={(e: any) => {
                    setSelectAccount(e.value);
                    setSelectProperty(null);
                  }}
                  options={
                    data &&
                    data.data.Accounts?.map((item: any) => ({
                      value: item.Name,
                      label: item.DisplayName,
                    }))
                  }
                />
              </div>

              <div>
                {selectAccount && data && (
                  <div>
                    <div>Properties:</div>
                    <Select
                      options={
                        data.data.Accounts.find((account: any) => account.Name === selectAccount)?.Properties.map((property: any) => ({
                          value: property.Id,
                          label: property.DisplayName,
                        })) || []
                      }
                      onChange={(selectedOption: any) => {
                        const property = data.data.Accounts.find((account: any) => account.Name === selectAccount)?.Properties.find((property: any) => property.Id === selectedOption.value);
                        if (selectedOption) {
                          setSelectProperty(property.Name);
                          setSelectPropertyId(property.Id);
                        }
                      }}
                    />
                  </div>
                )}
              </div>


              <DefaultButton
                isDisabled={isLoading}
                isLoading={isLoading}
                onClick={() => handleSelect()}
                className="mt-4">
                {t("createIntegration")}
              </DefaultButton>
              {showSuccess && (
                <div className="normal-case w-full p-3 bg-green-200 rounded mt-3 text-sm">
                  {t("analyticsPropertiesPageSuccess")}
                  <a href="/integrations" className="text-blue-500 underline">
                    {t("goBack")}
                  </a>
                </div>
              )}
              {showError && (
                <div className="normal-case w-full p-3 bg-red-200 rounded mt-3 text-sm">
                  Error: {showError}
                </div>
              )}
            </div>
          </PopupModal>
        )}
      </div>
    </div>
  );
};

export default AnalyticsPropertiesPage;
