import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MdClose } from "react-icons/md";
import { IoMdDownload } from "react-icons/io";
import RightModal from "@/components/Modal/RightModal";
import PrimaryButton from "@/components/buttons/PrimaryButton";

type Props = {
  open: boolean;
  onClose: () => void;
  imageUrl: string;
};

const ImagePreviewModal = (props: Props) => {
  const { open, onClose, imageUrl } = props;
  return (
    <RightModal
      open={open}
      onClose={onClose}
      size="w-5/12"
      action={
        <PrimaryButton href={imageUrl} icon={<IoMdDownload />} name="Download" className="w-full" />
      }>
      <div className=" flex flex-col items-center p-6">
        <div className="flex w-full h-full items-center justify-center p-4 text-center sm:items-center ">
          <img
            className="max-w-full max-h-full object-fit shadow-xl"
            src={imageUrl}
            alt="preview"
          />
        </div>
      </div>
    </RightModal>
  );
};

export default ImagePreviewModal;
