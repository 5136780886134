import { useMutation, useQuery } from "react-query";

import PopupModal from "@/components/PopupModal/PopupModal";
import { useEffect, useState } from "react";
import { LinkedIn } from "react-linkedin-login-oauth2";
import WhiteButton from "@/components/buttons/WhiteButton";

import { fetchLinkedinTokenStatus, removeLinkedinConnection } from "@/api/linkedinAuth";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { t } from "i18next";
import { useRecoilState } from "recoil";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";

interface Props {
  title: string;
  description: string;
  imgSrc?: string;
}

const LinkedinAuthCard = (props: Props) => {
  const [project] = useRecoilState(projectInfo);
  const [openModal, setOpenModal] = useState(false);

  const { title, description } = props;

  const { data: tokenStatus, refetch } = useQuery(
    "fetchLinkedinTokenStatus",
    () => fetchLinkedinTokenStatus(project.id),
    {
      retry: false,
      enabled: !!project.id,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (tokenStatus) {
      setTimeout(() => {
        refetch();
      }, 2000);
    }
  }, [tokenStatus]);

  const handleLinkedInLogin = () => {
    // LinkedIn OAuth2 işlemi için gerekli olan URL'yi oluşturun
    const clientId = "773pysubg0i6cd";
    const redirectUri = encodeURIComponent(`${window.location.origin}/linkedin/callback`);
    const scope = encodeURIComponent(
      "r_organization_social rw_organization_admin r_1st_connections_size r_organization_admin r_ads_reporting r_emailaddress r_liteprofile r_basicprofile r_ads rw_ads w_member_social w_organization_social"
    );
    const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`;

    // LinkedIn OAuth2 işlemi için oluşturulan URL'yi mevcut pencerede aç
    window.location.href = authUrl;
  };

  const { mutateAsync: disconnectLinkedin, isLoading: isLoadingRemove } =
    useMutation(removeLinkedinConnection);

  const handleDisconnect = () => {
    disconnectLinkedin(project.id).then(() => {
      window.location.reload();
    });
  };

  return (
    <div className="bg-bgCardColor max-w-7xl rounded-lg shadow p-6 ">
      <>
        <PopupModal
          closeAction={() => setOpenModal(false)}
          title=""
          isPopup={openModal}
          width="max-w-lg">
          <>
            <div>{t("Are you sure you want to disconnect Linkedin?")}</div>
            <div className="flex flex-col gap-2">
              <DefaultButton isLoading={isLoadingRemove} onClick={() => handleDisconnect()}>
                {t("remove")}
              </DefaultButton>
              <WhiteButton onClick={() => setOpenModal(false)}>{t("cancel")}</WhiteButton>
            </div>
          </>
        </PopupModal>
        <img src="/assets/icons/linkedin.png" alt="gas" width={46} className="mb-2" />
        <div className="text-lg font-semibold ">{title}</div>
        <div className="my-3 text-base ">{description}</div>
        <div className="flex justify-end pt-3">
          {tokenStatus?.status === "valid" ? (
            <>
              <button
                className="text-red-700 bg-red-100 font-medium py-1 px-4 rounded border border-red-400
                    hover:bg-red-200 hover:border-red-500 transition-all text-sm flex items-center"
                onClick={() => setOpenModal(true)}>
                <img src="/assets/icons/linkedin.png" alt="gas" width={30} className="mr-2" />
                {t("disconnect")}
              </button>
            </>
          ) : (
            <DefaultButton className="w-fit" onClick={handleLinkedInLogin}>
              {t("connect")}
            </DefaultButton>
          )}
        </div>
      </>
    </div>
  );
};

export default LinkedinAuthCard;
