import { Dialog, Transition } from "@headlessui/react";
import React, { ReactNode, RefObject, useEffect, useRef } from "react";
import { MdClose } from "react-icons/md";

type Props = {
  open: boolean;
  children?: ReactNode;
  action?: ReactNode;
  onClose: () => void;
  title?: string;
  size?: string;
  backgroundColor?: string;
  scrollToBottom?: number;
};

const RightModal = (props: Props) => {
  const {
    scrollToBottom,
    open,
    action,
    backgroundColor = "bg-white",
    children,
    onClose,
    size = "w-5/12",
  } = props;
  const scrollArea = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollToBottom) {
      if (scrollArea.current) {
        scrollArea.current.scroll({
          top: scrollArea.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }
  }, [scrollToBottom]);

  return (
    <Transition show={open} as={React.Fragment}>
      <Dialog className="z-50 relative" onClose={onClose}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          as={React.Fragment}>
          <div className="fixed z-30 inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 flex justify-end w-screen  h-screen ">
          <div className={"flex items-start justify-center  text-center  h-screen " + size}>
            <Transition.Child
              enter="transition ease-in-out duration-500 transform"
              enterFrom="translate-x-[100%] "
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-500 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-[100%]"
              as={React.Fragment}>
              <Dialog.Panel className="relative transform flex flex-col justify-start overflow-hidden items-start w-full transition-all  ">
                <button
                  className="absolute top-4 right-4 z-40 border-0 outline-0"
                  onClick={onClose}>
                  <MdClose className="text-xl" />
                </button>

                <div
                  ref={scrollArea}
                  className={
                    " w-full overflow-y-auto pb-6 " +
                    (action ? "h-[calc(100vh-60px)] " : " h-screen ") +
                    backgroundColor
                  }>
                  {children}
                </div>
                {action && (
                  <div className="bg-white h-[60px] flex items-center justify-center gap-2 w-full px-6 border-t border-gray-200">
                    {action}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default RightModal;
