import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const PaymentPage = () => {
  const location: any = useLocation();

  useEffect(() => {
    if (location?.state?.checkoutFormContent) {
      const scriptEl = document
        .createRange()
        .createContextualFragment(location.state.checkoutFormContent);

      document.head.appendChild(scriptEl);

      return () => {
        // document.head.removeChild(script);
        // document.head.removeChild(scriptEl);
      };
    }
  }, [location.state]);

  return (
    <div className="h-screen flex justify-center items-center flex-col">
      <Link
        to={{
          pathname: "/",
        }}>
        <img className="mb-6 h-12 w-auto" src="/assets/icons/logoPrivate.svg" alt="Your Company" />
      </Link>
      <div id="iyzipay-checkout-form" className="responsive"></div>
      <div className="flex  mt-4 gap-2">
        <div className="text-base font-medium pt-1">Powered by </div>
        <a href={`http://earnado.com/`} target="_blank">
          <img className="h-6 w-auto" src="/assets/icons/earnado-black.png" alt="Your Company" />
        </a>
      </div>
    </div>
  );
};

export default PaymentPage;
