import React from "react";

type Props = {
  children: any;
  className?: string;
  row?: boolean;
  col?: boolean;
};

const Card = ({ children, className, row, col }: Props) => {
  const cardClass =
    " w-full py-4 px-3 flex justify-start items-start  gap-6 bg-white relative hover:shadow-lg transition-all rounded-md border border-gray-300 cursor-pointer " +
    className +
    (col ? " flex-col " : null) +
    (row ? " flex-row " : null);
  return <div className={cardClass}>{children}</div>;
};

export default Card;
