import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  title?: string;
  description?: string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, title, description }) => {
  const modalRef = useRef(null);
  const modalRoot = document.getElementById("modal-root");

  // Close modal on ESC key press
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  if (!isOpen || !modalRoot) return null;

  return ReactDOM.createPortal(
    <div className="z-[102] modal-overlay flex overflow-x-hidden overflow-y-auto" onClick={onClose}>
      <div className="modal-content" ref={modalRef} onClick={(e) => e.stopPropagation()}>
        <div className="bg-gray-50 p-4">
          <div className="text-lg font-medium flex justify-between items-center">
            {title}
            <button
              type="button"
              className="inline-flex self-start justify-center px-2 font-medium text-gray-900 border border-transparent rounded-md hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 transition-all"
              onClick={onClose}>
              {"X"}
            </button>
          </div>

          <div className="text-sm text-gray-500">{description}</div>
        </div>
        <div className="bg-white p-4">{children}</div>
      </div>
    </div>,
    modalRoot as Element
  );
};

export default Modal;
