import React, { useEffect, useState } from "react";
import ProfileInformationUpdate from "../ProfileInformationUpdate";
import RightModal from "@/components/Modal/RightModal";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import { authMe } from "@/api/auth";
import { useQuery } from "react-query";
import ModalLoading from "@/components/Loading/ModalLoading";

interface Props {
  open?: boolean;
  onClose?: () => void;
  refetch?: () => void;
  size?: string;
}

export default function PaymentModal(props: Props) {
  const { size, open = false, onClose, refetch } = props;
  const [profileUpdate, setProfileUpdate] = useState(false);

  const {
    data: authData,
    refetch: refetchAuthData,
    isLoading: isLoadingAuth,
  } = useQuery("authmeProfileData", authMe);

  useEffect(() => {
    if (!authData?.invoice_type || !authData?.address?.country) {
      setProfileUpdate(true);
      return;
    } else {
      setProfileUpdate(false);
    }
  }, [authData]);

  console.log(profileUpdate);

  return (
    <>
      {isLoadingAuth ? (
        <ModalLoading />
      ) : (
        <RightModal
          size={size}
          open={open}
          onClose={() => (onClose ? onClose() : null)}
          action={
            <PrimaryButton name="Pay" onClick={() => console.log("clcik")} className="w-full" />
          }>
          <div className="w-full h-full flex  flex-1 flex-col justify-start items-start text-left  px-6 pt-14 gap-y-3  ">
            Payment
            <ProfileInformationUpdate
              size="w-3/12"
              open={profileUpdate}
              onClose={() => {
                setProfileUpdate(false);
                if (onClose) {
                  onClose();
                }
              }}
              refetch={() => refetchAuthData()}
            />
          </div>
        </RightModal>
      )}
    </>
  );
}
