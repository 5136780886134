import client from "./client";

export const createTicket = async (data: any) => {
  const response = await client.post(`/ticket`, data);
  return response.data;
};

export const getTickets = async (data: {
  ticket_id?: number;
  project_story_id?: number;
  project_id?: number;
  user_id?: number;
  skip?: number;
  limit?: number;
  order?: string;
}) => {
  const response = await client.get(`/ticket`, {
    params: {
      ticket_id: data.ticket_id,
      project_story_id: data.project_story_id,
      project_id: data.project_id,
      user_id: data.user_id,
      skip: data.skip,
      limit: data.limit,
      order: data.order,
    },
  });
  return response.data;
};
