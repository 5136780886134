import { fetchCreditsInformation } from "@/api/account";
import { useTranslation } from "react-i18next";
import { MdOutlineWarning } from "react-icons/md";
import { useQuery } from "react-query";
import SecondaryButton from "../buttons/SecondaryButton";

type Props = {
  projectId: number;
  projectPlan?: any;
  className?: string;
};

const CapacityBar = (props: Props) => {
  const { t } = useTranslation();
  const { className, projectId, projectPlan } = props;
  const { data, isLoading: creditInformationLoading } = useQuery(
    "fetchCreditInformation",
    () =>
      fetchCreditsInformation({
        project_id: projectId,
      }),
    {
      retry: false,
      enabled: !!projectId,
      cacheTime: 0,
    }
  );
  const remainingCredit = data?.remaining_credit;
  const usedCredit = data?.used_automated_designer_credit;

  const progressBar = usedCredit / remainingCredit;

  return projectPlan.id !== 1 ? (
    <div
      className={
        "p-3 mb-6 flex flex-col w-full bg-white border border-gray-300 rounded-lg shadow-lg " +
        className
      }>
      <div className="flex items-start justify-between w-full">
        <span className="text-md font-semibold">{t("credits")}</span>
        <span className="text-md font-semibold">{remainingCredit + " / " + usedCredit}</span>
      </div>
      <div className="mt-2 w-full border border-gray-500 bg-gray-200 rounded-md p-1">
        <div className="bg-primaryColor h-3 rounded-md" style={{ width: `${progressBar}%` }}>
          <span className="sr-only">{usedCredit}% Full</span>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={
        "p-3 mb-6 flex items-center justify-between w-full bg-red-200 border border-red-400 text-red-700 rounded-lg shadow-lg " +
        className
      }>
      <span className="flex items-center gap-2">
        <MdOutlineWarning />
        {t("needSubscribeForUseDesigner")}
      </span>
      <SecondaryButton to="/profile/plans" name={t("subscribeNow")} />
    </div>
  );
};

export default CapacityBar;
