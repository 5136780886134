import React, { useEffect, useState, useRef } from "react";
import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { MdClose } from "react-icons/md";

interface Options {
  id: any;
  value: string;
}

interface Props {
  data: Options[];
  label?: string;
  defaultValue?: Options[] | null;
  onChange: (selected: Options[]) => void;
  className?: string;
}

const classNames = (...classes: string[]) => classes.filter(Boolean).join(" ");

const MultipleSelect: React.FC<Props> = ({
  data,
  defaultValue,
  className,
  label,
  onChange,
}: Props) => {
  const [selected, setSelected] = useState<Options[]>(defaultValue || []);
  const [openUpwards, setOpenUpwards] = useState(false);
  const listboxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    const handleScroll = () => {
      if (listboxRef.current) {
        const { bottom } = listboxRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        setOpenUpwards(bottom + 200 > viewportHeight);
      }
    };
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);
    handleScroll(); // Initial check
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  const handleSelect = (items: Options[]) => {
    const selectedIds = items.map((item) => item.id);
    setSelected(items);
    onChange(selectedIds);
  };

  const removeItem = (id: any, event: React.MouseEvent<any, MouseEvent>) => {
    event.stopPropagation(); // Prevent event from bubbling up
    const newSelected = selected.filter((s) => s.id !== id);
    setSelected(newSelected);
    onChange(newSelected);
  };

  return (
    <Listbox value={selected} onChange={handleSelect} multiple>
      <div className="relative w-full" ref={listboxRef}>
        <div className={"flex flex-col items-start justify-start gap-2 " + className}>
          {label && <label className="text-sm font-semibold">{label}</label>}
          <Listbox.Button className="min-h-input w-full flex items-center justify-between rounded-md border bg-white px-2 border-gray-300 py-1">
            <div className="flex gap-1 items-center flex-wrap">
              {selected.length > 0 ? (
                selected.map((item) => (
                  <span key={item.id} className="flex items-center bg-gray-200 px-2 py-1 rounded">
                    {item.value}
                    <MdClose
                      className="ml-1 cursor-pointer"
                      onClick={(event) => removeItem(item.id, event)}
                    />
                  </span>
                ))
              ) : (
                <span className="text-sm text-gray-500">Select options</span>
              )}
            </div>
            <span className="pointer-events-none flex items-center">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>

          <Listbox.Options
            className={classNames(
              "absolute z-10 max-h-56 min-w-[220px] overflow-auto rounded-md bg-white p-1 text-base shadow-lg focus:outline-none text-sm border border-gray-300",
              openUpwards ? "bottom-[40px]" : "top-full mt-2"
            )}>
            {data
              .filter((item) => !selected.some((s) => s.id === item.id))
              .map((item) => (
                <Listbox.Option
                  key={item.id}
                  className={({ active }) =>
                    classNames(
                      active ? "bg-gray-200" : "text-gray-900",
                      "relative cursor-default select-none py-2 pr-9"
                    )
                  }
                  value={item}>
                  {({ active }) => (
                    <>
                      <div className="flex items-center">
                        <span className={classNames("ml-3 block truncate")}>{item.value}</span>
                      </div>
                      {selected.some((s) => s.id === item.id) && (
                        <span
                          className={classNames(
                            active ? "text-secondaryHoverColor" : "text-secondaryHoverColor",
                            "absolute inset-y-0 right-0 flex items-center pr-4"
                          )}>
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Listbox.Option>
              ))}
          </Listbox.Options>
        </div>
      </div>
    </Listbox>
  );
};

export default MultipleSelect;
