import { useState } from "react";
import AdsOverview from "@/features/report/components/AdsReportContent/AdsOverview";
import { useQuery } from "react-query";
import { fetchProjectMetrics } from "@/api/reports";
import useGoogleAdsLogin from "@/hooks/use-google-login";
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { subDays } from "date-fns";
import AdMetricsChart from "@/components/Charts/AdMetricsChart";

type Props = {
  data: any;
};

const AdsReport = (props: Props) => {
  const { data } = props;
  const customerClientIds = data.metrics[0].data.map((i: any) => ({
    clientId: i.customerClientId,
    clientName: i.customerClientName,
  }));
  const [selectedClientId, setSelectedCLientId] = useState(customerClientIds[0].clientId);

  const [range] = useState([
    {
      // before: 30 days
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const { isSuccess: isSuccessGoogle, isLoadingGoogleAdsLogin } = useGoogleAdsLogin();

  const { projectId, isLoading: isLoadingProjectId } = useFetchingProjectIds();

  const { data: metrics, isLoading } = useQuery(
    ["fetchProjectMetrics", range],
    () =>
      fetchProjectMetrics({
        projectId: projectId,
        startDate: range[0].startDate as any,
        endDate: range[0].endDate as any,
      }),
    {
      retry: false,
      enabled: !!projectId && isSuccessGoogle,
    }
  );

  return (
    <div className="flex flex-col flex-wrap gap-4 md:gap-10 pb-4 mt-4">
      {isLoading || isLoadingProjectId || isLoadingGoogleAdsLogin ? (
        <LoadingSpinner />
      ) : (
        <>{metrics && metrics.length && <AdMetricsChart data={metrics} />}</>
      )}

      {data?.metrics.map((metric: any, index: any) => (
        <div className="w-full" key={index}>
          {metric.metric_identifier === "ads_report_metric" && (
            <div className="flex flex-col gap-12">
              {metric?.data?.map((data: any, index: any) => (
                <AdsOverview key={index} reportDetail={data} isSample={metric?.sample} />
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default AdsReport;
