import React, { useContext, useEffect, useState } from "react";
import AssessmentLayout from "@/components/Layout/Assessment";
import ContentBar from "@/components/ContentBar";
import { t } from "i18next";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { userAtom } from "@/store/atoms/user.atoms";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import PageLoading from "@/components/Loading/PageLoading";
import { GlobalContext } from "@/context/GlobalStateProvider";
import { fetchProjectMainTasks } from "@/api/task";
import CategoryLabel from "@/components/CategoryLabel";
import SideModal from "@/components/Modal/SideModal";
import CreateTask from "./CreateTask";
import SelectTaskFilter from "./SelectTaskFilter";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import { useNavigate, useParams } from "react-router-dom";
import TaskDetail from "./TaskDetail";
import InfoBoard from "@/components/cards/InfoCard";
import { MdAdd, MdPlusOne } from "react-icons/md";
import SecondaryButton from "@/components/buttons/SecondaryButton";
import RightModal from "@/components/Modal/RightModal";
import StatusLabel from "@/components/StatusLabel";
import { FormatDate } from "@/utils/FormatDate";
import { dateFormatter } from "@/utils/dateFormatter";
import { IoMdTime } from "react-icons/io";

type Task = {
  name: string;
  label: string;
  labelColor: string;
  due_date: string;
  status: any;
  parent: string;
  project_story_id?: number;
  show_approve_button?: boolean;
};

const tasklist = {
  items: [
    {
      id: "name",
      label: "Name",
      alignment: "left",
      render: (r: Task) => r.name,
      searchable: true,
    },
    {
      id: "label",
      label: "Label",
      alignment: "center",
      render: (r: Task) => r.label,
      searchable: false,
    },
    {
      id: "end_date",
      label: "Due Date",
      alignment: "left",
      render: (r: Task) => dateFormatter(r.due_date),
      searchable: true,
    },
    {
      id: "status",
      label: "Status",
      alignment: "center",
      render: (r: Task) => r.status && <StatusLabel status={r.status} />,
      searchable: false,
    },
    {
      id: "parent",
      label: "Parent",
      alignment: "left",
      render: (r: Task) => r.parent,
      searchable: true,
    },
    {
      id: "action",
      label: "",
      alignment: "right",
      render: (r: Task) =>
        r.show_approve_button ? (
          <div className="flex justify-end">
            <div className="px-2 py-1 bg-white rounded-md border border-gray-200 w-auto justify-start shadow-md text-black flex items-center gap-2 min-w-10 ">
              <IoMdTime />
              Awaiting approval
            </div>
          </div>
        ) : (
          ""
        ),
      searchable: true,
    },
  ],
};

const Tasks = () => {
  const { projectStoryId } = useParams();
  const navigate = useNavigate();
  const [project] = useRecoilState(projectInfo);
  const [user] = useRecoilState(userAtom);
  const { globalState } = useContext(GlobalContext);
  const searchQuery = globalState.searchQuery || "";
  const [loading, setLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = React.useState<string | number>("");
  const [createModal, setCreateModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [filteredTasks, setFilteredTasks] = useState([]);

  const {
    data: tasks,
    isLoading,
    isFetched,
    refetch: refetchTasks,
  } = useQuery(
    "allTasks",
    () =>
      fetchProjectMainTasks({
        project_id: project.id,
        order_by: "create_date",
        order_direction: "desc",
      }),
    {
      retry: false,
      enabled: !!project.id,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (isFetched) {
      const filtered = tasks?.items
        .filter((row: Task) =>
          tasklist.items.some((item) => {
            const value = item.render(row);
            if (item.searchable && typeof value === "string") {
              return String(value).toLowerCase().includes(searchQuery.toLowerCase());
            }
            return false;
          })
        )
        .filter(
          (r: any) =>
            String(r.status.id).trim() === String(selectedStatus).trim() || !selectedStatus
        );
      setFilteredTasks(filtered);
      setLoading(false);
    }
  }, [tasks, selectedStatus, searchQuery]);

  const handleClickTask = (id: number | undefined) => {
    if (id !== undefined) {
      navigate(`/tasks/${id.toString()}`);
    }
  };

  useEffect(() => {
    if (projectStoryId) {
      setDetailModal(true);
    }
  }, [projectStoryId]);

  return (
    <div>
      <AssessmentLayout>
        <ContentBar title="Tasks" />
        {loading ? (
          <PageLoading />
        ) : (
          <div className="flex flex-col gap-6 w-full py-6">
            <SelectTaskFilter
              situations={tasks.status}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              actions={
                <SecondaryButton
                  icon={<MdAdd />}
                  name="Create Task"
                  onClick={() => setCreateModal(true)}
                />
              }
            />

            <div className="flex flex-col">
              <table className="min-w-full divide-y divide-gray-200 ">
                <thead className="bg-gray-100 sticky top-[70px] ">
                  <tr>
                    {tasklist.items.map((item: any, index: number) => (
                      <th
                        align={
                          item.alignment === undefined
                            ? undefined
                            : (item?.alignment as "left" | "center" | "right" | "justify" | "char")
                        }
                        className="py-2 px-2 font-semibold text-md "
                        key={index}>
                        {item.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                {filteredTasks.length !== 0 && (
                  <tbody className="bg-white divide-y divide-gray-200">
                    {filteredTasks.map((row: Task, index: number) => (
                      <tr
                        key={index}
                        onClick={() => handleClickTask(row?.project_story_id)}
                        className="cursor-pointer hover:bg-gray-100">
                        {tasklist.items.map((cell, cellIndex) => (
                          <td
                            align={
                              cell.alignment === undefined
                                ? undefined
                                : (cell?.alignment as
                                    | "left"
                                    | "center"
                                    | "right"
                                    | "justify"
                                    | "char")
                            }
                            key={cellIndex}
                            className="px-2 py-4  text-sm text-gray-900">
                            {typeof cell.render === "function" ? cell.render(row) : cell.render}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
              {filteredTasks.length === 0 && <InfoBoard title="No data found." />}
            </div>
            {createModal && (
              <CreateTask
                size="w-full sm:w-8/12 md:w-6/12 lg:w-4/12 xl:w-3/12"
                open={createModal}
                onClose={() => setCreateModal(false)}
                projectId={project.id}
                reFetch={() => refetchTasks()}
              />
            )}
            {detailModal && (
              <TaskDetail
                size="w-full sm:w-8/12 md:w-6/12 lg:w-4/12 xl:w-3/12"
                open={detailModal}
                projectId={project.id}
                projectStoryId={projectStoryId}
                refetch={() => refetchTasks()}
                onClose={() => {
                  setDetailModal(false);
                  navigate("/tasks");
                }}
              />
            )}
          </div>
        )}
      </AssessmentLayout>
    </div>
  );
};

export default Tasks;
