import React from "react";
import { Link } from "react-router-dom";
import { ButtonType } from "./buttons.interface";
import LoadingSpinner from "../UI/LoadingSpinner";

const BasicButton = (props: ButtonType) => {
  const { onClick, icon, loading, disabled, className, to, href, target, outline } = props;

  const fillClass =
    " h-6 w-6 rounded-full  flex  items-center justify-center  bg-white hover:bg-gray-200  ";

  const outlineClass =
    " h-6 w-6 rounded-full  flex  items-center justify-center  bg-white border border-gray-200 hover:gray-200  ";

  return to ? (
    <Link to={to} className={className + (outline ? outlineClass : fillClass)}>
      {icon && icon}
    </Link>
  ) : href ? (
    <a href={href} target={target} className={className + (outline ? outlineClass : fillClass)}>
      {icon && icon}
    </a>
  ) : (
    <button
      disabled={disabled}
      onClick={onClick}
      className={
        (disabled ? " opacity-60 " : "  ") + className + (outline ? outlineClass : fillClass)
      }>
      {loading ? <LoadingSpinner noPadding size="w-4 h-4" /> : icon && icon}
    </button>
  );
};

export default BasicButton;
