type Props = {
  title: string;
  description?: string;
};

const ContentBar = (props: Props) => {
  return (
    <div className="flex justify-between items-center z-[99] ">
      <div className="flex flex-col">
        <h2 className="text-xl leading-6 text-black font-regulars ">{props.title}</h2>
        {props.description && <span> {props.description} </span>}
      </div>
    </div>
  );
};

export default ContentBar;
