import { CheckCircleIcon } from "@heroicons/react/20/solid";
import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { postLinkedinAccessToken } from "@/api/linkedinAuth";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { useRecoilState } from "recoil";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";

const LinkedinCallbackPage = () => {
  // get query params
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const [project] = useRecoilState(projectInfo);

  const { mutateAsync, isLoading, isError } = useMutation(postLinkedinAccessToken);

  React.useEffect(() => {
    if (code) {
      mutateAsync({
        project_id: project.id,
        code: code,
        redirect_uri: window.location.origin + "/linkedin/callback",
      })
        .then((response) => {
          if (response) {
            setTimeout(() => {
              window.location.href = "/integrations?";
            }, 6000);
          }
        })
        .catch((err) => {
          setErrorMessage(err?.response?.data?.detail);
          setTimeout(() => {
            window.location.href = "/integrations?";
          }, 6000);
        });
    }
  }, [code]);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      {isLoading ? (
        <LoadingSpinner size="w-8 h-8" />
      ) : (
        <>
          {isError ? (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
              {errorMessage || "Something went wrong. Please try again."}
            </div>
          ) : (
            <>
              <CheckCircleIcon className="h-16 w-16 text-green-500 mb-4" />
              <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 m-2 rounded relative">
                You have successfully connected your Linkedin account.
              </div>
            </>
          )}
          <span className="mt-3 text-sm">
            You are being redirected to the integrations page.
            <span className="animate-pulse">Please wait.</span>
          </span>
        </>
      )}
    </div>
  );
};

export default LinkedinCallbackPage;
