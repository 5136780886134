import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon, PlayCircleIcon } from "@heroicons/react/20/solid";
import {
  fetchProjectData,
  fetchProjectRoadmapDetail,
  postProjectData,
  postProjectRoadmap,
} from "@/api/project";
import { useMutation, useQuery } from "react-query";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { roadmapDetailEvent, startingRoadmapEvent } from "@/analytics/mixpanel";
import { useRecoilState } from "recoil";
import { userAtom } from "@/store/atoms/user.atoms";
import { ProjectDataType } from "@/models/projectDataType";
import { t } from "i18next";
import { fetchMvpRoadmaps } from "@/api/mvp";
import RoadmapDetailLinkedWarningPopup from "../RoadmapDetailLinkedWarningPopup";

interface Props {
  projectId: any;
  open: boolean;
  setOpen: (state: boolean) => void;
  roadmapId: any;
  setOpenModal: (state: boolean) => void;
  refetch: () => void;
  selectedCard: any;
  label?: string;
}

export default function RoadmapDetailPopup(props: Props) {
  const {
    projectId,
    open,
    setOpen,
    roadmapId,
    setOpenModal,
    refetch: refetchRoadmap,
    selectedCard,
    label,
  } = props;

  const [user] = useRecoilState(userAtom);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    data: roadmap,
    isLoading,
    refetch,
    isSuccess,
  } = useQuery(
    ["fetchRoadmap2", roadmapId],
    () =>
      fetchProjectRoadmapDetail({
        project_id: projectId,
        roadmap_id: roadmapId,
      }),
    {
      retry: false,
      enabled: !!roadmapId,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      if (roadmap.name) {
        roadmapDetailEvent({
          roadmapName: roadmap.name,
          roadmapId: roadmap.id,
          userId: user?.id.toString() || "",
          email: user?.email || "",
        });
      }
    }
  }, [isSuccess]);

  const { mutateAsync: updateProjectData, isLoading: isLoadingUpdate } =
    useMutation(postProjectRoadmap);

  const { mutateAsync } = useMutation("postProjectDomainData", postProjectData, {
    retry: false,
  });

  const handleGettingStart = (key: string) => {
    fetchProjectData({ projectId: projectId, name: "GETTING_STARTED" })
      .then((res) => {
        const data = res.data.value;

        mutateAsync({
          projectId,
          project: {
            datatype_name: ProjectDataType.GETTING_STARTED,
            data: {
              value: {
                ...data,
                [key]: true,
              },
            },
          },
        });
      })
      .catch(() => {
        mutateAsync({
          projectId,
          project: {
            datatype_name: ProjectDataType.GETTING_STARTED,
            data: {
              value: {
                [key]: true,
              },
            },
          },
        });
      })
      .finally(() => {
        refetchRoadmap();
      });
  };

  const handleStart = () => {
    const autoStart = localStorage.getItem("autoStartStep");

    if (autoStart === "true") {
      handleGettingStart("roadmap");
      localStorage.removeItem("autoStartStep");
      updateProjectData({
        project_id: projectId,
        roadmap_id: roadmap.id,
      })
        .then(() => {
          refetch();
          setOpenModal(true);
          setOpen(false);
          startingRoadmapEvent({
            email: user?.email || "",
            userId: user?.id.toString() || "",
            roadmapName: roadmap.name,
            roadmapId: roadmap.id,
          });
        })
        .catch((err) => {
          setErrorMessage(err?.response?.data?.detail?.message);
          setOpenErrorModal(true);
        })
        .finally(() => {
          refetchRoadmap();
        });
    } else {
      updateProjectData({
        project_id: projectId,
        roadmap_id: roadmap.id,
      })
        .then(() => {
          refetch();
          setOpenModal(true);
          setOpen(false);
          startingRoadmapEvent({
            email: user?.email || "",
            userId: user?.id.toString() || "",
            roadmapName: roadmap?.name,
            roadmapId: roadmap?.id,
          });
        })
        .catch((err) => {
          setErrorMessage(err?.response?.data?.detail?.message);
          setOpenErrorModal(true);
        })
        .finally(() => {
          refetchRoadmap();
        });
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-400 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow transition-all sm:my-8 sm:w-full sm:max-w-4xl px-6 pt-8 pb-16">
                  <div className="h-96 overflow-auto">
                    <div className="absolute top-3 right-3   sm:block">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 "
                        onClick={() => setOpen(false)}>
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="mt-2 w-full">
                      {isLoading ? (
                        <LoadingSpinner size="w-6 h-6" />
                      ) : (
                        <>
                          <div className="px-6 pt-6  bg-white  rounded cursor-pointer  w-full min-h-[250px]">
                            <div className="text-lg font-bold">{roadmap?.name}</div>

                            <div className="mt-3">
                              <div className="border p-5 text-sm space-y-5">
                                <div className="grid grid-cols-2 gap-5 ">
                                  <div>
                                    <div className="text-gray-500">{t("strategyName")}</div>
                                    <div>{roadmap?.name}</div>
                                  </div>
                                  <div>
                                    <div className="text-gray-500">{t("strategyLabel")}</div>
                                    <div>{label}</div>
                                  </div>
                                  <div>
                                    <div className="text-gray-500">{t("operatingTime")}</div>
                                    <div>{selectedCard?.duration} {t("day")}</div>
                                  </div>
                                  <div>
                                    <div className="text-gray-500">{t("totalCredit")}</div>
                                    <div>{selectedCard?.total_credit} {t("credit")}</div>
                                  </div>
                                </div>
                                <div>
                                  <div>
                                    <div className="text-gray-500">{t("description")}</div>
                                    <div>{roadmap?.description}</div>
                                  </div>
                                </div>
                                <div>
                                  <div>
                                    <div className="text-gray-500">{t("tasks_plural")}</div>
                                  </div>
                                  <div className="border  rounded divide-y mt-2">
                                    {roadmap?.contents?.map((task: any, index: any) => {
                                      return (
                                        <div className="flex p-2 justify-between">
                                          <div>
                                            {index + 1} - {task.name}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="absolute bottom-5 right-10 ">
                              {/* {user?.role === USER_TYPE || user?.role === USER_TYPE_MVP2 ? (
                              <>
                                <a
                                  href={
                                    mvpRoadmap?.find((mvp: any) => mvp?.id === roadmap?.id)?.doc_url
                                  }
                                  target="_blank">
                                  <DefaultButton className="mt-5 w-fit ">
                                    {t("Go to roadmap")}
                                  </DefaultButton>
                                </a>
                              </>
                            ) : (
                              <>
                                <DefaultButton
                                  className="mt-5 w-fit "
                                  isDisabled={roadmap?.is_selected}
                                  isLoading={isLoadingUpdate}
                                  onClick={handleStart}
                                  fontWeight="300">
                                  <PlayCircleIcon className="w-5 h-5 mr-2" />
                                  {roadmap?.is_selected ? t("active") : t("start")}
                                </DefaultButton>
                              </>
                            )} */}
                              <>
                                <DefaultButton
                                  className="mt-5 w-fit "
                                  isDisabled={roadmap?.is_selected}
                                  isLoading={isLoadingUpdate}
                                  onClick={handleStart}
                                  fontWeight="300">
                                  <PlayCircleIcon className="w-5 h-5 mr-2" />
                                  {roadmap?.is_selected ? t("active") : t("start")}
                                </DefaultButton>
                              </>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <RoadmapDetailLinkedWarningPopup
        open={openErrorModal}
        setOpen={setOpenErrorModal}
        nameTask={errorMessage}
      />
    </>
  );
}
