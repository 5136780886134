import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

// Component
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import PopupModal from "@/components/PopupModal/PopupModal";

// Hook
import { setToken } from "@/utils/token";
import { createGoogleAuthUrl, loginUser, loginWithGoogleAuth } from "@/api/auth";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { signInEvent, signInFailureEvent, signUpEvent } from "@/analytics/mixpanel";
import mixpanel from "mixpanel-browser";
import Input from "@/components/UI/Input";
import { sendPasswordRecovery } from "@/api/verification";
import toast, { Toaster } from "react-hot-toast";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { USER_TYPE_MVP2 } from "@/models/userType";

const Login = () => {
  // State
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isForgetPassowrd, setIsForgetPassword] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  // Hook
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { mutate, isLoading: isLoadingPost } = useMutation(loginUser, {
    onSuccess: (data) => {
      const tempData = {
        userId: data.data.user_id,
        signInDone: true,
        sourceOutput: "/",
        email: email,
        fromGoogle: false,
      };
      signInEvent(tempData);
      // mixpanel.identify(data.data.user_id);

      mixpanel.people.set({ $email: email });

      setToken(data.data.access_token);
      localStorage.removeItem("dropdown1");
      navigate("/");
    },
    onError: (error: any) => {
      setError(error.response.data.detail);
      const tempData = {
        signInDone: false,
        email: email,
      };
      signInFailureEvent(tempData);
    },
    retry: false,
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(undefined);
    mutate({
      email,
      password,
    });
  };

  const { mutateAsync: mutateResetPassword, isLoading: isLoadingResetPassword } =
    useMutation(sendPasswordRecovery);

  const handleResetPassword = async () => {
    mutateResetPassword({ email })
      .then(() => {
        setIsForgetPassword(false);
        setEmail("");
      })
      .then(() => {
        toast.success(t("passwordResetSuccess"));
      })
      .catch((e) => {
        setEmail("");

        toast.error(e?.response?.data?.detail || "There is a problem, please try again later.");
      });
  };

  const { data: googleAuthUrl, isLoading: isLoadingGoogleUrl } = useQuery("googleAuthUrl", () =>
    createGoogleAuthUrl(window.location.origin)
  );

  const { mutateAsync: mutateGoogleLogin, isLoading: isLoadingGoogleLogin } = useMutation(
    loginWithGoogleAuth,
    {
      onSuccess: (data) => {
        if (!data.is_register) {
          const tempData = {
            userId: data.user_id,
            signInDone: true,
            sourceOutput: "/",
            email: data.email,
            fromGoogle: true,
          };
          signInEvent(tempData);
        } else {
          const tempData = {
            userId: data.user_id,
            signUpDone: true,
            sourceOutput: "/",
            email: data.email,
            fromGoogle: true,
          };
          signUpEvent(tempData);
        }

        mixpanel.identify(data.user_id);
        mixpanel.people.set({ $email: data.email, $name: data?.first_name + " " + data.last_name });

        setToken(data.access_token);
        localStorage.removeItem("dropdown1");
        navigate("/");
      },
      onError: (error: any) => {
        setError(error.response.data.detail);
        const tempData = {
          signInDone: false,
          email: email,
        };
        signInFailureEvent(tempData);
      },
      retry: false,
    }
  );

  useEffect(() => {
    if (code) {
      const queryRole = window.location.hostname.includes("prod") ? USER_TYPE_MVP2 : null;

      mutateGoogleLogin({
        code,
        role: queryRole,
      });
    }
  }, [code]);

  return (
    <div>
      <Toaster />
      <PopupModal closeAction={() => setIsForgetPassword(false)} isPopup={isForgetPassowrd}>
        <div className="flex flex-col gap-5 ">
          <p>
            Enter the email address you used to join and we'll send you instructions to reset your
            password.
          </p>
          <Input
            placeholder="E-mailinizi giriniz"
            name="email"
            type="email"
            title="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required={true}
          />
          <DefaultButton
            onClick={handleResetPassword}
            isLoading={isLoadingResetPassword}
            isDisabled={email.length === 0}>
            {t("send")}
          </DefaultButton>
        </div>
      </PopupModal>

      <div className="flex min-h-screen">
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 relative">
          {/*  <div className="absolute right-5 top-3 z-[99]">
            <SelectLanguage />
          </div> */}
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="h-12 w-auto" src="/assets/icons/logoPrivate.svg" alt="Logo" />
              <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
                {t("signInToYourAccount")}
              </h2>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <form
                  action="#"
                  method="POST"
                  className="space-y-6"
                  onSubmit={(e) => handleSubmit(e)}>
                  <div>
                    <div className="w-full">
                      {isLoadingGoogleUrl || isLoadingGoogleLogin ? (
                        <LoadingSpinner size="w-5 h-5" />
                      ) : (
                        <a
                          href={googleAuthUrl?.authorization_url}
                          className=" flex items-center justify-center space-x-2 bg-white border border-gray-300 px-4 py-2 rounded-md shadow-sm text-sm font-medium text-gray-500 hover:bg-gray-50 w-full">
                          <img src="/assets/icons/google.svg" alt="google" />
                          <span>{t("loginWithGoogle")}</span>
                        </a>
                      )}
                    </div>
                    <div className="w-full border-t border-gray-300 mt-8">
                      <div className="flex justify-center items-center -mt-3">
                        <span className="bg-white px-4 text-gray-500 text-sm ">{t("or")}</span>
                      </div>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      {t("email")}
                    </label>
                    <div className="mt-1">
                      <input
                        placeholder={t("enterYourEmail")}
                        type="email"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                      {t("password")}
                    </label>
                    <div className="mt-1">
                      <input
                        placeholder={t("enterYourPassword")}
                        type="password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center"></div>

                    <div className="text-sm">
                      <div
                        onClick={() => {
                          setIsForgetPassword(true);
                          setError(undefined);
                        }}
                        className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer">
                        {t("forgotPassword")}
                      </div>
                    </div>
                  </div>

                  {!isForgetPassowrd && error && (
                    <div className="text-dangerButtonPrimary text-sm -my-2 first-letter:capitalize">
                      {error}
                    </div>
                  )}
                  <div className="flex flex-col gap-4">
                    <DefaultButton
                      isDisabled={password.length === 0 || email.length === 0}
                      isLoading={isLoadingPost}>
                      {t("logIn")}
                    </DefaultButton>
                  </div>

                  <div className="flex justify-center flex-col space-y-2">
                    <span className="text-center text-sm text-textColor mt-2">
                      {t("dontHaveAnAccount")} {""}
                      <Link to="/register" className="text-sky">
                        {t("registerNow")}
                      </Link>
                      .
                    </span>
                    <div className="text-sm text-center ">
                      <Link
                        className="text-sky capitalize"
                        to="/terms-of-service"
                        target="_blank"
                        rel="noopener noreferrer">
                        {t("termsOfUse")}
                      </Link>
                      {"   •   "}
                      <Link
                        className="text-sky capitalize"
                        to="/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer">
                        {t("privacyPolicy")}
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block border-l">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/assets/images/login-bg.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
