import { format, formatDistanceToNow, parseISO } from "date-fns";
import enLocale from "date-fns/locale/en-US";
import trLocale from "date-fns/locale/tr";
import { tr } from "date-fns/locale";

export const dateFormatter = (dateString: string) => {
  const date = new Date(dateString);
  const locale = getLocaleFromLocalStorage() || "en";

  let formatOptions = {};
  if (locale === "tr") {
    formatOptions = { locale: trLocale };
  } else {
    formatOptions = { locale: enLocale };
  }

  const formattedDate = format(date, "LLL dd, yyyy", formatOptions);

  return formattedDate;
};

export const dateFormatterSelect = (dateString: string) => {
  const date = new Date(dateString);
  return format(date, "yyyy-MM-dd");
};

const getLocaleFromLocalStorage = (): string | null => {
  return localStorage.getItem("i18nextLng");
};

export const calculateTimeAgo = (dateString: any) => {
  const date = parseISO(dateString);
  let result = formatDistanceToNow(date, { addSuffix: true });
  result = result.replace(/^about /, ""); // "about" kelimesini kaldırır
  return result;
};
