import { t } from "i18next";
import { ScopeType, Severity } from "@/models/adsEnum";
import GeneralState from "@/components/GeneralState";
import { MdOutlineKeyboardArrowRight, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { GrStatusGoodSmall } from "react-icons/gr";

type Props = {
  data: any;
};

const GeneralTable = (props: Props) => {
  const { data } = props;

  return (
    <div>
      <div className="overflow-auto relative mb-5">
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs text-gray-700  bg-gray-100  text-center">
            <tr>
              <th scope="col" className="py-2 px-6">
                {t("errorDetail")}
              </th>
              <th scope="col" className="py-2 px-6">
                {t("severity")}
              </th>
              <th scope="col" className="py-2 px-6">
                {t("count")}
              </th>
              <th scope="col" className="py-2 px-6">
                {t("scope")}
              </th>
            </tr>
          </thead>
          <tbody className="text-center ">
            {data.rulesFailed?.map((item: any, index: any) => {
              return (
                <GeneralState>
                  {({ openCard, setOpenCard }) => (
                    <>
                      <tr
                        className={
                          "cursor-pointer border-b   " +
                          (openCard ? "  shadow-lg" : "border-gray-100 ")
                        }
                        key={index}
                        onClick={() => setOpenCard(!openCard)}>
                        <td className="px-4 py-4 ">
                          <div className="first-letter:uppercase flex items-center text-black">
                            {openCard ? (
                              <MdOutlineKeyboardArrowDown className="text-lg mr-2" />
                            ) : (
                              <MdOutlineKeyboardArrowRight className="text-lg mr-2" />
                            )}
                            {item?.failText ?? item?.failText?.english}
                          </div>
                        </td>
                        <td
                          className={`px-6 py-4 ${
                            item.severity === 3
                              ? "text-[#EE4549]"
                              : item.severity === 2
                              ? "text-[#FC723C]"
                              : item.severity === 1
                              ? "text-[#FFCB00]"
                              : "text-[#00B852]"
                          }`}>
                          <div className="first-letter:uppercase">{Severity[item.severity]}</div>
                        </td>
                        <td className="px-6 py-4">
                          {item?.campaigns?.length || item?.adGroups?.length}
                        </td>
                        <td className="px-6 py-4 font-medium">{ScopeType[item.scope]}</td>
                      </tr>
                      {openCard && (
                        <>
                          {item?.assets?.map((asset: any, index: any) => {
                            return (
                              <tr className={`border bg-gray-100 `} key={index}>
                                <td className="px-12 py-4 ">
                                  <div className="flex justify-start items-center first-letter:uppercase text-black">
                                    <GrStatusGoodSmall
                                      className={
                                        "text-xs  mr-2 " +
                                        (item.severity === 3
                                          ? "text-[#EE4549]"
                                          : item.severity === 2
                                          ? "text-[#FC723C]"
                                          : item.severity === 1
                                          ? "text-[#FFCB00]"
                                          : "text-[#00B852]")
                                      }
                                    />
                                    {asset?.name}
                                  </div>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                </GeneralState>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GeneralTable;
