import { useEffect, useState } from "react";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { t } from "i18next";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { authMe, puthAuthMe } from "@/api/auth";
import { useMutation, useQuery } from "react-query";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { fetchAllCountries } from "@/api/country";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import RightModal from "@/components/Modal/RightModal";
import SecondaryButton from "@/components/buttons/SecondaryButton";

interface Props {
  open?: boolean;
  size?: string;
  onClose?: () => void;
  refetch?: () => void;
}

export default function ProfileInformationUpdate(props: Props) {
  const { size, open = false, onClose, refetch } = props;
  const [selectedCountry, setSelectedCountry] = useState<any>(null);

  const schema = yup
    .object({
      street: yup.string().required(t("street_is_required")),
      city: yup.string().required(t("city_is_required")),
      zip: yup.string().required(t("zip_is_required")),
      state: yup.string().required(t("state_is_required")),
      tax_identification_number: yup.string(),
      tax_office: yup.string(),
      invoice_title: yup.string(),
      invoice_type: yup.string(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { mutateAsync, isLoading: isLoadingUpdate } = useMutation(puthAuthMe, {});

  const { data, isLoading } = useQuery("authmeProfile", authMe, {});

  const { data: countries, isLoading: isLoadingCountries } = useQuery(
    "fetchACountries",
    fetchAllCountries,
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleCountryChange = (e: any) => {
    setSelectedCountry(e.value);
  };

  useEffect(() => {
    if (data?.address?.country) {
      setSelectedCountry(data.address.country);
    }
  }, [data]);

  const onSubmit = (incomingData: any) => {
    if (!selectedCountry) {
      toast.error("Please select a country");
      return;
    }

    if (incomingData.tax_identification_number) {
      if (
        incomingData.tax_identification_number.length !== 10 &&
        incomingData.tax_identification_number.length !== 11
      ) {
        toast.error("Please enter a valid tax identification number, 10 or 11 digits");
        return;
      }
    }

    mutateAsync({
      userId: data.id,
      address: incomingData.street,
      city: incomingData.city,
      state: incomingData.state,
      zip_code: incomingData.zip,
      tax_identification_number: incomingData.tax_identification_number,
      tax_office: incomingData.tax_office,
      invoice_title: incomingData.invoice_title,
      invoice_type: incomingData.invoice_type,
      country: selectedCountry,
    })
      .then((res) => {
        console.log(res);
        toast.success("Your profile has been updated successfully");
        if (refetch) {
          refetch();
        }
        if (onClose) {
          onClose();
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("There was an error updating your profile. Please try again later.");
      });
  };

  return (
    <RightModal
      size={size}
      open={open}
      onClose={() => (onClose ? onClose() : null)}
      action={
        <SecondaryButton
          onClick={handleSubmit(onSubmit)}
          name="Save"
          loading={isLoadingUpdate}
          className="w-full"
        />
      }>
      <div className="w-full h-full flex  flex-1 flex-col justify-start items-start text-left  px-6 pt-14 gap-y-3  ">
        <div className="text-left w-full">
          <h2 className="text-base font-semibold leading-6 text-gray-900">Information Required</h2>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Please update your profile information before proceeding.
            </p>
            <p className="mt-1 text-xs text-gray-500">
              *{t("We need your billing address for your invoice.")}
            </p>
          </div>
        </div>
        <div>
          {isLoading ? (
            <LoadingSpinner size="w-5 h-5" />
          ) : (
            <form className="flex flex-col gap-6" method="POST" onSubmit={handleSubmit(onSubmit)}>
              <div className="sm:col-span-3">
                <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                  {t("country")}
                </label>
                <div className="mt-1 capitalize">
                  {isLoadingCountries ? (
                    <LoadingSpinner size="w-5 h-5" />
                  ) : (
                    <>
                      <Select
                        isSearchable={false}
                        defaultValue={{
                          label:
                            countries?.find(
                              (country: any) => +country?.id === +data?.address?.country
                            )?.name || "Select a Country",
                          value: +data?.address?.country || "",
                        }}
                        onChange={handleCountryChange}
                        styles={{
                          control: (provided: any) => ({
                            ...provided,
                            border: "1px solid #d9dbdd",

                            borderRadius: "0.375rem",
                            boxShadow: "none",
                            "&:hover": {
                              borderColor: "#e2e8f0",
                            },
                            textTransform: "capitalize",
                            fontSize: "0.875rem",
                            padding: "0rem 0.3rem",
                          }),
                        }}
                        options={countries.map((country: any) => ({
                          value: country.id,
                          label: country.name,
                        }))}
                      />
                    </>
                  )}
                </div>
                {errors.country?.message && (
                  <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                    {errors.country?.message}
                  </span>
                )}
              </div>
              <div className="sm:col-span-6">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  {t("streetAddress")}
                </label>
                <div className="mt-1">
                  <input
                    {...register("street")}
                    defaultValue={data?.address?.address}
                    type="text"
                    autoComplete="street-address"
                    className={`
                ${onblur ? "valid:border-primaryColor" : "border-gray-300"}
                block w-full appearance-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                  />
                </div>
                {errors.street?.message && (
                  <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                    {errors.street?.message}
                  </span>
                )}
              </div>
              <div className="sm:col-span-6">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  {t("invoiceType")}
                </label>
                <div className="mt-1 flex gap-4 items-center text-sm">
                  <label className="flex gap-1" htmlFor="field-rain">
                    <input
                      {...register("invoice_type")}
                      type="radio"
                      value="personal"
                      id="field-rain"
                      defaultChecked={data?.invoice_type === "personal"}
                    />
                    Personal
                  </label>
                  <label className="flex gap-1" htmlFor="field-wind">
                    <input
                      {...register("invoice_type")}
                      type="radio"
                      value="commercial"
                      id="field-wind"
                      defaultChecked={data?.invoice_type === "commercial"}
                    />
                    Commercial
                  </label>
                </div>
                {errors.invoice_type?.message && (
                  <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                    {errors.invoice_type?.message}
                  </span>
                )}
              </div>
              <div className="sm:col-span-6">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                  {t("invoiceTitle")}
                </label>
                <div className="mt-1">
                  <input
                    {...register("invoice_title")}
                    defaultValue={data?.invoice_title}
                    type="text"
                    className={`
                ${onblur ? "valid:border-primaryColor" : "border-gray-300"}
                block w-full appearance-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                  />
                </div>
                {errors.invoice_title?.message && (
                  <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                    {errors.invoice_title?.message}
                  </span>
                )}
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="tax_identifaction_number"
                    className="block text-sm font-medium text-gray-700">
                    {t("Tax Identification Number")}
                  </label>
                  <div className="mt-1">
                    <input
                      defaultValue={data?.tax_identification_number}
                      type="text"
                      {...register("tax_identification_number")}
                      className={`
                ${onblur ? "valid:border-primaryColor" : "border-gray-300"}
                block w-full appearance-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                    />
                  </div>
                  {errors.tax_identification_number?.message && (
                    <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                      {errors.tax_identification_number?.message}
                    </span>
                  )}
                </div>
                <div>
                  <label htmlFor="tax_office" className="block text-sm font-medium text-gray-700">
                    {t("Tax Office")}
                  </label>
                  <div className="mt-1">
                    <input
                      defaultValue={data?.tax_office}
                      type="text"
                      {...register("tax_office")}
                      className={`
                ${onblur ? "valid:border-primaryColor" : "border-gray-300"}
                block w-full appearance-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                    />
                  </div>
                  {errors.tax_office?.message && (
                    <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                      {errors.tax_office?.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                    {t("city")}
                  </label>
                  <div className="mt-1">
                    <input
                      {...register("city")}
                      defaultValue={data?.address?.city}
                      type="text"
                      autoComplete="address-level2"
                      className={`
                ${onblur ? "valid:border-primaryColor" : "border-gray-300"}
                block w-full appearance-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                    />
                  </div>
                  {errors.city?.message && (
                    <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                      {errors.city?.message}
                    </span>
                  )}
                </div>
                <div className="">
                  <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                    {t("stateProvince")}
                  </label>
                  <div className="mt-1">
                    <input
                      {...register("state")}
                      defaultValue={data?.address?.state}
                      type="text"
                      autoComplete="address-level1"
                      className={`
                ${onblur ? "valid:border-primaryColor" : "border-gray-300"}
                block w-full appearance-none rounded-md border  px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                    />
                  </div>
                  {errors.state?.message && (
                    <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                      {errors.state?.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="sm:col-span-2">
                <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                  {t("zipCode")}
                </label>
                <div className="mt-1">
                  <input
                    {...register("zip")}
                    defaultValue={data?.address?.zip_code}
                    type="text"
                    autoComplete="postal-code"
                    className={`
                ${onblur ? "valid:border-primaryColor" : "border-gray-300"}
                block w-full appearance-none rounded-md border  px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                  />
                </div>
                {errors.zip?.message && (
                  <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                    {errors.zip?.message}
                  </span>
                )}
              </div>
            </form>
          )}
        </div>
        <Toaster />
      </div>
    </RightModal>
  );
}
