import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import DataTable from "@/components/Table/DataTable";
import ReportSectionCard from "@/components/cards/ReportSectionCard";
import DateRangeSelect from "@/components/DateRangePicker";
import InfoBoard from "@/components/cards/InfoCard";
import { adReportBreadCrumb } from "@/store/atoms/adReportBreadcrumb.atom";
import { useRecoilState } from "recoil";
import { currentDayToMinus } from "@/utils/currentDayToMinus";
import { fetchAdGroups } from "@/api/metric";

type Props = {
  tableId: string;
};

const AdGroupsTable = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { tableId } = props;
  const params: any = useParams();
  const clientId: number = params?.clientId;
  const campaignId: number = params?.campaignId;
  const filterDataString = localStorage.getItem("table-filter");
  const filterData = filterDataString ? JSON.parse(filterDataString) : null;
  const [breadcrumbState, setBreadcrumbState] = useRecoilState(adReportBreadCrumb);

  const isFilterDataExist = () => {
    if (filterData && filterData[tableId]) {
      return true;
    } else {
      return false;
    }
  };

  const [filter, setFilter] = useState<any>({
    page: isFilterDataExist() ? filterData[tableId].page : 1,
    pageSize: isFilterDataExist() ? filterData[tableId].pageSize : 10,
    orderBy: isFilterDataExist() ? filterData[tableId].orderBy : 6,
    startDate: isFilterDataExist() ? filterData[tableId].startDate : currentDayToMinus(7),
    endDate: isFilterDataExist() ? filterData[tableId].endDate : currentDayToMinus(1),
    sortOrder: isFilterDataExist() ? filterData[tableId].sortOrder : 2,
  });
  const endDate = filter?.endDate;
  const startDate = filter?.startDate;
  const [refresh, setRefresh] = useState<number>();

  useEffect(() => {
    console.log("asdas");
    const storedFiltersString = localStorage.getItem("table-filter");
    const storedFilters = storedFiltersString ? JSON.parse(storedFiltersString) : {};
    const updatedFilters = { ...storedFilters, [tableId]: filter };
    localStorage.setItem("table-filter", JSON.stringify(updatedFilters));
  }, [filter, tableId]);

  const { data, isLoading, isFetching, refetch } = useQuery(
    "fetchAdGroupMetrics",
    () =>
      fetchAdGroups({
        clientId: clientId,
        campaignId: campaignId,
        page: filter.page,
        pageSize: filter.pageSize,
        orderBy: filter.orderBy,
        startDate: filter.startDate,
        endDate: filter.endDate,
        sortOrder: filter.sortOrder,
      }),
    {
      retry: false,
      enabled: !!clientId,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (data && data.breadcrumb && data.breadcrumb.items) {
      setBreadcrumbState(data.breadcrumb.items);
    }
  }, [data]);

  const handleOrder = (value: number) => {
    setFilter((prevFilter: any) => ({
      ...prevFilter,
      orderBy: value,
    }));
    setRefresh(Math.random());
  };

  const handleSortOrder = (orderBy: number) => {
    setFilter((prevFilter: any) => ({
      ...prevFilter,
      sortOrder: data.table.orderBy === orderBy ? (data.table.sortOrder === 1 ? 2 : 1) : 2,
    }));
    setRefresh(Math.random());
  };

  const handlePage = (value: number) => {
    setFilter((prevFilter: any) => ({
      ...prevFilter,
      page: value,
    }));
    setRefresh(Math.random());
  };

  const handlePageSize = (value: number) => {
    setFilter((prevFilter: any) => ({
      ...prevFilter,
      pageSize: value,
    }));
    setRefresh(Math.random());
  };

  const handleChangeDate = (selectedStartDate: any, selectedEndDate: any) => {
    const startDate: any = new Date(selectedStartDate);
    const endDate: any = new Date(selectedEndDate);
    setFilter((prevFilter: any) => ({
      ...prevFilter,
      startDate: startDate.toISOString().split("T")[0],
      endDate: endDate.toISOString().split("T")[0],
    }));
    setRefresh(Math.random());
  };

  const handleClick = (id: any, path: any) => {
    navigate(`${window.location.pathname}/${path}/${id}`);
  };

  useEffect(() => {
    if (filter) {
      refetch();
    }
  }, [refresh]);

  return (
    <div className="pb-6 ">
      <ReportSectionCard
        showHide={true}
        title={t("adGroups")}
        description={data && "Total " + data.table.totalRecordCount + " Record"}
        action={
          <DateRangeSelect
            handleChangeDate={handleChangeDate}
            endDate={endDate}
            startDate={startDate}
          />
        }>
        {isLoading ? (
          <LoadingSpinner />
        ) : data ? (
          <DataTable
            data={data}
            tableId={tableId}
            isFetching={isFetching}
            handleClick={handleClick}
            handleOrder={handleOrder}
            handleSortOrder={handleSortOrder}
            handlePage={handlePage}
            handlePageSize={handlePageSize}
          />
        ) : (
          <InfoBoard title={t("noDataTitle")} description={t("noDataDescription")} />
        )}
      </ReportSectionCard>
    </div>
  );
};

export default AdGroupsTable;
