import CircleButton from "@/components/buttons/CircleButton";
import { userAtom } from "@/store/atoms/user.atoms";
import { Menu } from "@headlessui/react";
import React from "react";
import { FaUserAlt } from "react-icons/fa";
import { MdOutlinePowerSettingsNew, MdOutlineSettings } from "react-icons/md";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";

export const ProfileMenu = () => {
  const [user] = useRecoilState(userAtom);

  const menu = [
    { name: "Profile", path: "/profile", icon: <MdOutlineSettings /> },
    { name: "Logout", path: "/logout", icon: <MdOutlinePowerSettingsNew /> },
  ];

  console.log(user);
  return (
    <div className="relative ">
      <Menu>
        <Menu.Button className="bg-transparent">
          <CircleButton secondary icon="E" />
        </Menu.Button>

        <Menu.Items className="absolute right-0 z-50 flex flex-col gap-3 border border-gray-300 bg-white rounded-lg shadow-lg  min-w-[300px]  overflow-auto">
          <div className="flex gap-3 items-center p-4 border-b border-gray-200">
            <div className="w-10 h-10 border border-gray-300 rounded-full overflow-hidden relative flex items-center justify-center bg-gray-300">
              {user?.avatar ? (
                <img src={user?.avatar} alt="avatar" />
              ) : (
                <FaUserAlt className="text-2xl" />
              )}
            </div>
            <div className="flex flex-col capitalize">
              <span>{user?.first_name + " " + user?.last_name}</span>
            </div>
          </div>
          <div className="flex flex-col w-full pb-3 gap-2 ">
            {menu.map((i: any, index: number) => (
              <Menu.Item key={index}>
                <Link to={i.path} className="flex items-center w-full gap-2 px-3  ">
                  {i.icon}
                  {i.name}
                </Link>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Menu>
    </div>
  );
};
