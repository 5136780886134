function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

type Props = {
  categories: any;
  setSelectedCategory: any;
  selectedCategory: any;
  actions?: any;
};

export default function SelectStrategyFilter(props: Props) {
  const { categories, setSelectedCategory, selectedCategory, actions } = props;
  const handleFilter = (filterId: string) => {
    setSelectedCategory(filterId);
  };

  return (
    <div className="w-full flex-col md:flex-row flex items-stratch justify-between ">
      <div className="flex flex-1  flex-wrap overflow-auto scrollbar-hide justify-start">
        <nav className="flex w-full" aria-label="Tabs">
          <div
            className={classNames(
              selectedCategory === ""
                ? "bg-white text-primaryColor border-b border-primaryColor font-[600] "
                : " text-gray-400 border-b border-gray-300",
              "flex items-center  px-4 py-2 text-sm h-full font-medium  cursor-pointer text-sm whitespace-nowrap"
            )}
            onClick={() => handleFilter("")}>
            All
          </div>
          {categories.map((tab: any, index: number) => (
            <div
              key={index}
              onClick={() => handleFilter(tab.id)}
              className={classNames(
                selectedCategory === tab.id
                  ? "bg-white text-primaryColor border-b border-primaryColor font-[600]"
                  : " text-gray-400 border-b border-gray-300",
                "flex items-center px-4 py-2 h-full text-sm font-medium  cursor-pointer text-sm whitespace-nowrap"
              )}
              aria-current={tab.current ? "page" : undefined}>
              {tab.value}
            </div>
          ))}
          <div className="flex flex-1 h-full border-b border-gray-200 " />
        </nav>
      </div>
      {actions && (
        <div className="flex items-center justify-end  gap-2 py-2 border-0 md:border-b border-gray-200">
          {actions}
        </div>
      )}
    </div>
  );
}
