import { startingRoadmapEvent } from "@/analytics/mixpanel";
import { deleteProjectRoadmap, fetchProjectRoadmapDetail, postProjectRoadmap } from "@/api/project";
import confetti from "../../../assets/images/confetti.gif";
import { Divider } from "@/components/Divider";
import ModalLoading from "@/components/Loading/ModalLoading";
import SideModal from "@/components/Modal/SideModal";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import SecondaryButton from "@/components/buttons/SecondaryButton";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import { userAtom } from "@/store/atoms/user.atoms";
import React, { useEffect, useState } from "react";
import { MdLock } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useRecoilState } from "recoil";
import ConfirmModal from "@/components/Modal/ConfirmModal";
import DangerButton from "@/components/buttons/DangerButton";
import RightModal from "@/components/Modal/RightModal";
import { useNavigate } from "react-router-dom";

type Props = {
  projectId: any;
  open: boolean;
  size: string;
  strategyId: any;
  onClose: () => void;
  reFetch: () => void;
};

const StrategyDetail = (props: Props) => {
  const { open, size, projectId, strategyId, onClose, reFetch } = props;
  const [project] = useRecoilState(projectInfo);
  const [user] = useRecoilState(userAtom);
  const planId = project.pricingPlan.id;
  const navigate = useNavigate();

  const [error, setError] = useState();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [isSUccessStarted, setIsSuccessStarted] = useState(false);
  const [rejectConfirmModal, setRejectConfirmModal] = useState(false);
  const [prequisiteId, setPrequisiteId] = useState<number | undefined>();
  const [rejectLoading, setRejectLoading] = useState(false);
  const [strategyTasks, setStrategyTasks] = useState([]);
  const [loading, setLoading] = useState(true);

  console.log(loading);

  const {
    data: strategyData,
    isLoading,
    isSuccess,
    isFetched,
    refetch: refetchStrategy,
  } = useQuery(
    ["fetchStrategyDetail", strategyId],
    () =>
      fetchProjectRoadmapDetail({
        project_id: projectId,
        roadmap_id: strategyId,
      }),
    {
      retry: false,
      enabled: Boolean(strategyId),
      cacheTime: 0,
    }
  );

  const { mutateAsync: updateProjectData, isLoading: isLoadingUpdate } =
    useMutation(postProjectRoadmap);

  const handleActivate = () => {
    updateProjectData({
      project_id: projectId,
      roadmap_id: strategyId,
    })
      .then(() => {
        startingRoadmapEvent({
          email: user?.email || "",
          userId: user?.id.toString() || "",
          roadmapName: strategyData?.name,
          roadmapId: strategyData?.id,
        });
      })
      .catch((err) => {
        setError(err?.response?.data?.detail?.message);
        setOpenErrorModal(true);
      })
      .finally(() => {
        setIsSuccessStarted(true);
        reFetch();
      });
  };

  const { mutateAsync: deleteProjectData, isLoading: isLoadingDelete } =
    useMutation(deleteProjectRoadmap);

  const handleReject = () => {
    setRejectLoading(true);
    deleteProjectData({
      project_id: projectId,
      roadmap_id: strategyId,
    })
      .then(() => {
        startingRoadmapEvent({
          email: user?.email || "",
          userId: user?.id.toString() || "",
          roadmapName: strategyData?.name,
          roadmapId: strategyData?.id,
        });
      })
      .catch((err) => {
        setError(err?.response?.data?.detail?.message);
        setOpenErrorModal(true);
      })
      .finally(() => {
        setRejectLoading(false);
        refetchStrategy();
        setRejectConfirmModal(false);
        reFetch();
      });
  };

  const handleDone = () => {
    setIsSuccessStarted(false);
    onCloseModal();
  };

  useEffect(() => {
    if (isFetched) {
      setLoading(true);
      if (planId === 1 && strategyData.contents) {
        const firstThreeContents = strategyData.contents.slice(0, 3);
        setStrategyTasks(firstThreeContents);
      } else {
        setStrategyTasks(strategyData.contents);
      }
      setPrequisiteId(undefined);
      setLoading(false);
    }
  }, [isLoading]);

  const handlePrequisiteClick = (id: any) => {
    setLoading(true);
    navigate(`/strategies/75`);
    refetchStrategy();
  };

  const onCloseModal = () => {
    setLoading(true);
    onClose();
  };

  return (
    <RightModal
      open={open}
      onClose={() => onCloseModal()}
      size={size}
      action={
        !isLoading &&
        strategyId &&
        (planId !== 1 ? (
          <div className="w-full ">
            {strategyData?.is_selected ? (
              <PrimaryButton
                outline
                onClick={() => setRejectConfirmModal(true)}
                loading={isLoadingDelete}
                name="Reject"
                className="  w-full"
              />
            ) : (
              <PrimaryButton
                disabled={strategyData?.prequisites.length !== 0 ? true : false}
                onClick={() => handleActivate()}
                name="Activate"
                className="  w-full"
                loading={isLoadingUpdate}
              />
            )}
          </div>
        ) : (
          <div
            className=" bottom-0 bg-gradient-to-t from-primaryHoverColor  w-full fixed z-90 rigth-0 top-0  w-full  h-screen flex flex-col justify-end items-center px-6 py-6 gap-4"
            style={{ width: "inherit" }}>
            <MdLock className="text-[4rem] text-white" />
            <span className="font-[600] text-white text-md">
              <span className="text-yellow">Upgrade Your Plan </span>to View and Assign Tasks to the
              Mikrolo Team
            </span>
            <SecondaryButton name="Upgrade Now" />
          </div>
        ))
      }>
      {loading || !strategyId ? (
        <ModalLoading />
      ) : (
        <div className="flex flex-col w-full">
          <div className="w-full he-full flex  flex-1 flex-col justify-start items-start text-left  px-6 pt-10 pb-10 gap-y-3  ">
            <h2 className="text-lg font-[600] "> {strategyData.name} </h2>
            <span className="block text-sm font-[300] "> {strategyData.description} </span>
            <Divider />
            <span className="text-sm font-[600] ">Strategy details</span>
            <div className="w-full flex-col">
              <div className="w-full grid grid-cols-2 gap-2 text-sm ">
                <div className="w-full">Price:</div>
                <div className="w-full">{"$" + strategyData.total_price}</div>
                <div className="w-full">Strategy label:</div>
                <div className="w-full">{strategyData.category_title} </div>
                <div className="w-full">Operating time:</div>
                <div className="w-full">{strategyData.operating_time} Day</div>
              </div>
            </div>
            <Divider />

            {strategyData?.prequisites?.length !== 0 && (
              <div className="flex flex-col items-start gap-2">
                <div className="rounded-md bg-red-50 p-4">
                  <div className="flex">
                    <div className="">
                      <h3 className="text-sm text-left font-semibold text-red-800 ">
                        You must complete the prerequisites to activate this strategy!
                      </h3>
                      <div className="mt-2 text-sm text-red-700">
                        <ul className="list-disc space-y-1 pl-5 text-left">
                          {strategyData.prequisites.map((item: any, index: number) => (
                            <li
                              onClick={() => handlePrequisiteClick(item.id)}
                              className="cursor-pointer"
                              key={index}>
                              {item.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <span className="text-sm font-[600] ">Tasks</span>
            <div className="w-full flex flex-col  gap-1 ">
              {strategyTasks.map((item: any, index: number) => (
                <>
                  <div className="flex gap-4 items-center justify-start">
                    <div className="w-6 h-6 rounded-full bg-secondaryColor flex items-center justify-center text-white text-md font-semibold">
                      {index + 1}
                    </div>
                    <div className="flex-1 text-sm font-light">{item.name}</div>
                  </div>
                  {index !== strategyTasks.length - 1 && (
                    <div className="w-8 flex items-center justify-center">
                      <div className="h-2 border-r border-dashed border-secondaryColor"></div>
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>

          <SideModal
            size="w-full md:w-5/12 lg:w-4/12 xl:w-3/12"
            open={isSUccessStarted}
            onClose={handleDone}>
            <div className="w-full h-screen flex flex-col justify-center items-center gap-6 p-6">
              <img className="w-28 h-28" src={confetti} alt="confetti" />
              <span className="font-[600] text-lg">
                Your strategy has been successfully activated.
              </span>
              <SecondaryButton name="Done" onClick={handleDone} />
            </div>
          </SideModal>
          <ConfirmModal
            title=" Are you sure you want to delete this strategy?"
            description="You will lose your progress."
            size="w-full md:w-5/12 lg:w-4/12 xl:w-3/12"
            open={rejectConfirmModal}
            onClose={() => setRejectConfirmModal(false)}
            confirmButton={
              <DangerButton loading={rejectLoading} name="Reject" onClick={() => handleReject()} />
            }
          />
        </div>
      )}
    </RightModal>
  );
};

export default StrategyDetail;
