import React, { useEffect, useState, useRef } from "react";
import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

interface Options {
  id: any;
  value: string;
}

interface Props {
  data: Options[];
  disabled?: boolean;
  label?: string;
  defaultValue?: Options | null;
  onChange: (selected: Options | null) => void;
  className?: string;
  basic?: boolean;
  placeholder?: string;
}

const classNames = (...classes: string[]) => classes.filter(Boolean).join(" ");

const Select: React.FC<Props> = ({
  data,
  disabled,
  defaultValue,
  className,
  label,
  onChange,
  basic,
  placeholder,
}: Props) => {
  const [selected, setSelected] = useState<Options | null>(defaultValue || null);
  const [openUpwards, setOpenUpwards] = useState(false);
  const [openToLeft, setOpenToLeft] = useState(false);
  const listboxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (listboxRef.current) {
        const { bottom, right } = listboxRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const viewportWidth = window.innerWidth;

        setOpenUpwards(bottom + 200 > viewportHeight);
        setOpenToLeft(right + 100 > viewportWidth); // Check if near right edge
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  const handleCHange = (item: any) => {
    setSelected(item);
    onChange(item.id);
  };

  return (
    <Listbox value={selected} onChange={handleCHange} disabled={disabled}>
      <div className="relative w-full" ref={listboxRef}>
        <div className={"flex flex-col items-start justify-start gap-2 " + className}>
          {label && <label className="text-sm font-semibold">{label}</label>}
          <Listbox.Button
            className={
              basic
                ? " w-full flex items-center justify-between "
                : "h-input w-full flex items-center justify-between rounded-md border bg-white px-2 border-gray-300"
            }>
            <span className="flex gap-1 items-center">
              <span
                className={
                  "text-sm text-left flex truncate " + (basic ? " whitespace-pre-wrap " : "")
                }>
                {selected ? selected.value : placeholder ? placeholder : "Select an option"}
              </span>
            </span>
            <span className="pointer-events-none flex items-center">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>

          <Listbox.Options
            className={classNames(
              "absolute z-10 max-h-56 min-w-[220px] overflow-auto rounded-md bg-white p-1 text-base shadow-lg focus:outline-none text-sm border border-gray-300",
              openUpwards ? "bottom-[40px]" : "top-full mt-2",
              openToLeft ? "right-0" : "left-0"
            )}>
            {data.map((item) => (
              <Listbox.Option
                key={item.id}
                className={({ active }) =>
                  classNames(
                    active ? "bg-gray-200" : "text-gray-900",
                    "relative text-sm cursor-default select-none py-2  pr-9"
                  )
                }
                value={item}>
                {({ selected, active }) => (
                  <>
                    <div className="flex items-center">
                      <span
                        className={classNames(
                          selected ? "font-semibold" : "font-normal",
                          "ml-3 block truncate"
                        )}>
                        {item.value}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          active ? "text-secondaryHoverColor" : "text-secondaryHoverColor",
                          "absolute inset-y-0 right-0 flex items-center pr-4"
                        )}>
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </div>
    </Listbox>
  );
};

export default Select;
