import { useEffect } from "react";
import { useMutation } from "react-query";
import { googleAnalyticsCallback } from "@/api/credential";
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";

const AdsCallbackPage = () => {
  // get code from url
  const { projectId } = useFetchingProjectIds();

  const code = new URLSearchParams(window.location.search).get("code");
  const errorTag = new URLSearchParams(window.location.search).get("error");

  /*   const { data, isSuccess, isError } = useQuery(
    "loginGoogleAdsCallback",
    () => loginGoogleAdsCallback(code || ""),
    {
      retry: false,
      enabled: !!code,
      refetchOnWindowFocus: false,
    }
  ); */

  const { mutate, isSuccess, isError, error } = useMutation(
    "googleAnalyticsCallback",
    googleAnalyticsCallback
  );

  if (isSuccess) {
    window.location.href = "/google-analytics/properties";
  }

  if (isError) {
    console.log(error);
    console.log("---Error----", JSON.stringify(error));
    window.location.href = "/integrations";
  }

  useEffect(() => {
    if (code && projectId) {
      mutate({
        code: code,
        platform: "google_analytics",
        project_id: projectId,
        base_url: window.location.origin,
      });
    }
  }, [code, projectId]);

  useEffect(() => {
    if (errorTag) {
      window.location.href = "/integrations";
    }
  }, [errorTag]);

  return <div>You are being redirected to the relevant page, please wait...</div>;
};

export default AdsCallbackPage;
