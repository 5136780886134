import React, { useEffect } from "react";
import AssessmentLayout from "@/components/Layout/Assessment";

import GoogleAdsCard from "@/features/integrations/components/IntegrationCards/GoogleAdsCard";
import GoogleAnalyticsCard from "@/features/integrations/components/IntegrationCards/GoogleAnalyticsCard";
import FacebookAuthCard from "@/features/integrations/components/IntegrationCards/FacebookAuthCard";
import LinkedinAuthCard from "@/features/integrations/components/IntegrationCards/LinkedinAuthCard";

import ContentBar from "@/components/ContentBar";
import { t } from "i18next";
import { useRecoilState } from "recoil";
import { userAtom } from "@/store/atoms/user.atoms";
import { pageViewEvent } from "@/analytics/mixpanel";
import PopupModal from "@/components/UI/Modal";
import { Link } from "react-router-dom";

const IntegrationPage = () => {
  const [user] = useRecoilState(userAtom);
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);

  useEffect(() => {
    const getPaths = JSON.parse(localStorage.getItem("paths") as any);

    pageViewEvent({
      pageName: "integrations",
      userId: user?.id.toString() || "",
      email: user?.email || "",
      previousPage: getPaths?.currentPath || "",
    });
  }, []);

  /* useEffect(() => {
    if (localStorage.getItem("firstConnection") === "true") {
      setIsPopupOpen(true);
      localStorage.removeItem("firstConnection");
    }
  }, []); */

  return (
    <div data-testid="integrations-page">
      <AssessmentLayout>
        <ContentBar title="Integrations" />
        <PopupModal setModalState={setIsPopupOpen} modalState={isPopupOpen}>
          <div className="text-base font-medium">
            {t(
              "There are rules that start working for you in line with the strategy you have started"
            )}
          </div>
          <div>
            <Link className="flex justify-end" to="/rules">
              <button className=" text-blue-500  rounded-md mt-4 mr-2">{t("Go to Rules")} →</button>
            </Link>
          </div>
        </PopupModal>
        <div className="grid grid-cols-2 gap-[30px] w-full my-8">
          <GoogleAnalyticsCard
            title="Google Analytics"
            description={t(
              "Connecting your Google Analytics account allows us to generate reports with your data, detect some issues and create custom tasks for you."
            )}
          />
          <GoogleAdsCard
            title="Google Ads"
            description={t(
              "Connecting your Google Ads account allows us to provide you some reports on your ads, detect errors and create tasks about your ads."
            )}
          />
          <FacebookAuthCard
            title="Facebook"
            description={t(
              "Connecting your Facebook account allows us to share your posts on your Facebook page and create tasks about your Facebook page."
            )}
          />
          <LinkedinAuthCard
            title="Linkedin"
            description={t(
              "Connecting your Linkedin account allows us to share your posts on your Linkedin page and create tasks about your Linkedin page."
            )}
          />
        </div>
      </AssessmentLayout>
    </div>
  );
};

export default IntegrationPage;
