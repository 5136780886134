import { userAtom } from "@/store/atoms/user.atoms";
import { useRecoilState } from "recoil";
import { fetchProjectIds } from "@/api/account";
import { useQuery } from "react-query";
import { useState } from "react";

const useFetchingProjectIds = () => {
  const [user] = useRecoilState(userAtom);
  const [refetchEnable, setRefetchEnable] = useState(false);

  const { data, isLoading, isError, error, isSuccess } = useQuery<any, Error>(
    ["projectId", user?.account_id],
    () => fetchProjectIds(user?.account_id as number),
    {
      enabled: !!user?.account_id,
      retry: false,
      refetchInterval: refetchEnable ? 30000 : false,
    }
  );

  const projectId = data && data[0].id;

  return {
    projectId,
    isLoading,
    isError,
    error,
    isSuccess,
    pricingPlan: data && data[0].pricing_plan,
    assignedTaskCount: data && data[0].assigned_task_count,
    totalCredit: data && data[0].pricing_plan?.credit,
    totalUsedCredit: data && data[0].total_used_credit,
    hasAssignTask: data && data[0].pricing_plan?.credit > data[0].total_used_credit,
    setRefetchEnable,
  };
};

export default useFetchingProjectIds;
