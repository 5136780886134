import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import PrimaryButton from "../buttons/PrimaryButton";

type Props = {
  open: boolean;
  title: string;
  description?: string;
  onClose: () => void;
  size?: string;
  confirmButton?: any;
};

const ConfirmModal = (props: Props) => {
  const { open, title, description, onClose, size = "w-5/12", confirmButton } = props;
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm  ${size}`}>
                <div className="relative w-full flex flex-col gap-4 justify-start overflow-auto  ">
                  <span className="text-xl font-[600]">{title}</span>
                  <span className="text-gray-500">{description} </span>
                  <div className="flex mt-2 gap-2 items-center justify-end">
                    <PrimaryButton outline name="Close" onClick={onClose} />
                    {confirmButton}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmModal;
