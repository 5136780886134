import { useMutation, useQuery } from "react-query";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { deleteGoogleAnalyticsIntegration } from "@/api/analytics/googleAnalytics";
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { createGAUrl } from "@/api/credential";
import PopupModal from "@/components/PopupModal/PopupModal";
import { useState } from "react";
import WhiteButton from "@/components/buttons/WhiteButton";
import { t } from "i18next";
import { getAnalyticsEnabled } from "@/api/eAnl";
import Card from "@/components/cards/Card";
import SecondaryButton from "@/components/buttons/SecondaryButton";

interface Props {
  title: string;
  description: string;
  imgSrc?: string;
}

const GoogleAnalyticsCard = (props: Props) => {
  const { projectId, isLoading: isLoadingIds } = useFetchingProjectIds();
  const [openModal, setOpenModal] = useState(false);

  const { title, description } = props;

  const { data: status, isLoading: isLoadingStatus } = useQuery(
    "fetchStatus",
    () => getAnalyticsEnabled(projectId),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!projectId,
      cacheTime: 0,
    }
  );

  const { data, isLoading } = useQuery("getGoogleAnalytics", () => createGAUrl("google_analytics"));

  const { mutateAsync } = useMutation(
    "deleteGoogleAnalyticsIntegration",
    deleteGoogleAnalyticsIntegration,
    {
      retry: false,
    }
  );

  const handleDisconnect = () => {
    mutateAsync(projectId).then(() => {
      window.location.reload();
    });
  };

  return (
    <>
      {isLoadingStatus || isLoadingIds || isLoading ? (
        <LoadingSpinner size="w-8 h-8" />
      ) : (
        <>
          <PopupModal
            closeAction={() => setOpenModal(false)}
            title=""
            isPopup={openModal}
            width="max-w-md">
            <>
              <div>{t("Are you sure you want to disconnect Google Analytics?")}</div>
              <div className="flex flex-col gap-2">
                <DefaultButton onClick={() => handleDisconnect()}>{t("remove")}</DefaultButton>
                <WhiteButton onClick={() => setOpenModal(false)}>{t("cancel")}</WhiteButton>
              </div>
            </>
          </PopupModal>
          <Card col>
            <img src="/assets/icons/google_analytics.svg" alt="gas" width={34} className="mb-2" />
            <div className="flex flex-col gap-1">
              <span className="text-lg font-semibold">{title}</span>
              <span className="text-gray-700">{description}</span>
            </div>

            {status ? (
              <button
                className=" text-green-600 font-medium rounded-lg
                 transition-all ">
                {t("connected")}
              </button>
            ) : (
              <SecondaryButton outline name={t("connect")} href={data?.authorization_url} />
            )}
          </Card>
        </>
      )}
    </>
  );
};

export default GoogleAnalyticsCard;
