import {
  CheckCircleIcon,
  ClipboardDocumentCheckIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchProjectMainTasks } from "@/api/task";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import AssessmentLayout from "@/components/Layout/Assessment";
import ContentBar from "@/components/ContentBar";
import ToastNotify from "@/components/UI/ToastNotify";
import { useRecoilState } from "recoil";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import InProgressPage from "@/views/Dashboard/TasksPage/InProgressPage";
import AssignedPage from "@/views/Dashboard/TasksPage/AssignedPage";
import FinishedPage from "@/views/Dashboard/TasksPage/FinishedPage";

const TaskCardLayout = () => {
  const href = window.location.pathname;
  const [project] = useRecoilState(projectInfo);
  const location = useLocation();
  const [activeCard, setActiveCard] = React.useState("inProgress");
  const [inprogressTasks, setInprogressTasks] = useState({
    items: [],
    total_count: 0,
  });
  const [assignedTasks, setAssignedTasks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (location.pathname === "/tasks") {
      setActiveCard("inProgress");
    } else if (location.pathname.includes("assigned")) {
      setActiveCard("assigned");
    } else if (location.pathname.includes("finished")) {
      setActiveCard("finished");
    }
  }, [location.pathname]);

  const {
    data: inprogressTasksData,
    isLoading: isLoadingInprogressTasks,
    isFetching: isFetchingInprogressTasks,
    refetch: refetchInprogressTasks,
    isSuccess: isSuccessInprogressTasks,
  } = useQuery(
    "allTasksInprogress",
    () => fetchProjectMainTasks({ project_id: project.id, status: "inprogress" }),
    {
      retry: false,
      enabled: !!project.id,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: finishedTasks,

    refetch: refetchFinishedTasks,
    isSuccess: isSuccessFinishedTasks,
  } = useQuery(
    "finishedTasks",
    () => fetchProjectMainTasks({ project_id: project.id, status: "finished" }),
    {
      retry: false,
      enabled: !!project.id,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (!isLoadingInprogressTasks) {
      const assignedItems = inprogressTasksData?.items?.filter(
        (item: any) => item.is_assigned === true
      );
      setAssignedTasks(assignedItems);
    }

    if (!isLoadingInprogressTasks) {
      const inprogressItems = inprogressTasksData?.items?.filter(
        (item: any) => item.is_assigned === false
      );
      setInprogressTasks((prevTasks) => ({
        ...prevTasks,
        items: inprogressItems || [],
        total_count: inprogressItems?.length || 0,
      }));
    }
  }, [isFetchingInprogressTasks]);

  useEffect(() => {
    if (isSuccessInprogressTasks && isSuccessFinishedTasks) {
      setLoading(false);
    }
  }, [isSuccessInprogressTasks, isSuccessFinishedTasks]);

  return (
    <AssessmentLayout>
      {loading ? (
        <LoadingSpinner size="w-8 h-8" />
      ) : (
        <>
          <ContentBar title={t("tasks_plural")} />
          <div className="flex mb-8 my-4 gap-6 flex-col md:flex-row">
            <Link
              to="/tasks"
              className={`w-full rounded-lg overflow-hidden cursor-pointer ${
                activeCard === "inProgress" ? "bg-yellow-50  shadow-lg" : "bg-white shadow"
              } `}>
              <div className="flex items-center gap-5 p-4">
                <div className="bg-[#FCD34D] p-2 rounded-lg w-fit">
                  <CheckCircleIcon className="w-6 h-6 text-white mx-auto" />
                </div>
                <div>
                  <div className="text-sm text-[#6B7280]">{t("inProgress")}</div>
                  <div className="text-xl font-semibold">
                    {inprogressTasks?.total_count}{" "}
                    {inprogressTasks?.total_count !== 1 && t("tasks_plural")}{" "}
                    {inprogressTasks?.total_count === 1 && t("task")}
                  </div>
                </div>
              </div>
              <div
                className={`${
                  activeCard === "inProgress" ? "bg-yellow-50" : "bg-white "
                }  py-2 px-4`}>
                <div className="text-[#4F46E5] text-sm cursor-pointer">{t("viewAll")}</div>
              </div>
            </Link>
            <Link
              to="/tasks/assigned"
              className={`w-full rounded-lg overflow-hidden cursor-pointer ${
                activeCard === "assigned" ? "bg-rose-50  shadow-lg" : "bg-white shadow"
              } `}>
              <div className="flex items-center gap-5 p-4">
                <div className="bg-rose-400 p-2 rounded-lg w-fit">
                  <UserGroupIcon className="w-6 h-6 text-white mx-auto" />
                </div>
                <div>
                  <div className="text-sm text-[#6B7280]">{t("assigned")}</div>
                  <div className="text-xl font-semibold">
                    {assignedTasks.length} {assignedTasks.length === 1 && t("task")}{" "}
                    {assignedTasks.length !== 1 && t("tasks_plural")}
                  </div>
                </div>
              </div>
              <div
                className={`${activeCard === "assigned" ? "bg-rose-50" : "bg-white "}  py-2 px-4`}>
                <div className="text-[#4F46E5] text-sm cursor-pointer">{t("viewAll")}</div>
              </div>
            </Link>
            <Link
              to="/tasks/finished"
              className={`w-full rounded-lg overflow-hidden cursor-pointer ${
                activeCard === "finished" ? "bg-green-50  shadow-lg" : "bg-white shadow"
              } `}>
              <div className="flex items-center gap-5 p-4">
                <div className="bg-[#34D399] p-2 rounded-lg w-fit">
                  <ClipboardDocumentCheckIcon className="w-6 h-6 text-white mx-auto" />
                </div>
                <div>
                  <div className="text-sm text-[#6B7280]">{t("finished")}</div>
                  <div className="text-xl font-semibold">
                    {finishedTasks?.total_count}{" "}
                    {finishedTasks?.total_count > 1 && t("tasks_plural")}
                    {finishedTasks?.total_count <= 1 && t("task")}
                  </div>
                </div>
              </div>
              <div
                className={`${activeCard === "finished" ? "bg-green-50" : "bg-white "}  py-2 px-4`}>
                <div className="text-[#4F46E5] text-sm cursor-pointer">{t("viewAll")}</div>
              </div>
            </Link>
          </div>

          {href === "/tasks" && (
            <InProgressPage
              refetch={() => {
                refetchInprogressTasks();
                refetchFinishedTasks();
              }}
              data={inprogressTasks}
            />
          )}
          {href === "/tasks/assigned" && (
            <AssignedPage
              refetch={() => {
                refetchInprogressTasks();
                refetchFinishedTasks();
              }}
              data={assignedTasks}
            />
          )}
          {href === "/tasks/finished" && (
            <FinishedPage
              refetch={() => {
                refetchInprogressTasks();
                refetchFinishedTasks();
              }}
              data={finishedTasks}
            />
          )}

          <ToastNotify />
        </>
      )}
    </AssessmentLayout>
  );
};

export default TaskCardLayout;
