import { ButtonProps } from "@/common/ButtonProps";

import React from "react";
import SetButton from "./SetButton";
import CancelButton from "./CancelButton/CancelButton";
import DefaultButton from "./DefaultButton/DefaultButton";
import DefaultHalfButton from "./DefaultHalfButton/DefaultHalfButton";
import HalfBackButton from "./HalfBackButton/HalfBackButton";

const Button: React.FC<ButtonProps> = ({ buttonType, text, onClick, className }) => {
  switch (buttonType) {
    case "setButton":
      return <SetButton text={text} onClick={onClick} />;
    case "cancelButton":
      return (
        <CancelButton text={text} onClick={onClick}>
          {text}
        </CancelButton>
      );
    case "defaultButton":
      return <DefaultButton onClick={onClick}>{text}</DefaultButton>;
    case "defaultHalfButton":
      return (
        <DefaultHalfButton type="button" className={className} onClick={onClick}>
          {text}
        </DefaultHalfButton>
      );
    case "halfBackButton":
      return (
        <HalfBackButton type="button" className={className} onClick={onClick}>
          {text}
        </HalfBackButton>
      );
    default:
      return <button onClick={onClick}>{text}</button>;
  }
};

export default Button;
