import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import mixpanel from "mixpanel-browser";

import "./index.css";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "react-query";
import "./i18n";
import { GlobalContext, GlobalProvider } from "./context/GlobalStateProvider";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

mixpanel.init(process.env.REACT_APP_MIX_PANEL_TOKEN as string, { debug: true });

ReactDOM.render(
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <GlobalProvider>
        <App />
      </GlobalProvider>
    </QueryClientProvider>
  </RecoilRoot>,
  document.getElementById("root")
);
