import { fetchCreditsInformation } from "@/api/account";
import { authMe } from "@/api/auth";
import { fetchAutomatedDesignerList } from "@/api/automated-designer/automated-designer";
import CapacityBar from "@/components/CapacityInformations/DesignCapacityBar";
import ContentBar from "@/components/ContentBar";
import AssessmentLayout from "@/components/Layout/Assessment";
import PageLoading from "@/components/Loading/PageLoading";
import RightModal from "@/components/Modal/RightModal";
import SideModal from "@/components/Modal/SideModal";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import SecondaryButton from "@/components/buttons/SecondaryButton";
import DesignCard from "@/components/cards/DesignCard/DesignCard";
import InfoBoard from "@/components/cards/InfoCard";
import CreateDesignModal from "@/features/automated-designer/CreateDesignModal";
import ImagePreviewModal from "@/features/automated-designer/ImagePreviewModal";
import GetExtraCredit from "@/features/profile/components/GetExtraCredit";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import { fetchAddressFromIP } from "@/utils/fetchAddressFromIP";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdDesignServices } from "react-icons/md";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";

const AutomatedDesigner = () => {
  const { t } = useTranslation();
  const [project] = useRecoilState(projectInfo);
  const projectId = project.id;
  const projectPlan = project.pricingPlan;
  const [previewImage, setPreviewImage] = useState("");
  const [generateModal, setGenerateModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [creditModalOpen, setCreditModalOpen] = useState(false);
  const [locdingIp, setIsLoadingIp] = useState(false);
  const [currenctFromCountry, setCurrencyFromCountry] = useState<any>(null);

  useEffect(() => {
    setIsLoadingIp(true);
    fetchAddressFromIP().then((res) => {
      if (res.country_name === "Turkey") setCurrencyFromCountry("TRY");
      else setCurrencyFromCountry("USD");
      setIsLoadingIp(false);
    });
  }, []);

  const {
    data: authData,
    refetch: refetchAuthData,
    isLoading: isLoadingAuth,
  } = useQuery("authmeProfile", authMe);

  const { data, isLoading, isFetched, refetch } = useQuery(
    "fetchAutomatedDesignList",
    () =>
      fetchAutomatedDesignerList({
        projectId: projectId,
      }),
    {
      retry: false,
      enabled: !!projectId,
      cacheTime: 0,
    }
  );

  console.log(data);

  const handleImagePreview = (url: string) => {
    setPreviewImage(url);
    setModalOpen(true);
  };

  return (
    <AssessmentLayout>
      <div className="flex justify-between items-center z-[99]  pt-2">
        <ContentBar title="Automated Designer" />
        <div className="hidden lg:block">
          <SecondaryButton
            name="Create New Design"
            onClick={() => setGenerateModal(true)}
            icon={<MdDesignServices />}
          />
        </div>
      </div>

      <CapacityBar className="mt-6 hidden" projectId={projectId} projectPlan={projectPlan} />

      {isLoading ? (
        <LoadingSpinner />
      ) : data && data.length !== 0 ? (
        <div className="grid grid-cols-4 gap-4 pb-10 mt-6">
          {data.map((row: any, index: number) => (
            <DesignCard key={index} handleViewImage={handleImagePreview} data={row} />
          ))}
        </div>
      ) : (
        <div className="w-full bg-white mt-6 ">
          <InfoBoard
            title={t("noDesignGeneratedYet")}
            description={t("noDesignGeneratedYetDescription")}
            action={
              <SecondaryButton name={t("createNewDesign")} onClick={() => setGenerateModal(true)} />
            }
          />
        </div>
      )}
      <ImagePreviewModal
        imageUrl={previewImage}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />

      <CreateDesignModal
        size="w-3/12"
        open={generateModal}
        onClose={() => setGenerateModal(false)}
        projectId={projectId}
        projectPlan={projectPlan}
        refetch={() => refetch()}
        handlePreview={handleImagePreview}
        handleGetCredits={() => setCreditModalOpen(true)}
      />

      <SideModal
        open={creditModalOpen}
        onClose={() => setCreditModalOpen(false)}
        title="Get Extra Credit(s)"
        size="w-4/12">
        <GetExtraCredit
          onClose={() => setCreditModalOpen(false)}
          projectId={projectId}
          authData={authData}
          currenctFromCountry={currenctFromCountry}
          refetch={refetchAuthData}
        />
      </SideModal>
    </AssessmentLayout>
  );
};

export default AutomatedDesigner;
