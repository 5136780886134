import React, { useEffect, useState } from "react";
import AssessmentLayout from "@/components/Layout/Assessment";
import { useTranslation } from "react-i18next";
import ContentBar from "@/components/ContentBar";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import ReportCard from "@/features/report/components/cards/ReportCard";
import { pageViewEvent } from "@/analytics/mixpanel";
import { userAtom } from "@/store/atoms/user.atoms";
import { useRecoilState } from "recoil";
import {
  getAnalyticsEnabled,
  getAnalyticsReportMetrics,
  getAnalyticsReportTemplates,
  getAnalyticsReports,
  getSelectedProperties,
} from "@/api/eAnl";
import { fetchReports } from "@/api/reports";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import Modal from "@/components/Modal";
import SetForm from "./popup/SetForm";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import Breadcrumb from "@/components/breadcrumb/breadcrumb";
import { adReportBreadCrumb } from "@/store/atoms/adReportBreadcrumb.atom";

const ReportsPage = () => {
  const { t } = useTranslation();
  const [project] = useRecoilState(projectInfo);
  const projectId = project.id;
  const pricingPlan = project.pricingPlan;
  const [openPopup, setOpenPopup] = useState(false);
  const [isLoading, setLoadingState] = useState(true);
  const [isLoadingAnalyticsMetrics, setIsLoadingAnalyticsMetrics] = useState(true);
  const [isLoadingAnalyticsTemplates, setIsLoadingAnalyticsTemplates] = useState(true);
  const [isLoadingAnalytics, setIsLoadingAnalytics] = useState(true);
  const [isLoadingSelectedProperties, setIsLoadingSelectedProperties] = useState(true);
  const [isLoadingInPopup, setLoadingStateInPopup] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);
  const [breadcrumbState, setBreadcrumbState] = useRecoilState(adReportBreadCrumb);

  localStorage.removeItem("table-filter");

  useEffect(() => {
    setBreadcrumbState([]);
  }, []);

  const handleRefresh = () => {
    setRefreshCount((prevCount) => prevCount + 1); // Trigger a change in refreshCount, which will re-run the useEffect
    setOpenPopup(false);
  };

  const setLoadingInPopup = (state: boolean) => {
    setLoadingStateInPopup(state);
  };

  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (projectId) {
      const fetchData = async () => {
        try {
          const result = await fetchReports(projectId);
          setData(result);
          setLoadingState(false);
        } catch (error: any) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
      console.log("id");
    }
  }, [projectId]);

  const [isAnalyticsActive, setIsAnalyticsActive] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAnalyticsEnabled(projectId);
        setIsAnalyticsActive(result);
        if (!result) {
          setIsLoadingAnalyticsMetrics(false);
          setIsLoadingAnalyticsTemplates(false);
          setIsLoadingAnalytics(false);
          setIsLoadingSelectedProperties(false);
        } else {
          handleRefresh();
        }
      } catch (error: any) {
        if (error.response.status === 401) return (window.location.href = "/integrations");
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [analyticsMetrics, setAnalyticsMetrics] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAnalyticsReportMetrics(projectId);
        setAnalyticsMetrics(result.data);
        setIsLoadingAnalyticsMetrics(false);
      } catch (error: any) {
        if (error.response.status === 401) return (window.location.href = "/integrations");
        console.error("Error fetching data:", error);
      }
    };

    isAnalyticsActive && fetchData();
  }, [refreshCount]);

  const [analyticsTemplates, setAnalyticsTemplates] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAnalyticsReportTemplates(projectId);
        setAnalyticsTemplates(result.data);
        setIsLoadingAnalyticsTemplates(false);
      } catch (error: any) {
        if (error.response.status === 401) return (window.location.href = "/integrations");
        console.error("Error fetching data:", error);
      }
    };

    isAnalyticsActive && fetchData();
  }, [refreshCount]);

  const [analyticsData, setAnalyticsData] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAnalyticsReports(projectId);
        setAnalyticsData(result.data);
        setIsLoadingAnalytics(false);
      } catch (error: any) {
        if (error.response.status === 401) return (window.location.href = "/integrations");
        else if (error.response.status === 404) {
          setAnalyticsData([]);
          setIsLoadingAnalytics(false);
        }

        console.error("Error fetching data:", error);
      }
    };

    isAnalyticsActive && fetchData();
  }, [refreshCount, projectId]);

  const [user] = useRecoilState(userAtom);

  useEffect(() => {
    const getPaths = JSON.parse(localStorage.getItem("paths") as any);

    pageViewEvent({
      pageName: "reports",
      userId: user?.id.toString() || "",
      email: user?.email || "",
      previousPage: getPaths?.currentPath || "",
    });
  }, []);

  const [propertyId, setPropertyId] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getSelectedProperties(projectId);
        setPropertyId(result.data?.Id);
        setIsLoadingSelectedProperties(false);
      } catch (error: any) {
        if (error.response.status === 401) return (window.location.href = "/integrations");
        else if (error.response.status === 404)
          return (window.location.href = "/google-analytics/properties");
        console.error("Error fetching data:", error);
      }
    };

    isAnalyticsActive && fetchData();
  }, [refreshCount]);

  const getMetricsSentence = (report: any) => {
    if (!isLoadingAnalyticsMetrics) {
      const metricNames = report.Metrics.map((metricId: any) => {
        const metric = analyticsMetrics.find((m: any) => m.Id === metricId);
        return metric ? metric.Name : "";
      }).filter(Boolean); // Filter out any undefined or empty metric names

      if (metricNames.length === 0) return "No metrics available.";

      return `Metrics: ${metricNames.join(", ")}.`;
    }
  };

  const getTemplateOptions = (templates: any) => {
    if (isLoadingAnalyticsTemplates) {
      return [{ value: "loading", label: "Loading templates..." }];
    }

    if (templates && templates.length > 0) {
      return templates.map((template: any) => ({
        value: template.TemplateName,
        label: template.TemplateName,
      }));
    }

    return [{ value: "0", label: "No templates available" }];
  };

  const getMetricOptions = (metrics: any) => {
    if (isLoadingAnalyticsMetrics) {
      return [{ value: "loading", label: "Loading metrics..." }];
    }

    if (metrics && metrics.length > 0) {
      return metrics.map((metric: any) => ({ value: metric.Id, label: metric.Name }));
    }

    return [{ value: "0", label: "No metrics available" }];
  };

  const getSelectedMetricOptions = (templateName?: string) => {
    if (isLoadingAnalyticsTemplates) {
      return [];
    }

    const template = analyticsTemplates.find((t: any) => t.TemplateName === templateName);
    if (template) {
      return template.Metrics.map((metricId: any) => {
        const metric = analyticsMetrics.find((m: any) => m.Id === metricId);
        return { value: metric.Id, label: metric.Name };
      }).filter(Boolean); // Filter out any undefined or empty metric names
    }

    return [];
  };

  return (
    <div data-testid="integrations-page">
      <AssessmentLayout>
        <ContentBar title={t("reports")} />
        <Breadcrumb />
        {isLoading ||
        isLoadingAnalytics ||
        isLoadingAnalyticsMetrics ||
        isLoadingAnalyticsTemplates ||
        isLoadingSelectedProperties ? (
          <div className="flex h-[calc(100vh-5rem)] justify-center items-center ">
            <LoadingSpinner size="w-8 h-8" />
          </div>
        ) : (
          <>
            <div>
              {isAnalyticsActive ? (
                <DefaultButton className="w-1/6" onClick={() => setOpenPopup(true)}>
                  {t("createReport")}
                </DefaultButton>
              ) : (
                <div className="mt-2 p-4 bg-red-100 border-l-4 border-red-500 text-red-700">
                  <p className="font-semibold text-lg">
                    {t("toCreateReportPleaseConnectAccounts")}
                  </p>
                </div>
              )}
            </div>
            <div className="flex flex-wrap gap-7 my-8 overflow-visible">
              {Array.isArray(data) &&
                data.length > 0 &&
                data?.map((report: any, index: any) => (
                  <ReportCard
                    data={report}
                    thumbnail={"/assets/images/reports/google-ads.png"}
                    pricingPlan={pricingPlan}
                    key={index}
                    application={"google_ads"}
                    reportDescription={report.description}
                  />
                ))}
              {Array.isArray(analyticsData) &&
                analyticsData.length > 0 &&
                analyticsData?.map((report: any, index: any) => (
                  <ReportCard
                    thumbnail={"/assets/images/reports/analytics.png"}
                    pricingPlan={pricingPlan}
                    key={index}
                    data={report}
                    application={"google_analytics"}
                    reportDescription={getMetricsSentence(report)}
                  />
                ))}
            </div>
          </>
        )}
        <br />
      </AssessmentLayout>

      <div id="modal-root">
        <Modal
          isOpen={openPopup}
          onClose={() => setOpenPopup(false)}
          title={t("createReport")}
          description={t("pleaseFillAllTheFields")}>
          {isLoadingInPopup ? (
            <div className="flex align-center">
              <LoadingSpinner size="w-8 h-8" />
            </div>
          ) : (
            <>
              <SetForm
                projectId={projectId}
                propertyId={propertyId}
                templateOptions={getTemplateOptions(analyticsTemplates)}
                metricOptions={getMetricOptions(analyticsMetrics)}
                getSelectedMetrics={getSelectedMetricOptions}
                buttonText={t("setAutoPilot")}
                placeholder={t("atLeastSelectAnOption")}
                setLoading={setLoadingInPopup}
                onRefresh={() => handleRefresh()}></SetForm>
            </>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default ReportsPage;
