import { useEffect, useState } from "react";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import ReactEcharts from "echarts-for-react";

type Props = {
  data: any;
};

interface ChartData {
  name: string;
  legends: string[];
  xAxis: any;
  series: {
    name: string;
    type: string;
    stack: string;
    data: any;
  }[];
}

const AdMetricsChart = (props: Props) => {
  const { data } = props;
  const [chartData, setChartData] = useState<ChartData | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (data) {
      const newData = {
        name: "Ad Metrics",
        legends: [
          "Avarage Cost",
          "Avarage CPS",
          "Avarage CPM",
          "Clicks",
          "Conversion",
          "Cost",
          "CTR",
          "Impressions",
        ],
        xAxis: data.allConversionsByDate.map((i: any) => i.date),
        series: [
          {
            name: "Avarage Cost",
            type: "line",
            stack: "Total",
            data: data.averageCostByDate.map((i: any) => i.value.toFixed(2)),
          },
          {
            name: "Avarage CPS",
            type: "line",
            stack: "Total",
            data: data.averageCpcByDate.map((i: any) => i.value.toFixed(2)),
          },
          {
            name: "Avarage CPM",
            type: "line",
            stack: "Total",
            data: data.averageCpmByDate.map((i: any) => i.value.toFixed(2)),
          },
          {
            name: "Clicks",
            type: "line",
            stack: "Total",
            data: data.clicksByDate.map((i: any) => i.value.toFixed(2)),
          },
          {
            name: "Conversion",
            type: "line",
            stack: "Total",
            data: data.conversionsByDate.map((i: any) => i.value.toFixed(2)),
          },
          {
            name: "Cost",
            type: "line",
            stack: "Total",
            data: data.costByDate.map((i: any) => i.value.toFixed(2)),
          },
          {
            name: "CTR",
            type: "line",
            stack: "Total",
            data: data.ctrByDate.map((i: any) => i.value.toFixed(2)),
          },
          {
            name: "Impressions",
            type: "line",
            stack: "Total",
            data: data.impressionsByDate.map((i: any) => i.value.toFixed(2)),
          },
        ],
      };

      setChartData(newData);
      setLoading(false);
    }
  }, [data]);

  const option = {
    tooltip: {
      trigger: "axis",
    },
    legend: {
      left: 0,
      top: 0,
      data: chartData?.legends.map((i: any) => i),
    },
    grid: {
      left: "0",
      right: "0",
      bottom: "0",
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: chartData?.xAxis.map((i: any) => i),
    },
    yAxis: {
      type: "value",
    },
    series: chartData?.series.map((i: any) => i),
  };

  return (
    <div className="flex flex-col flex-wrap gap-4 md:gap-10 pb-4 mt-4">
      {loading ? <LoadingSpinner /> : <ReactEcharts option={option} />}
    </div>
  );
};

export default AdMetricsChart;
