import { useEffect } from "react";
import { useMutation } from "react-query";
import { postProjectData } from "@/api/project";
import { useRecoilState } from "recoil";
import { t } from "i18next";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import GoogleAdsCard from "@/features/onboarding/components/ConnectAccounts/GoogleAdsCard";
import GoogleAnalyticsCard from "@/features/onboarding/components/ConnectAccounts/GoogleAnalyticsCard";

import { useNavigate } from "react-router-dom";
import { userAtom } from "@/store/atoms/user.atoms";
import { ProjectDataType } from "@/models/projectDataType";
import { listenButtonClickedEvent, pageViewEvent, saveOnboardingEvent } from "@/analytics/mixpanel";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import PrimaryButton from "@/components/buttons/PrimaryButton";

type Props = {
  projectId: number;
  setCurrentStep: (step: number) => void;
};

const SecondStep = (props: Props) => {
  const navigate = useNavigate();
  const { projectId } = props;
  const [user] = useRecoilState(userAtom);

  const { mutateAsync, isLoading } = useMutation("postProjectDomainData", postProjectData, {
    retry: false,
  });

  const handleSaveCountry = () => {
    mutateAsync({
      projectId,
      project: {
        datatype_name: ProjectDataType.ONBOARDING,
        data: {
          value: true,
        },
      },
    }).then(() => {
      saveOnboardingEvent({
        userId: user?.id.toString() || "",
        email: user?.email || "",
      });
      localStorage.removeItem("stepper");
      localStorage.removeItem("onboardingItems");
      localStorage.removeItem("onboardingCurrentStep");
      navigate("/");
    });
  };

  const handleSaveSkip = () => {
    mutateAsync({
      projectId,
      project: {
        datatype_name: ProjectDataType.ONBOARDING,
        data: {
          value: true,
        },
      },
    }).then(() => {
      saveOnboardingEvent({
        userId: user?.id.toString() || "",
        email: user?.email || "",
      });

      listenButtonClickedEvent({
        buttonName: "onboarding(account) skip",
        userId: user?.id.toString() || "",
        email: user?.email || "",
        skip: true,
      });

      localStorage.removeItem("stepper");
      localStorage.removeItem("onboardingItems");
      localStorage.removeItem("onboardingCurrentStep");
      navigate("/");
    });
  };

  useEffect(() => {
    const getPaths = JSON.parse(localStorage.getItem("paths") as any);

    pageViewEvent({
      pageName: "onboarding(accounts)",
      userId: user?.id.toString() || "",
      email: user?.email || "",
      previousPage: getPaths?.currentPath || "",
    });
  }, []);

  return (
    <div className="h-full w-full flex  flex-col items-center justify-center  relative">
      <div className="grid grid-cols-2 gap-4  ">
        <GoogleAnalyticsCard
          title="Google Analytics"
          description="Get digital marketing insights based on your analytics  data..
            "
        />
        <GoogleAdsCard
          title="Google Ads"
          description="Manage your marketing budget effectively. "
        />
      </div>

      <div className="absolute bottom-0 w-full flex justify-end ">
        {isLoading ? (
          <LoadingSpinner size="w-6 h-6" />
        ) : (
          <div className="flex items-center gap-2 ">
            <PrimaryButton outline name={t("skip")} onClick={() => handleSaveSkip()} />
            <PrimaryButton name={t("finish")} onClick={() => handleSaveCountry()} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SecondStep;
