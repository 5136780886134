import React from "react";
import { MdOutlineSpeakerNotesOff } from "react-icons/md";

type Props = {
  title?: string;
  description?: string;
  action?: any;
};

const InfoBoard = (props: Props) => {
  const { title, description, action } = props;
  return (
    <div className="w-full flex flex-col items-center p-10 bg-gray-50">
      <div className="w-8/12 flex flex-col items-center">
        <div className="flex flex-col items-center justify-center bg-white border shadow rounded-full w-20 h-20">
          <MdOutlineSpeakerNotesOff className="text-4xl text-secondaryHoverColor" />
        </div>
        <span className="font-semibold text-md text-gray-600 text-center mt-2"> {title} </span>
        <span className="text-sm text-center text-gray-700 mt-2"> {description} </span>
        <div className="mt-4">{action}</div>
      </div>
    </div>
  );
};

export default InfoBoard;
