export function currentDayToMinus(minusDay: number) {
  // Günümüz tarihini al
  const today = new Date();

  // Bir gün geriye çek
  today.setDate(today.getDate() - minusDay);

  // Tarihi "YYYY-MM-DD" formatına dönüştür
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Ay 0-11 arası olduğu için +1
  const day = String(today.getDate()).padStart(2, "0");

  const formatted = `${year}-${month}-${day}`;
  return formatted;
}
