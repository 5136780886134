import { atom } from "recoil";

export const projectDataOnboarding = atom<{
  id: number;
  project_id: number;
  create_date: string;
  display_name: string;
  is_deleted: boolean;
  data: {
    value: boolean;
  };
}>({
  key: "projectDataOnboarding",
  default: {
    id: 0,
    project_id: 0,
    create_date: "2022-12-26T12:12:47.520233",
    display_name: "ONBOARDING",
    is_deleted: false,
    data: {
      value: false,
    },
  },
});
