import AssessmentLayout from "@/components/Layout/Assessment";
import ContentBar from "@/components/ContentBar";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Breadcrumb from "@/components/breadcrumb/breadcrumb";
import AdGroupKeywordMetricsTable from "@/features/report/components/ad-reports/tables/ad-group-keywords/AdGroupKeywordMetricsTable";
import CampaignCard from "@/features/ads-management/components/cards/CampaignCard";

const AdGroupKeywordsPage = () => {
  const { t } = useTranslation();
  const { projectId, clientId, reportId, adGroupId, campaignId } = useParams();

  return (
    <div>
      <AssessmentLayout>
        <ContentBar title={t("adGroupKeywords")} />
        <Breadcrumb
          adGroupId={adGroupId}
          campaignId={campaignId}
          reportId={reportId}
          clientId={clientId}
          projectId={projectId}
        />
        <div>
          <AdGroupKeywordMetricsTable tableId="adGroupKeywordMetrics" />
        </div>
      </AssessmentLayout>
    </div>
  );
};

export default AdGroupKeywordsPage;
