import { Dialog, Transition } from "@headlessui/react";
import React from "react";
import { MdClose } from "react-icons/md";

type Props = {
  open: boolean;
  children?: any;
  onClose: () => void;
  title?: string;
  size?: string;
};

const SideModal = (props: Props) => {
  const { open, title, children, onClose, size = "w-5/12" } = props;
  return (
    <Transition show={open} as={React.Fragment}>
      <Dialog className="z-50 relative" onClose={onClose}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="ropacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          as={React.Fragment}>
          <div className="fixed z-30 inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 flex justify-end w-screen  h-screen ">
          <div
            className={
              "flex min-h-full items-end justify-center  text-center sm:items-center bg-white overflow-y-auto  " +
              size
            }>
            <Transition.Child
              enter="transition ease-in-out duration-500 transform"
              enterFrom="opacity-0"
              enterTo="opacity-1"
              leave="transition ease-in-out duration-500 transform"
              leaveFrom="opacity-1"
              leaveTo="opacity-0"
              as={React.Fragment}>
              <Dialog.Panel className="relative transform flex flex-col justify-start items-start w-full transition-all h-screen">
                <button
                  className="absolute top-4 right-4 z-40 border-0 outline-0"
                  onClick={onClose}>
                  <MdClose className="text-xl" />
                </button>
                <div className="relative w-full flex justify-start min-h-screen max-h-screen   ">
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SideModal;
