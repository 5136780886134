import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { t } from "i18next";
import { fetchCreditInfo, fetchPaymentForm } from "@/api/credit";
import { useMutation, useQuery } from "react-query";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { profile } from "console";
import ProfileInformationUpdate from "../ProfileInformationUpdate";

interface Props {
  onClose: () => void;
  authData: any;
  projectId: any;
  currenctFromCountry: any;
  refetch?: () => void;
}

export default function GetExtraCredit(props: Props) {
  const { onClose, authData, currenctFromCountry, projectId, refetch } = props;
  const [amount, setAmount] = useState<any>(10);
  const { data: creditInfo, isLoading } = useQuery("creditInfo", () =>
    fetchCreditInfo(currenctFromCountry)
  );
  const [creditLoading, setCreditLoading] = useState(false);
  const navigate = useNavigate();
  const [profileUpdate, setProfileUpdate] = useState(false);

  const { mutateAsync: mutateAsyncAuthCustomForm, isLoading: isLoadingForm } =
    useMutation(fetchPaymentForm);

  const handleCustomClick = () => {
    setCreditLoading(true);
    mutateAsyncAuthCustomForm({
      projectId: projectId,
      amount: amount,
      currency: currenctFromCountry,
    }).then((res) => {
      if (res.status === "failure") {
        alert(res.error_message);
        setCreditLoading(false);
      } else {
        navigate("/payment", {
          state: {
            checkoutFormContent: res.checkoutFormContent,
          },
        });
        setCreditLoading(false);
        onClose();
      }
    });
  };

  useEffect(() => {
    if (!authData?.invoice_type || !authData?.address?.country) {
      setProfileUpdate(true);
      return;
    } else {
      setProfileUpdate(false);
    }
  }, [authData]);

  return profileUpdate ? (
    <div className="w-full lex flex-col p-4">
      <ProfileInformationUpdate refetch={refetch} />
    </div>
  ) : (
    <div className="flex flex-col w-full p-4">
      <div className="sm:flex justify-start items-center">
        <div className="mt-3 text-center sm:mt-0 sm:text-left">
          <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-gray-900 ">
            Buy extra credit
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">How many credits do you need?</p>
          </div>
        </div>
      </div>
      <div>
        <input
          type="number"
          defaultValue={amount}
          min={creditInfo?.min_value ?? 10}
          step={creditInfo?.step ?? 10}
          max={10000}
          className="w-full border border-gray-300 rounded-md p-2 mt-4 outline-none"
          onKeyDown={(e) => {
            if (e.key === "." || e.key === "," || e.key === "-") {
              e.preventDefault();
            }
          }}
          onChange={(e) => setAmount(Number(e.target.value))}
        />
      </div>
      <div className="mt-5 sm:mt-4 grid grid-cols-2 gap-3">
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={onClose}>
          {t("cancel")}
        </button>
        <DefaultButton isDisabled={creditLoading} onClick={() => handleCustomClick()}>
          {t("buy")} {amount * creditInfo?.credit} {currenctFromCountry === "USD" ? "$" : "₺"}
        </DefaultButton>
      </div>
    </div>
  );
}
