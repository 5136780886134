import client from "./client";

export const putStoryTask = async (data: { taskId: number; projectStoryId: number }) => {
  const response = await client.put(`/stories/${data.projectStoryId}/task/${data.taskId}`);
  return response.data;
};

export const fetchStoryDetail = async (projectStoryId: number) => {
  const response = await client.get(`/stories/${projectStoryId}`);
  return response.data;
};

export const fetchStoryTemplates = async () => {
  const response = await client.get(`/stories/templates`);
  return response.data;
};

export const fetchStoryCategories = async () => {
  const response = await client.get(`project/task/categories`);
  return response.data;
};

export const fetchStoryAssignableTasks = async (projectStoryId: number) => {
  const response = await client.get(`/stories/${projectStoryId}/assignable-tasks`);
  return response.data;
};

export const fetchStoryPublishedData = async (projectStoryId: number) => {
  const response = await client.get(`/stories/${projectStoryId}/preview`);
  return response.data;
};

// new endpoints
export const fetchMultiTimeline = async (projectStoryId: number) => {
  const response = await client.get(`stories/${projectStoryId}/messages`);
  return response.data;
};

export const postMessageMultiTimeline = async (data: {
  project_story_id: number;
  message: string;
  files: File[];
}) => {
  const form_data = new FormData();
  form_data.append("message", data.message);

  if (data.files && data.files.length > 0) {
    for (let i = 0; i < data.files.length; i++) {
      // add each file to form data
      form_data.append(`files`, data.files[i]);
    }
  }

  const response = await client.post(`stories/${data.project_story_id}/messages`, form_data);
  return response.data;
};

export const finishStory = async (projectStoryId: number) => {
  const response = await client.post(`/stories/${projectStoryId}/finish`);
  return response.data;
};

// Correct syntax for type annotation in function parameters
export const reproduceAnswer = async ({
  projectStoryId,
  feedback = "",
}: {
  projectStoryId: number;
  feedback?: string;
}) => {
  // Assuming your API expects a body with feedback.
  const requestBody = feedback ? { feedback: feedback } : null;
  if (requestBody) {
    const response = await client.post(`/stories/${projectStoryId}/reproduce-answer`, requestBody);
    return response.data;
  } else {
    const response = await client.post(`/stories/${projectStoryId}/reproduce-answer`);
    return response.data;
  }
};
