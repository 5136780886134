import React, { useEffect, useState } from "react";
import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

interface Options {
  id: any;
  name: string;
  phoneCode: string; // Adjusted to match the type used in PhoneField
  avatar: string;
}

interface Props {
  data: Options[];
  defaultValue?: Options | null;
  handleChange: (selected: Options | null) => void; // Use handleChange instead of handleSelect
}

const classNames = (...classes: string[]) => classes.filter(Boolean).join(" ");

const PhoneCodeSelect: React.FC<Props> = ({ data, defaultValue, handleChange }: Props) => {
  const [selected, setSelected] = useState<Options | null>(defaultValue || null);

  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    handleChange(selected);
  }, [selected]);

  return (
    <Listbox value={selected} onChange={setSelected}>
      <>
        <div className="relative  w-auto">
          <Listbox.Button className="flex items-center relative cursor-default gap-1 pr-2 mr-2 border-r text-left text-gray-900 ">
            <span className="flex gap-1 items-center">
              {selected && (
                <>
                  <img
                    src={selected.avatar}
                    alt=""
                    className="h-5 w-5 flex-shrink-0 rounded-full"
                  />
                  <span className="text-md block truncate">{selected.phoneCode}</span>
                </>
              )}
            </span>
            <span className="pointer-events-none flex items-center ">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>

          <Listbox.Options className="absolute z-10 mt-1 max-h-56 min-w-[90px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {data.map((item) => (
              <Listbox.Option
                key={item.id}
                className={({ active }) =>
                  classNames(
                    active ? "bg-gray-200 " : "text-gray-900",
                    "relative cursor-default select-none py-2 pl-3 pr-9"
                  )
                }
                value={item}>
                {({ selected, active }) => (
                  <>
                    <div className="flex items-center">
                      <img
                        src={item.avatar}
                        alt=""
                        className="h-5 w-5 flex-shrink-0 rounded-full"
                      />
                      <span
                        className={classNames(
                          selected ? "font-semibold" : "font-normal",
                          "ml-3 block truncate"
                        )}>
                        {item.name}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          active ? "text-primaryColor" : "text-primaryColor",
                          "absolute inset-y-0 right-0 flex items-center pr-4"
                        )}>
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </>
    </Listbox>
  );
};

export default PhoneCodeSelect;
