import React from "react";

type Props = {
  total: number;
  finished: number;
  label?: string;
};

export const ProgressBar = (props: Props) => {
  const { total, finished, label } = props;

  const percentage = total !== 0 ? (finished / total) * 100 : 0;
  return (
    <div className="flex flex-col w-full gap-2">
      <div className="w-full flex justify-between items-center ">
        <span className="w-6/12 text-sm font-[500]">{finished + " / " + total}</span>
        {percentage !== 0 && (
          <span className="flex justify-end w-6/12 text-sm font-[600]"> {percentage + "%"} </span>
        )}
      </div>
      <div className="w-full h-4 rounded-lg overflow-hidden  bg-gray-200">
        <div className="bg-secondaryColor h-4" style={{ width: `${percentage}%` }}></div>
      </div>
    </div>
  );
};
