import { t } from "i18next";
import React from "react";

type Props = {
  children?: React.ReactNode;
  title?: string;
  description?: string;
  descriptionLink?: string;
  subDescription?: string;
  date: string;
  size?: string;
  customTitle?: string;
  reportDetail: any;
  altTitle?: string;
  message?: string;
};

const AdsContentCard = (props: Props) => {
  const {
    children,
    description,
    title,
    descriptionLink,
    subDescription,
    date,
    customTitle,
    reportDetail,
    altTitle,
    message,
    size = "w-[540px]",
  } = props;

  return (
    <div className={`flex flex-col rounded-[10px] border  text-sm shadow items-center ${size}`}>
      <div className="relative border-b py-3 px-6 bg-[#F8F8FC] h-auto w-full overflow-y-auto custom-scroll rounded-t-[10px] ">
        <div className="absolute right-5 font-medium text-gray-600 text-xs">
          {reportDetail.sample ? (
            <div className=" font-medium text-sm text-orange-500 bg-orange-50 w-fit rounded-lg ">
              {t("sample")}
            </div>
          ) : (
            date
          )}
        </div>
        <div className="font-medium text-base mb-2 capitalize">
          {altTitle} {title || customTitle || "Report"}
        </div>
        <div className="text-gray-500 text-sm ">
          {description}
          {message}
        </div>

        {descriptionLink && (
          <div className="text-xs mt-2">
            <a
              className="text-indigo-600"
              href={descriptionLink || "#"}
              target="_blank"
              rel="noopener noreferrer">
              {t("Learn more about this report")}
            </a>
          </div>
        )}
      </div>
      <div className="h-[350px] bg-white max-h-[350px] bold-custom-scroll overflow-y-auto rounded-b-[10px]  w-full">
        <div className="px-8 flex flex-col justify-center mt-5 w-full">
          <div className="flex">{children}</div>
          <div className="mt-2 text-center">{subDescription}</div>
        </div>
      </div>
    </div>
  );
};

export default AdsContentCard;
