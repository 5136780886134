import client from "../client";

export const fetchAutomatedDesignerList = async (data: { projectId: number }) => {
  const response = await client.get(`/automated-designer/designs/${data.projectId}`, {
    params: {},
  });

  return response.data;
};

export const fetchAutomatedDesignerImageTypes = async () => {
  const response = await client.get(`/automated-designer/image-types`, {
    params: {},
  });

  return response.data;
};

export const automatedDesignerCreate = async (data: {
  project_id: number;
  prompt: string;
  image_type: string;
}) => {
  const response = await client.post(`automated-designer/create-image`, {
    project_id: data.project_id,
    prompt: data.prompt,
    image_type: data.image_type,
  });

  return response.data;
};
