import CircleButton from "@/components/buttons/CircleButton";
import { Menu } from "@headlessui/react";
import React from "react";
import { TbBellRinging } from "react-icons/tb";

export const Notification = () => {
  return (
    <div className="relative ">
      <Menu>
        <Menu.Button className="bg-transparent">
          <CircleButton count={3} icon={<TbBellRinging />} />
        </Menu.Button>

        <Menu.Items className="absolute right-0 z-50 flex flex-col gap-3 border border-gray-300 bg-white rounded-lg shadow-lg p-4 min-w-[400px] max-h-[300px] overflow-auto">
          <h1 className="text-md font-semibold">Notifications</h1>
          {[...Array(2)].map((i:any) => (
            <Menu.Item>
              <div className="pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0">
                <div className="w-0 flex-1 p-4">
                  <div className="flex items-start">
                    <div className=" w-0 flex-1">
                      <p className="text-sm font-medium text-gray-900">New Task</p>
                      <p className="mt-1 text-sm text-gray-500">Socail media keywords</p>
                    </div>
                  </div>
                </div>
                <div className="flex border-l border-gray-200">
                  <button
                    type="button"
                    className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                    View
                  </button>
                </div>
              </div>
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
    </div>
  );
};
