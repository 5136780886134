import { ProgressBar } from "@/components/ProgressBar";
import SecondaryButton from "@/components/buttons/SecondaryButton";
import React from "react";
import { MdOutlineBolt } from "react-icons/md";

type Props = {
  key?: any;
  name?: string;
  category?: string;
  price?: number;
  description?: string;
  onClick: () => void;
  isSelected: boolean;
  isNew: boolean;
  tasks?: number;
  finishedTasks?: number;
};

const StrategyCard = (props: Props) => {
  const {
    key,
    name,
    description = "",
    category,
    price,
    isSelected,
    isNew,
    onClick,
    tasks,
    finishedTasks,
  } = props;

  const categoryClass =
    category === "Amazon"
      ? "text-amazon"
      : category === "SEO"
      ? "text-seo"
      : category === "Google Ads"
      ? "text-googleAds"
      : category === "Social Media"
      ? "text-socialMedia"
      : category === "Content Marketing"
      ? "text-contentMarketing"
      : category === "Meta Ads"
      ? "text-metaAds"
      : "text-black";

  return (
    <div
      key={key}
      onClick={onClick}
      className="w-full py-4 flex flex-col gap-6 bg-white relative hover:shadow-lg transition-all rounded-md border border-gray-300 cursor-pointer">
      <div className="px-6 flex items-center justify-between">
        <span className={"text-sm " + categoryClass}> {category} </span>
        <span className=" text-sm flex items-center gap-2">{"$" + price}</span>
      </div>
      <div className="flex flex-col px-6 ">
        <span className="text-md font-[600]">{name}</span>
        <span className="text-sm font-light mt-1">
          {description?.slice(0, 134)}
          {description?.length >= 134 && "..."}{" "}
        </span>
      </div>

      {isSelected ? (
        <div className="px-6">
          <ProgressBar total={tasks || 0} finished={finishedTasks || 0} />
        </div>
      ) : (
        <div className="flex flex-1 items-end justify-end px-6">
          <SecondaryButton outline name="View" />
        </div>
      )}
    </div>
  );
};

export default StrategyCard;
