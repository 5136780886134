export const assigneeOptions = [
  { id: 0, value: "Me" },
  { id: 1, value: "Mikrolo" },
];

export const statusOptions = [
  {
    id: "notstarted",
    value: "Not Started",
  },
  {
    id: "inprogress",
    value: "In Progress",
  },
  {
    id: "completed",
    value: "Completed",
  },
  {
    id: "cancelled",
    value: "Cancelled",
  },
];
