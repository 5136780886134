import DesignCardSlider from "@/components/Sliders/DesignCardSlider";
import SecondaryButton from "@/components/buttons/SecondaryButton";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  data: any;
  handleViewImage: (imageUrl: string) => void;
};

const DesignCard = (props: Props) => {
  const { data, handleViewImage } = props;
  const { t } = useTranslation();
  const images = data.response.data;
  const imageType = data.request.image_type
    .toLowerCase()
    .split("_")
    .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return (
    data && (
      <div className="relative w-auto rounded-lg overflow-hidden border border-gray-300 shadow-xl bg-white">
        <div className="border-b border-gray-300 p-3 font-semibold text-sm">{imageType}</div>
        <DesignCardSlider handleView={handleViewImage} images={images} />
        <div className="flex flex-col p-3 border-t border-gray-300">
          <span className="font-semibold text-sm">Prompt</span>
          <div className="h-[80px] mt-2 overflow-x-auto rounded-md text-sm p-2 border border-gray-300 ">
            {data.request.prompt}
          </div>
        </div>
      </div>
    )
  );
};

export default DesignCard;
