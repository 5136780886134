import AssessmentLayout from "@/components/Layout/Assessment";
import ContentBar from "@/components/ContentBar";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Breadcrumb from "@/components/breadcrumb/breadcrumb";
import BiddingStrategiesMetricsTable from "@/features/report/components/ad-reports/tables/bidding-strategies/BiddingStrategiesMetricsTable";

const BiddingStrategiesPage = () => {
  const { t } = useTranslation();
  const { projectId, clientId, reportId, campaignId } = useParams();

  return (
    <div>
      <AssessmentLayout>
        <ContentBar title={t("biddingStrategies")} />
        <Breadcrumb reportId={reportId} clientId={clientId} projectId={projectId} />
        <div>
          <BiddingStrategiesMetricsTable tableId="biddingStrategiesMetrics" />
        </div>
      </AssessmentLayout>
    </div>
  );
};

export default BiddingStrategiesPage;
