import { SparklesIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useState } from "react";
import { t } from "i18next";

export default function UpgradeSection() {
  const [show] = useState(
    sessionStorage.getItem("upgradeSection") === "true" ? false : true
  );

  // const handleClose = () => {
  //   sessionStorage.setItem("upgradeSection", "true");
  //   setShow(false);
  // };

  return (
    <div
      className={`
        ${show ? "block" : "hidden"} mt-5 
      `}>
      <div>
        <div className="rounded-lg bg-indigo-600 p-2 sm:p-3 shadow-sm">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex w-0 flex-1 items-center">
              <span className="flex rounded-lg bg-indigo-800 p-2">
                <SparklesIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </span>
              <p className="ml-3 mr-8 truncate font-medium text-white">
                <span className="hidden md:inline text">
                  {t("To create and assign more tasks to our experts, let’s upgrade your account!")}
                </span>
              </p>
            </div>
            <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
              <Link
                to="/profile/plans"
                className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-indigo-600 shadow-sm hover:bg-indigo-50">
                {t("Upgrade Your Account")}
              </Link>
            </div>
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
              {/*  <button
                onClick={() => handleClose()}
                type="button"
                className="-mr-1 flex rounded-md p-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white">
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
