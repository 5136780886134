import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useMutation, useQuery } from "react-query";
import { finishStory, reproduceAnswer } from "@/api/stories";
import { XMarkIcon } from "@heroicons/react/20/solid";

import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { t } from "i18next";
import WhiteButton from "@/components/buttons/WhiteButton";
import PricingTableForAssign from "@/components/PricingTableForAssign";
import { taskCompleteEvent, taskViewModalEvent } from "@/analytics/mixpanel";
import { userAtom } from "@/store/atoms/user.atoms";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import MessageTabContent from "./MessageTabContent";
import AssignTalentModal from "@/features/tasks/components/AssignTalentModal";
import PreviewTabContent from "@/features/tasks/components/TaskContent/PreviewTabContent";
import { toast } from "react-toastify";
import { taskNavbar } from "@/store/atoms/taskNavbar.atoms";
import FinishTaskModal from "../FinishTaskModal";
import OurSuggestionContent from "./OurSuggestionContent";
import { unreadMessageCount } from "@/store/atoms/unreadMessageCount";
import ReproduceAnswerModal from "../ReproduceAnswerModal";
import { fetchProjectMainTasks } from "@/api/task";

const sourceUrl = process.env.REACT_APP_CONTENT_URL;

// const style = {
//   header: {
//     textAlign: "left",
//     margin: "10px 20px",
//   },
//   paragraph: {
//     fontSize: "16px",
//   },
// };

const CustomParagraphRenderer = ({ data }: any) => {
  const alt = data.file.alt ? data.file.alt : "";
  const src = data.file.url ? sourceUrl + data.file.url : "";
  return (
    <div className="my-2">
      <img src={src} alt={alt} />
    </div>
  );
};

const CustomListRenderer = ({ data }: any) => {
  return (
    <div className="m-5 text-base">
      {Array.isArray(data) ? (
        <ul>
          {data.map((item: any, index: number) => (
            <li className="list-disc" key={index}>
              {item}
            </li>
          ))}
        </ul>
      ) : (
        <p>{t("somethingWentWrong")}</p>
      )}
    </div>
  );
};

const CustomDefaultRenderer = ({ data }: any) => {
  const paragraphStyle = {
    fontSize: "16px",
  };
  return (
    <div className="mt-5 text-base paragraph-class">
      <p style={paragraphStyle}>{data.text}</p>
    </div>
  );
};

const CustomNumberListRenderer = ({ data }: any) => {
  return (
    <div className="m-5 text-base">
      {Array.isArray(data) ? (
        <ol>
          {data.map((item: string, index: number) => (
            <li className="list-decimal" key={index}>
              {item}
            </li>
          ))}
        </ol>
      ) : (
        <p>{t("somethingWentWrong")}</p>
      )}
    </div>
  );
};

const RenderValue = ({ value }: any) => {
  if (Array.isArray(value)) {
    // Render array
    return (
      <ul>
        {value.map((item, index) => (
          <li key={index}>
            <RenderValue value={item} />
          </li>
        ))}
      </ul>
    );
  } else if (typeof value === "object" && value !== null) {
    // Render object properties recursively
    return <CustomDictRenderer data={value} />;
  } else {
    // Render primitive value
    return <span>{value.toString()}</span>;
  }
};

const CustomDictRenderer = ({ data }: any) => {
  if (typeof data !== "object" || data === null) {
    console.error("CustomDictRenderer requires an object as data.");
    return null;
  }

  const entries = Object.entries(data);

  return (
    <div className="custom-dict-renderer">
      {entries.map(([key, value], index) => (
        <div key={index} className="dict-entry">
          <strong>{key}:</strong> <RenderValue value={value} />
        </div>
      ))}
    </div>
  );
};

const CustomHeaderRenderer = ({ data }: any) => {
  if (!data || !data.text || !data.level) {
    console.error("Invalid data provided to CustomHeaderRenderer");
    return null;
  }

  const { text, level } = data;

  // Ensure the level is between 1 and 6
  const headerLevel = Math.min(Math.max(level, 1), 6);
  const HeaderTag = `h${headerLevel}`;

  const headerStyle = {
    textAlign: "left",
    margin: "10px 20px",
  };

  // Use React.createElement to dynamically create the header element
  return React.createElement(
    HeaderTag,
    { style: headerStyle, className: `header-level-${headerLevel}` },
    text
  );
};

// const classes = {
//   header: "header-class1 header-class2",
//   paragraph: "paragraph-class",
// };
interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedStory: any;
  setSelectedStory: (selectedStory: any) => void;
  refetch: any;
  hasAssignTask: boolean;
  projectId: any;
  projectStoryId: any;
  partnerName: string | null;
  tasks: any;
  setUnreadMessageItems: (unreadMessageItems: any) => void;
  unreadMessageItems: any;
}

export default function TaskContent(props: Props) {
  const [activeTab, setActiveTab] = useState<"details" | "suggestion" | "message" | "preview">(
    "details"
  );
  const [assignModal, setAssignModal] = useState(false);
  const [isAssigning, setIsAssigning] = useState(false);
  const [error, setError] = useState("");
  const [navbarFetch, setNavbarFetch] = useRecoilState<any>(taskNavbar);
  const [isOpenFinishTask, setIsOpenFinishTask] = useState(false);
  const [isOpenReproduceAnswer, setIsOpenReproduceAnswer] = useState(false);

  const { i18n } = useTranslation();

  const [finishState, setFinishState] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const [user] = useRecoilState(userAtom);
  const [unreadMessageItemsForSidebar, setUnreadMessageItemsForSidebar] =
    useRecoilState(unreadMessageCount);

  const {
    projectId,
    projectStoryId,
    open,
    setOpen,
    selectedStory,
    setSelectedStory,
    hasAssignTask,
    // projectId,
    // tasks,
    setUnreadMessageItems,
    unreadMessageItems,
  } = props;

  const storyData = selectedStory;

  useEffect(() => {
    if (activeTab === "message") {
      setUnreadMessageItems((unreadMessageItems: any) => {
        const index = unreadMessageItems.findIndex(
          (item: any) => item.project_story_id === storyData.project_story_id
        );
        if (index !== -1) {
          unreadMessageItems.splice(index, 1);
        }

        const newArr = unreadMessageItemsForSidebar.items.filter(
          (item: any) => item.project_story_id !== storyData.project_story_id
        );

        setUnreadMessageItemsForSidebar({
          items: [...newArr],
        });

        return [...unreadMessageItems];
      });
    }
  }, [activeTab]);

  const handleAssignTask = async () => {
    if (hasAssignTask) {
      setAssignModal(true);
    } else {
      setIsOpen(true);
    }
  };

  const { isLoading: isLoadingFinish, mutateAsync } = useMutation(finishStory, {
    retry: false,
    onSuccess: () => {},
  });

  const { isLoading: isLoadingReproduceAnswer, mutateAsync: mutateAsyncReproduceAnswer } =
    useMutation(reproduceAnswer, {
      retry: false,
      onSuccess: () => {},
    });

  const handleFinishTask = async () => {
    mutateAsync(storyData.project_story_id)
      .then(() => {
        const getPaths = JSON.parse(localStorage.getItem("paths") as any);
        taskCompleteEvent({
          taskId: storyData.id,
          taskName: storyData.name,
          previousPage: getPaths?.prevPath || "",
          assignTask: Boolean(storyData.partner_task_id),
          email: user?.email || "",
          userId: user?.id.toString() || "",
          taskCompleteDone: true,
        });
      })
      .then(() => {
        props.refetch();
        toast.success(t("Task completed successfully, you can continue with the next task"));
        setNavbarFetch(!navbarFetch);
      })
      .catch((err) => {
        console.log(error);
        setError(
          err.response.data.detail || t("There was an error finishing the task. Try again later.")
        );
      });
  };

  const handleReproduceAnswer = async (feedback: any) => {
    mutateAsyncReproduceAnswer({
      projectStoryId: projectStoryId,
      feedback: feedback,
    })
      .then(() => {
        props.refetch();
        toast.success(t("theAnswerHasBeenSuccessfullyReproduced"));
        setNavbarFetch(!navbarFetch);
      })
      .catch((err) => {
        setError(
          err.response?.data?.detail || t("thereWasAnErrorReproducingTheAnswerTryAgainLater")
        );
        toast.error(t("thereWasAnErrorReproducingTheAnswerTryAgainLater"));
      });
  };

  useEffect(() => {
    setFinishState(true);
  }, [storyData]);

  const handlePartnerTaskStatus = (partner_task_id: number, status = "finished") => {
    setSelectedStory((s: any) => {
      if (!s?.tasks) return s;
      const temp = s;
      const taskIndex = temp?.tasks?.findIndex((e: any) => e.partner_task_id === partner_task_id);
      if (taskIndex === -1) return s;
      temp.tasks[taskIndex].partner_task_status = status;
      let partner_task_count = 0,
        finished_task_count = 0;
      for (const t of temp.tasks) {
        if (!t.partner_task_id) continue;
        partner_task_count++;
        if (t.partner_task_status === "finished") finished_task_count++;
      }
      if (partner_task_count !== 0 && partner_task_count === finished_task_count)
        temp.project_story_status = "finished";
      return temp;
    });
  };

  function renderStoryContent(story: any) {
    let blocks = [];

    try {
      const content =
        typeof story.story_content === "string"
          ? JSON.parse(story.story_content)
          : story.story_content;

      blocks = content.blocks || content["blocks"] || [];
    } catch (error) {
      console.error("Error parsing story content:", error);
    }

    return blocks.map((item: any, index: number) => {
      switch (item.type) {
        case "header":
          return <CustomHeaderRenderer key={index} data={item.data} />;
        case "paragraph":
          return <CustomDefaultRenderer key={index} data={item.data} />;
        case "image":
          // Using CustomParagraphRenderer for images as specified
          return <CustomParagraphRenderer key={index} data={item.data} />;
        case "list":
        case "bulletlist":
        case "bulletList":
        case "bulletedList":
        case "bulletedlist":
          if (Array.isArray(item.data.items)) {
            return <CustomListRenderer key={index} data={item.data.items} />;
          } else if (Array.isArray(item.data)) {
            return <CustomListRenderer key={index} data={item.data} />;
          }
          break; // If not an array, break without returning anything
        case "numberList":
        case "numberlist":
        case "numberedlist":
        case "numberedList":
          // For numbered lists, ensuring item.data is an array before rendering
          if (Array.isArray(item.data)) {
            return <CustomNumberListRenderer key={index} data={item.data} />;
          } else if (Array.isArray(item.data.items)) {
            return <CustomNumberListRenderer key={index} data={item.data.items} />;
          } else if (typeof item.data === "object") {
            return <CustomDefaultRenderer key={index} data={item.data} />;
          }
          break; // If not an array or object, break without returning anything
        default:
          return <CustomDictRenderer key={index} data={item.data} />;
      }
    });
  }

  return (
    <div>
      <div>
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="block relative z-[99]" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex h-full justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                  <Dialog.Panel className="relative transform  rounded-lg bg-white px-4 pt-5 pb-4  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[820px] sm:p-6 overflow-hidden">
                    <div className="absolute top-0 right-0  pt-4 pr-4 ">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                        onClick={() => setOpen(false)}>
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="flex sm:items-start mt-5 h-full w-full flex-col ">
                      <div className="w-full">
                        <div className="mt-1 h-full ">
                          <div className=" font-medium text-xl first-letter:uppercase block">
                            {(selectedStory.sub_title &&
                              selectedStory.sub_title.replace("&", "and")) ||
                              (selectedStory.story_name &&
                                selectedStory.story_name.replace("&", "and"))}
                          </div>

                          <div className="flex mt-6 rounded">
                            <div
                              onClick={() => {
                                setActiveTab("details");
                                taskViewModalEvent({
                                  userId: user?.id.toString() || "",
                                  email: user?.email || "",
                                  taskId: selectedStory.id,
                                  taskName: selectedStory.name,
                                  tabName: "details",
                                });
                              }}
                              className={`cursor-pointer shadow p-3 flex-1 text-center 
                              ${
                                activeTab === "details" && `bg-blue-50 border-b border-b-blue-500`
                              }`}>
                              {t("detail")}
                            </div>

                            {selectedStory.label_name === "social_media" && (
                              <div
                                onClick={() => {
                                  setActiveTab("suggestion");
                                  taskViewModalEvent({
                                    userId: user?.id.toString() || "",
                                    email: user?.email || "",
                                    taskId: selectedStory.id,
                                    taskName: selectedStory.name,
                                    tabName: "suggestion",
                                  });
                                }}
                                className={`cursor-pointer shadow p-3 flex-1 text-center 
                              ${
                                activeTab === "suggestion" &&
                                `bg-blue-50 border-b border-b-blue-500`
                              }`}>
                                {t("ourSuggestions")}
                              </div>
                            )}

                            {(selectedStory.is_assigned || isAssigning) && (
                              <>
                                <div
                                  onClick={() => {
                                    setActiveTab("message");
                                    taskViewModalEvent({
                                      userId: user?.id.toString() || "",
                                      email: user?.email || "",
                                      taskId: selectedStory.id,
                                      taskName: selectedStory.name,
                                      tabName: "message",
                                    });
                                  }}
                                  className={`cursor-pointer shadow p-3 flex-1 text-center relative
                              ${
                                activeTab === "message" && `bg-blue-50 border-b border-b-blue-500`
                              }`}>
                                  {t("messages")}

                                  <>
                                    {unreadMessageItems
                                      ?.find(
                                        (i: any) =>
                                          i?.project_story_id === selectedStory?.project_story_id
                                      )
                                      ?.tasks?.reduce(
                                        (a: any, b: any) => a + b?.unread_message_count,
                                        0
                                      ) > 0 && (
                                      <div className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs font-medium">
                                        {unreadMessageItems
                                          ?.find(
                                            (i: any) =>
                                              i?.project_story_id ===
                                              selectedStory?.project_story_id
                                          )
                                          ?.tasks?.reduce(
                                            (a: any, b: any) => a + b?.unread_message_count,
                                            0
                                          )}
                                      </div>
                                    )}
                                  </>
                                </div>
                              </>
                            )}

                            {/* {selectedStory.project_story_status === "finished" && ( */}
                            <div
                              onClick={() => {
                                setActiveTab("preview");
                                taskViewModalEvent({
                                  userId: user?.id.toString() || "",
                                  email: user?.email || "",
                                  taskId: selectedStory.id,
                                  taskName: selectedStory.name,
                                  tabName: "preview",
                                });
                              }}
                              className={`cursor-pointer shadow p-3 flex-1 text-center
                              ${
                                activeTab === "preview" && `bg-blue-50 border-b border-b-blue-500`
                              } `}>
                              {t("preview")}
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                      </div>
                      {activeTab === "details" && (
                        <div className="w-full h-[calc(100%-100px)]  overflow-auto">
                          <div className="text-sm text-gray-400 pt-6 first-letter:uppercase">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4">
                              <div className="flex flex-col gap-1 capitalize">
                                <div className="min-w-[60px] font-medium">{t("status")}</div>
                                <div className=" flex items-center text-black">
                                  {selectedStory.project_story_status}
                                </div>
                              </div>
                              <div className="flex flex-col gap-1 capitalize">
                                <div className="min-w-[60px] font-medium">{t("label")}</div>
                                <div className={`rounded-full flex gap-2 text-black`}>
                                  {selectedStory.label_title
                                    ? selectedStory.label_title
                                    : t("noLabel")}
                                </div>
                              </div>

                              <div className="flex flex-col gap-1 capitalize">
                                <div className="min-w-[60px] font-medium">{t("dueDate")}</div>

                                <div className="text-black">
                                  {selectedStory.due_date === null ? (
                                    <div className="text-gray-500">{t("noDueDate")}</div>
                                  ) : (
                                    <div>
                                      {new Date(selectedStory.due_date).toLocaleString(
                                        i18n.language,
                                        {
                                          year: "numeric",
                                          month: "long",
                                          day: "numeric",
                                          hour: "numeric",
                                          minute: "numeric",
                                        }
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="flex flex-col gap-1  capitalize">
                                <div className="min-w-[60px] font-medium">{t("assignee")}</div>
                                <div className="flex items-center gap-2 text-black">
                                  {selectedStory.tasks?.find((t: any) => t.partner_first_name) ? (
                                    <>
                                      {selectedStory?.tasks?.map(
                                        (t: any, index: any) =>
                                          t.partner_first_name && (
                                            <>
                                              <span className="">
                                                {t.partner_first_name}
                                                {index !== selectedStory?.tasks?.length - 1 && ","}
                                              </span>
                                            </>
                                          )
                                      )}
                                    </>
                                  ) : (
                                    <div>{t("noAssigned")}</div>
                                  )}
                                </div>
                              </div>

                              {selectedStory.label_name === "social_media" && (
                                <div className="flex flex-col gap-1 capitalize">
                                  <div className="min-w-[60px] font-medium">
                                    {t("Our Suggestions")}
                                  </div>

                                  <div className="text-black">
                                    <button
                                      onClick={() => {
                                        setActiveTab("suggestion");
                                      }}
                                      className="text-blue-500">
                                      {t("viewSuggestions")}
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div>
                            {selectedStory && (
                              <div className="mt-2">
                                {selectedStory.sub_content && (
                                  <>
                                    <div className="mt-5 text-gray-400 text-sm font-medium">
                                      {t("brief")}
                                    </div>

                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: selectedStory.sub_content,
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                            )}

                            {/* {selectedStory &&
                                  selectedStory.story_identifier === "social_media_strategy" && (
                                    <SocialMediaTask
                                      projectId={projectId}
                                      setFinishState={setFinishState}
                                    />
                                  )} */}
                          </div>
                          <div>
                            {selectedStory && (
                              <>
                                <div className="mt-2">
                                  {
                                    selectedStory.story_content && (
                                      <>
                                        <div className="mt-5 text-gray-400 text-sm font-medium">
                                          {t("about")}
                                        </div>
                                        {/* <Output
                                          renderers={renderers}
                                          style={style.header}
                                          classNames={classes.header}
                                          data={
                                            typeof selectedStory.story_content === 'string' ? JSON.parse(selectedStory.story_content) : selectedStory.story_content
                                          }
                                        /> */}
                                        <>{renderStoryContent(storyData)}</>
                                      </>
                                    ) /* : (
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: selectedStory.story_description,
                                            }}
                                          />
                                        ) */
                                  }
                                  {/*     <div className="flex justify-end mt-2">
                                        {selectedStory.project_story_status === "finished" && (
                                          <>
                                            <div className="mb-2">
                                              {selectedStory.partner_first_name &&
                                                selectedStory.project_story_status &&
                                                selectedStory.label_name === "facebook" && (
                                                  <>
                                                    {openShareInFacebookModal && (
                                                      <ShareInFacebookModal
                                                        selectedStory={selectedStory}
                                                        open={openShareInFacebookModal}
                                                        setOpen={setOpenShareInFacebookModal}
                                                      />
                                                    )}

                                                    <DefaultButton
                                                      onClick={() =>
                                                        setOpenShareInFacebookModal(true)
                                                      }
                                                      className="flex gap-1 items-center w-fit">
                                                      <ShareIcon
                                                        className="h-4 w-4 text-white mr-2"
                                                        aria-hidden="true"
                                                      />
                                                      <span>Share in Facebook</span>
                                                    </DefaultButton>
                                                  </>
                                                )}
                                            </div>

                                             <div className="">
                                              {selectedStory.partner_first_name &&
                                                selectedStory.project_story_status &&
                                                selectedStory.label_name === "instagram" && (
                                                  <>
                                                    {openShareInInstagramModal && (
                                                      <ShareInInstagramModal
                                                        selectedStory={selectedStory}
                                                        open={openShareInInstagramModal}
                                                        setOpen={setOpenShareInInstagramModal}
                                                      />
                                                    )}

                                                    <DefaultButton
                                                      onClick={() =>
                                                        setOpenShareInInstagramModal(true)
                                                      }
                                                      className="flex gap-1 items-center w-fit">
                                                      <ShareIcon
                                                        className="h-4 w-4 text-white mr-2"
                                                        aria-hidden="true"
                                                      />
                                                      <span>Share in Instagram</span>
                                                    </DefaultButton>
                                                  </>
                                                )}
                                            </div> 
                                          </>
                                        )}
                                      </div> */}
                                </div>
                              </>
                            )}

                            {/* {selectedStory &&
                                  selectedStory.story_identifier === "social_media_strategy" && (
                                    <SocialMediaTask
                                      projectId={projectId}
                                      setFinishState={setFinishState}
                                    />
                                  )} */}
                          </div>
                        </div>
                      )}
                      {activeTab === "message" && (
                        <>
                          <MessageTabContent
                            refetch={props.refetch}
                            selectedStory={storyData}
                            handlePartnerTaskStatus={handlePartnerTaskStatus}
                          />
                        </>
                      )}
                      {activeTab === "suggestion" && (
                        <>
                          <OurSuggestionContent
                            handleAssignTask={handleAssignTask}
                            isAssigning={isAssigning}
                            selectedStory={storyData}
                          />
                        </>
                      )}
                      {activeTab === "preview" && (
                        <>
                          <PreviewTabContent selectedStory={storyData} />
                        </>
                      )}
                      {activeTab === "details" && (
                        <>
                          <div className="flex justify-end gap-5 w-full my-3">
                            <div>
                              {/*  {selectedStory.project_story_status === "inprogress" &&
                                !Boolean(selectedStory.partner_task_id) &&
                                !selectedStory.is_assigned &&
                                !isAssigning && (
                                  <WhiteButton
                                    fontWeight="font-bold"
                                    isDisabled={!finishState}
                                    className={`w-fit`}
                                    onClick={() => setIsOpenFinishTask(true)}
                                    isLoading={isLoadingFinish}>
                                    {t("finishTask")}
                                  </WhiteButton>
                                )} */}
                              {
                                <WhiteButton
                                  fontWeight="font-bold"
                                  isDisabled={false}
                                  className={`w-fit`}
                                  onClick={() => setIsOpenReproduceAnswer(true)}
                                  isLoading={isLoadingReproduceAnswer}>
                                  {t("reproduceAnswer")}
                                </WhiteButton>
                              }
                            </div>
                            <div>
                              {storyData.project_story_status === "inprogress" &&
                                !selectedStory.is_assigned && (
                                  <WhiteButton
                                    fontWeight="font-bold"
                                    isDisabled={!finishState}
                                    className={`w-fit`}
                                    onClick={() => setIsOpenFinishTask(true)}
                                    isLoading={isLoadingFinish}>
                                    {t("finishTask")}
                                  </WhiteButton>
                                )}
                            </div>
                            <div>
                              {storyData.partner_role !== null &&
                                storyData.project_story_status === "inprogress" &&
                                !storyData.is_assigned &&
                                !isAssigning && (
                                  <>
                                    {Boolean(storyData.partner_task_id) ? (
                                      <> </>
                                    ) : (
                                      <DefaultButton
                                        onClick={() => handleAssignTask()}
                                        className="w-fit">
                                        {t("assignToMikrolod")}
                                      </DefaultButton>
                                    )}
                                  </>
                                )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>

      {assignModal && (
        <AssignTalentModal
          selectedStory={storyData}
          setSelectedStory={setSelectedStory}
          open={assignModal}
          setOpen={setAssignModal}
          refetch={props.refetch}
          setIsAssigning={setIsAssigning}
        />
      )}

      <PricingTableForAssign
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={t("The features covered by your plan are over!")}
        description={t(
          "If you want to contune using the features’ how about choosing a plan that suits you better?"
        )}
      />
      {isOpenFinishTask && (
        <FinishTaskModal
          isOpenFinishTask={isOpenFinishTask}
          setIsOpenFinishTask={setIsOpenFinishTask}
          handleFinishTask={handleFinishTask}
        />
      )}
      {isOpenReproduceAnswer && (
        <ReproduceAnswerModal
          isOpenReproduceAnswer={isOpenReproduceAnswer}
          setIsOpenReproduceAnswer={setIsOpenReproduceAnswer}
          handleReproduceAnswer={handleReproduceAnswer}
        />
      )}
    </div>
  );
}
