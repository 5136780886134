import React, { useContext, useEffect, useState } from "react";
import AssessmentLayout from "@/components/Layout/Assessment";
import ContentBar from "@/components/ContentBar";
import { t } from "i18next";
import { fetchProjectRoadmap } from "@/api/project";
import { useQuery } from "react-query";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { useRecoilState } from "recoil";
import { userAtom } from "@/store/atoms/user.atoms";
import { pageViewEvent } from "@/analytics/mixpanel";
import ConfirmPopup from "@/features/roadmap/components/Popup";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import PageLoading from "@/components/Loading/PageLoading";
import { GlobalContext } from "@/context/GlobalStateProvider";
import StrategyCard from "./StrategyCard";
import StrategyDetail from "./StrategyDetail";
import SelectStrategyFilter from "./SelectStrategyFilter";
import { useNavigate, useParams } from "react-router-dom";

const Strategies = () => {
  const { strategyId } = useParams();
  const navigate = useNavigate();
  const [project] = useRecoilState(projectInfo);
  const projectId = project.id;
  const [user] = useRecoilState(userAtom);
  const { globalState, setGlobalState } = useContext(GlobalContext);
  const searchQuery = globalState.searchQuery || "";
  const [selectedId, setSelectedId] = React.useState<null | number>(null);
  const [openDetail, setOpenDetail] = React.useState(false);
  const [activeStrategies, setActiveStrategies] = useState([]);
  const [strategies, setStrategies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = React.useState<string | number>("");

  const [openTaskModal, setOpenModal] = React.useState(false);

  useEffect(() => {
    const getPaths = JSON.parse(localStorage.getItem("paths") as any);

    pageViewEvent({
      pageName: "tasks",
      userId: user?.id.toString() || "",
      email: user?.email || "",
      previousPage: getPaths?.currentPath || "",
    });
  }, []);

  const {
    data: roadmap,
    isLoading,
    isFetched,
    isSuccess,
    refetch: reFetchRoadmap,
  } = useQuery("fetchRoadmap", () => fetchProjectRoadmap(projectId), {
    retry: false,
    enabled: !!projectId,
  });

  useEffect(() => {
    if (isFetched) {
      const activeList = roadmap.items.filter((item: any) => item.is_selected === true);
      const inactiveList = roadmap.items.filter((item: any) => item.is_selected === false);
      setActiveStrategies(activeList);
      setStrategies(inactiveList);
      setLoading(false);
    }
  }, [isSuccess, roadmap, isLoading]);

  const handleClickStrategy = (id: number | undefined) => {
    if (id !== undefined) {
      navigate(`/strategies/${id.toString()}`);
    }
  };

  useEffect(() => {
    if (strategyId) {
      setOpenDetail(true);
    }
  }, [strategyId]);

  return (
    <div>
      <AssessmentLayout>
        <ContentBar
          title={activeStrategies.length !== 0 ? t("activeStrategies") : t("suggestedStrategies")}
        />
        {isLoading ? (
          <PageLoading />
        ) : (
          <>
            {loading ? (
              <LoadingSpinner size="w-8 h-8" />
            ) : (
              <div className="flex flex-col gap-6 w-full py-6">
                <div className="grid grid-cols-1 lg:grid-cols-3  gap-6 ">
                  {activeStrategies.length !== 0 ? (
                    activeStrategies.map((item: any, index: number) => (
                      <StrategyCard
                        onClick={() => handleClickStrategy(item.id)}
                        key={index}
                        name={item.name}
                        description={item.description}
                        category={
                          roadmap.categories.find((element: any) => element.id === item.category_id)
                            ?.value
                        }
                        price={item.total_price}
                        isSelected={item.is_selected}
                        isNew={item.new}
                        tasks={item.total_count}
                        finishedTasks={item.finished_count}
                      />
                    ))
                  ) : (
                    <>
                      <div className="w-full py-10 px-6 flex flex-col justify-center gap-2 bg-primaryColor relative hover:shadow-lg transition-all rounded-md border border-gray-300 cursor-pointer ">
                        <h2 className="text-yellow text-lg font-[600] ">Start Your Journey</h2>
                        <span className="text-white text-sm font-light">
                          Start by selecting your first strategy and assigning your first task to
                          the Mikrolo team.
                        </span>
                      </div>
                    </>
                  )}
                </div>
                <SelectStrategyFilter
                  categories={roadmap.categories}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                />
                <div className="grid grid-cols-1 lg:grid-cols-3  gap-6 ">
                  {strategies
                    ?.filter(
                      (r: any) =>
                        r.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        r.description.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                    .filter((r: any) => +r.category_id === +selectedCategory || !selectedCategory)
                    .map((item: any, index: any) => (
                      <StrategyCard
                        key={index}
                        onClick={() => handleClickStrategy(item.id)}
                        name={item.name}
                        description={item.description}
                        category={
                          roadmap.categories.find((element: any) => element.id === item.category_id)
                            ?.value
                        }
                        price={item.total_price}
                        isSelected={item.is_selected}
                        isNew={item.new}
                      />
                    ))}
                  <StrategyDetail
                    open={openDetail}
                    onClose={() => {
                      setOpenDetail(false);
                      navigate("/strategies");
                    }}
                    size="w-full sm:w-8/12 md:w-6/12 lg:w-4/12 xl:w-3/12"
                    projectId={projectId}
                    strategyId={strategyId}
                    reFetch={reFetchRoadmap}
                  />

                  <ConfirmPopup open={openTaskModal} setOpen={setOpenModal} />
                </div>
              </div>
            )}
          </>
        )}
      </AssessmentLayout>
    </div>
  );
};

export default Strategies;
