import {
  automatedDesignerCreate,
  fetchAutomatedDesignerImageTypes,
} from "@/api/automated-designer/automated-designer";
import { Divider } from "@/components/Divider";
import ModalLoading from "@/components/Loading/ModalLoading";
import ProgressBar from "@/components/Loading/ProgressBar";
import RightModal from "@/components/Modal/RightModal";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import SecondaryButton from "@/components/buttons/SecondaryButton";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaRegSadTear } from "react-icons/fa";
import { IoMdDownload } from "react-icons/io";
import { MdOutlineSearch } from "react-icons/md";
import { useQuery } from "react-query";

type Props = {
  size: string;
  open: boolean;
  onClose: () => void;
  projectId?: number;
  projectPlan: any;
  refetch: () => void;
  handleGetCredits?: () => void;
  handlePreview?: (url: any) => void;
};

const CreateDesignModal = (props: Props) => {
  const { t } = useTranslation();
  const { size, open, onClose, projectId, projectPlan, handleGetCredits, refetch, handlePreview } =
    props;
  const [error, setError] = useState("");
  const [imageType, setImageType] = useState<any>();
  const [imageTypeList, setImageTypeList] = useState<any>([]);
  const [request, setRequest] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompts] = useState("");

  const {
    data: typeData,
    isLoading: loadingTypeData,
    isFetched,
    refetch: refetchTypeData,
  } = useQuery("fetchAutomatedDesignImageTypes", () => fetchAutomatedDesignerImageTypes(), {
    retry: false,
    cacheTime: 0,
  });

  useEffect(() => {
    if (typeData) {
      const formattedData = Object.keys(typeData).map((key) => ({
        value: key,
        label: typeData[key],
      }));
      setImageTypeList(formattedData);
      setImageType(formattedData[0].value);
    }
  }, [typeData]);

  const handleSubmit = async () => {
    if (prompt.length === 0) {
      setError("Prompt is required");
    } else {
      setError("");
      setLoading(true);
      try {
        const response = await automatedDesignerCreate({
          project_id: Number(projectId),
          prompt: prompt,
          image_type: imageType,
        });
        setRequest(response);
        setLoading(false);
        refetch();
      } catch (err) {
        console.error("Error creating design:", err);
        setError("Failed to create design.");
        setLoading(false);
      }
    }
  };

  const handlePreviewImage = (imageUrl: string) => {
    if (handlePreview) {
      handlePreview(imageUrl);
    }
  };

  const handleClose = () => {
    setPrompts("");
    setRequest([]);
    onClose();
  };

  return (
    <RightModal
      size={size}
      open={open ? open : false}
      onClose={() => handleClose()}
      action={
        <PrimaryButton
          loading={loading}
          className="w-full"
          name="Generate"
          onClick={() => handleSubmit()}
        />
      }>
      {loadingTypeData ? (
        <ModalLoading />
      ) : (
        <div className="w-full h-full flex  flex-1 flex-col justify-start items-start text-left  px-6 pt-10 gap-y-6  ">
          <h1 className="text-xl font-bold">Create new design</h1>
          <div className="w-full flex  items-center justify-between border border-gray-400 px-3  w-full rounded-lg focus:outline-none">
            <label
              htmlFor="type"
              className="block text-sm font-medium text-gray-700 text-left py-2">
              Image Type
            </label>
            <select
              id="type"
              value={imageType}
              onChange={(e) => setImageType(e.target.value)}
              className="text-right h-full focus:outline-none flex flex-1 pr-4 cursor-pointer">
              {imageTypeList.map((option: any) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <Divider />
          <div className="w-full flex flex-col items-start justify-start">
            <textarea
              id="prompt"
              onChange={(e) => setPrompts(e.target.value)}
              className="block w-full  focus:outline-none focus:ring-indigo-500  sm:text-sm bg-white "
              value={prompt}
              rows={6}
              placeholder="Enter your prompt here. Be sure to include all the details you want to see in the final result."
            />
            {error.length !== 0 && <p className="text-red-500 text-xs mt-2">{error}</p>}
          </div>
          {loading ? (
            <div className="bg-white border border-primaryColor border-dashed  w-full h-[260px] p-3 flex flex-col justify-center items-center mb-4 rounded-lg">
              <div className="w-8/12">
                <ProgressBar />
              </div>
              <span className="font-semibold text-center text-sm mt-4">
                Design is being created. Please do not close or refresh the page.
              </span>
            </div>
          ) : (
            request.length !== 0 &&
            (request.response ? (
              <div className="border border-gray-200 w-full p-2">
                <div className=" w-full h-[260px] flex  justify-center items-center  rounded-lg">
                  <img
                    src={request.response?.data[0]?.image_url}
                    alt="generated"
                    className="max-w-full max-h-full object-fit"
                  />
                </div>
                <div className="flex justify-center mt-2 items-center gap-2 ">
                  <PrimaryButton
                    onClick={() => handlePreviewImage(request.response?.data[0]?.image_url)}
                    icon={<MdOutlineSearch />}
                    className=""
                  />
                  <PrimaryButton
                    href={request.response?.data[0]?.image_url}
                    icon={<IoMdDownload />}
                    className=""
                  />
                </div>
              </div>
            ) : (
              <div className="p-3">
                <div className="bg-white border border-primaryColor border-dashed  w-full h-[260px] p-3 flex flex-col justify-center items-center mb-4 rounded-lg">
                  <div className="flex flex-col items-center justify-center bg-white border shadow rounded-full w-14 h-14">
                    <FaRegSadTear className="text-4xl text-primaryColor" />
                  </div>
                  <span className="text-sm w-8/12 mt-2"> {request.message} </span>
                  {projectPlan.id === 1 ? (
                    <SecondaryButton
                      to="/profile/plans"
                      className="mt-2"
                      name={t("subscribeNow")}
                    />
                  ) : (
                    <SecondaryButton
                      onClick={handleGetCredits}
                      className="mt-2"
                      name={t("buyCredits")}
                    />
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </RightModal>
  );
};

export default CreateDesignModal;
