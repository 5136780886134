import { useEffect, useState } from "react";
import AssessmentLayout from "@/components/Layout/Assessment";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchProjectReports, fetchProjectMetrics } from "@/api/reports";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { subDays } from "date-fns";
import { reportsDetailEvent } from "@/analytics/mixpanel";
import { useRecoilState } from "recoil";
import { userAtom } from "@/store/atoms/user.atoms";
import InfoSection from "@/components/InfoSection";
import useGoogleAdsLogin from "@/hooks/use-google-login";
import AdMetricsChart from "@/components/Charts/AdMetricsChart";
import GeneralTable from "@/features/report/components/charts/GeneralTable";
import CLientSwitchMenu from "@/components/Dropdown/ClientSwitchMenu";
import ReportSectionCard from "@/components/cards/ReportSectionCard";
import InfoBoard from "@/components/cards/InfoCard";
import { t } from "i18next";
import Breadcrumb from "@/components/breadcrumb/breadcrumb";
import { adReportBreadCrumb } from "@/store/atoms/adReportBreadcrumb.atom";

interface Client {
  clientId: string;
  clientName: string;
  // Add other properties as needed
}

const AdReportPage = () => {
  const params: any = useParams();
  const reportId = params?.reportId;
  const projectId = params?.id;
  const clientId = params?.clientId;
  const [user] = useRecoilState(userAtom);
  const [clients, setClients] = useState<Client[]>([]);
  const [selectedClient, setSelectedCLient] = useState<any>();
  const [chartData, setChartData] = useState<any>([]);
  const [chartDataLoading, setChartDataLoading] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [breadcrumbState, setBreadcrumbState] = useRecoilState(adReportBreadCrumb);

  localStorage.removeItem("table-filter");
  
  useEffect(() => {
    setBreadcrumbState([]);
  }, []);

  useEffect(() => {
    setBreadcrumbState([]);
  }, []);

  const [range] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const { data, isLoading, isSuccess, isFetched, refetch } = useQuery(
    "fetchProjectReports",
    () =>
      fetchProjectReports({
        projectId: projectId,
        reportId: reportId,
      }),
    {
      retry: false,
      enabled: !!projectId && !!reportId,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (isFetched) {
      setClients(
        data?.metrics?.data.map((i: any) => ({
          clientId: i.customerClientId,
          clientName: i.customerClientName,
        }))
      );
      setSelectedCLient(clientId);
    }
  }, [data, clientId]);

  const {
    isSuccess: isSuccessGoogle,
    isFetched: isFetchedGoogleLogin,
    isLoadingGoogleAdsLogin,
  } = useGoogleAdsLogin();

  const {
    data: metrics,
    isLoading: isLoadingMetrics,
    isSuccess: isSuccessMetrics,
    isFetched: isFetchedMetrics,
    refetch: refetchMetrics,
  } = useQuery(
    ["fetchProjectMetrics", range],
    () =>
      fetchProjectMetrics({
        projectId: projectId,
        startDate: range[0].startDate as any,
        endDate: range[0].endDate as any,
      }),
    {
      retry: false,
      enabled: !!projectId && !!isFetchedGoogleLogin,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      const getPaths = JSON.parse(localStorage.getItem("paths") as any);
      reportsDetailEvent({
        reportName: data?.name,
        reportId: reportId?.toString() as any,
        userId: user?.id.toString() || "",
        email: user?.email || "",
        previousPage: getPaths?.prevPath || "",
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    setChartDataLoading(true);
    if (isFetchedMetrics) {
      const selectedMetric = metrics.find((item: any) => item.customerClientId == selectedClient);

      if (selectedMetric) {
        setChartData(selectedMetric);
        setChartDataLoading(false);
      } else {
        setChartDataLoading(false);
        setChartData(null);
      }
    }
  }, [isFetchedMetrics === true, selectedClient, clientId]);

  useEffect(() => {
    setTableDataLoading(true);
    if (isFetched) {
      const selectedData = data.metrics.data.find(
        (item: any) => item.customerClientId == selectedClient
      );
      if (selectedData) {
        setTableData(selectedData);
        setTableDataLoading(false);
      }
    }
  }, [isFetched === true, selectedClient, clientId]);

  return (
    <div className="pb-10">
      <AssessmentLayout>
        <CLientSwitchMenu clientId={selectedClient} options={clients} />
        <InfoSection
          name="ReportDetailInfoSection"
          bgClass="rounded-lg bg-yellow-100 p-2 sm:p-3 shadow-sm"
          icon="ExclamationCircleIcon"
          iconClass="h-6 w-6 text-white"
          text={t("reportInfoText")}
          fontClass="ml-3 mr-8"
          hoverClass="hover:bg-yellow-500"
          iconSpanClass="flex rounded-lg bg-yellow-500 p-2"
          boldSentenceIndex={[0]}
          closeButton={true}
        />
        <Breadcrumb reportId={reportId} clientId={clientId} projectId={projectId} />

        <ReportSectionCard title={t("adReports")} showHide={true}>
          {isLoading || isLoadingMetrics || chartDataLoading ? (
            <LoadingSpinner size="w-8 h-8" />
          ) : chartData ? (
            <div className="p-4 pt-0">
              <AdMetricsChart data={chartData} />
            </div>
          ) : (
            <InfoBoard title={t("noDataTitle")} description={t("noDataDescription")} />
          )}
        </ReportSectionCard>

        <ReportSectionCard
          title={tableData?.customerClientName + " Reports"}
          showHide={true}
          action={
            tableData.rulesFailed && (
              <Link
                className="p-2 bg-primaryColor text-white rounded-lg border border-primaryColor"
                to={`${window.location.pathname}/metrics`}>
                Campaign Metrics
              </Link>
            )
          }
          className="mt-4">
          {tableDataLoading ? (
            <LoadingSpinner size="w-8 h-8" />
          ) : tableData.rulesFailed ? (
            <GeneralTable data={tableData} />
          ) : (
            <InfoBoard title={t("noDataTitle")} description={t("noDataDescription")} />
          )}
        </ReportSectionCard>
      </AssessmentLayout>
    </div>
  );
};

export default AdReportPage;
