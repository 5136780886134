import React, { useState } from "react";

import { MdAttachFile, MdClose } from "react-icons/md";
import { getFileIcon, formatFileName } from "@/utils/formatters";

type Props = {
  onSelect?: (files: File[]) => void;
  showFileList?: boolean;
};

const FileUploader = (props: Props) => {
  const { onSelect, showFileList = true } = props;
  const [files, setFiles] = useState<File[]>([]);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      const fileArray = Array.from(selectedFiles);
      setFiles((prevFiles) => {
        const newFiles = [...prevFiles, ...fileArray];
        if (onSelect) {
          onSelect(newFiles);
        }
        return newFiles;
      });
    }
  };

  const handleFileRemove = (fileName: string) => {
    setFiles((prevFiles) => {
      const newFiles = prevFiles.filter((file) => file.name !== fileName);
      if (onSelect) {
        onSelect(newFiles);
      }
      return newFiles;
    });
  };

  return (
    <div className="w-full flex flex-col items-start gap-3">
      <input className="hidden" id="select_file" type="file" multiple onChange={handleFileSelect} />

      {showFileList && files.length > 0 && (
        <div className="w-full flex flex-col gap-2">
          {files.map((file) => (
            <div
              key={file.name}
              className="w-full flex flex-row items-center justify-between gap-1 p-2 rounded-lg border border-gray-300 bg-white shadow-md">
              <div className="flex items-center gap-1">
                {getFileIcon(file, file.name)}
                <span className="text-xs  text-primaryColor">{formatFileName(file.name, 24)}</span>
              </div>
              <button
                onClick={() => handleFileRemove(file.name)}
                className="bg-transparent border-0 text-red flex items-center">
                <MdClose />
              </button>
            </div>
          ))}
        </div>
      )}

      <div
        onClick={() => document.getElementById("select_file")?.click()}
        className="w-auto flex items-center justify-center gap-1  cursor-pointer ">
        <MdAttachFile className="text-xl" />
        <span className="text-sm text-primaryColor">Add File</span>
      </div>
    </div>
  );
};

export default FileUploader;
