import React, { useState } from "react";
import PhoneField from "@/components/FormElements/PhoneField";
import TextField from "@/components/FormElements/TextField";
import Select from "@/components/FormElements/Select";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import MultipleSelect from "@/components/FormElements/MultipleSelect";
import { useQuery } from "react-query";
import { fetchOnboardingForm } from "@/api/auth";
import PageLoading from "@/components/Loading/PageLoading";

type Props = {
  projectId: number;
  loading?: boolean;
  onSubmit: (onboardingData: any) => void;
};

const FirstStep = (props: Props) => {
  const { projectId, loading, onSubmit } = props;
  const [onboardingData, setOnboardingData] = useState<Record<string, any>>({});

  const { data: onboardingForm, isLoading } = useQuery<any>(
    "fetchCategories",
    () => fetchOnboardingForm(),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const onChange = (key: string, value: any) => {
    setOnboardingData((prev) => ({ ...prev, [key]: value }));
  };

  const isactive = () => {
    if (
      onboardingData?.company_website &&
      onboardingData?.contact_number &&
      onboardingData?.industry &&
      onboardingData?.contact_number &&
      onboardingData?.job_title &&
      onboardingData?.team_size &&
      onboardingData?.marketing_goal
    ) {
      return false;
    } else {
      return true;
    }
  };

  console.log(onboardingData);

  return isLoading ? (
    <PageLoading />
  ) : (
    <div className="w-full flex flex-col gap-4">
      <TextField
        onChange={(e: any) => onChange("company_name", e.target.value)}
        label="Company Name"
        placeholder="e.g. Company"
      />
      <TextField
        onChange={(e: any) => onChange("company_website", e.target.value)}
        label="Company Website"
        placeholder="e.g. https://mybusiness.com"
      />
      <PhoneField
        onChange={(value: string) => onChange("contact_number", value)}
        label="Phone Code"
      />
      <TextField onChange={(e: any) => onChange("job_title", e.target.value)} label="Job Title" />
      <Select
        label="Industry"
        onChange={(value: any) => onChange("industry", value)}
        data={onboardingForm.industry}
      />
      <Select
        label="How many people are working in your marketing team?"
        onChange={(value: any) => onChange("team_size", value)}
        data={onboardingForm.marketing_team_count}
      />
      <MultipleSelect
        label="What is your digital marketing goal?"
        onChange={(value: any) => onChange("marketing_goal", value)}
        data={onboardingForm.marketing_goal}
      />
      <div className="flex justify-end w-full">
        <PrimaryButton
          loading={loading}
          disabled={isactive()}
          name="Next"
          onClick={() => onSubmit(onboardingData)}
        />
      </div>
    </div>
  );
};

export default FirstStep;
