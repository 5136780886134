import React from "react";
import { Link } from "react-router-dom";
import { ButtonType } from "./buttons.interface";
import LoadingSpinner from "../UI/LoadingSpinner";

const PrimaryButton = (props: ButtonType) => {
  const { name, onClick, icon, loading, disabled, className, to, href, target, outline } = props;

  const fillClass =
    " h-button  px-3 rounded-lg flex  gap-2 items-center justify-center  bg-primaryColor hover:bg-primaryHoverColor text-white ";

  const outlineClass =
    " h-button  px-3 rounded-lg flex  gap-2 items-center justify-center  border border-primaryColor text-primaryColor hover:bg-primaryColor hover:text-white ";

  return to ? (
    <Link to={to} className={className + (outline ? outlineClass : fillClass)}>
      {icon && icon}
      {name && name}
    </Link>
  ) : href ? (
    <a href={href} target={target} className={className + (outline ? outlineClass : fillClass)}>
      {icon && icon}
      {name && name}
    </a>
  ) : (
    <button
      disabled={disabled}
      onClick={onClick}
      className={
        (disabled ? " opacity-60 " : "  ") + className + (outline ? outlineClass : fillClass)
      }>
      {loading ? <LoadingSpinner noPadding size="w-4 h-4" /> : icon && icon}
      {name && name}
    </button>
  );
};

export default PrimaryButton;
