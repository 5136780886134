import { CheckIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { fetchIyzicoPlans, postIyzicoCheckoutForm } from "@/api/iyzico";
import { useMutation, useQuery } from "react-query";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { userAtom } from "@/store/atoms/user.atoms";
import { useRecoilState } from "recoil";
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";
import { authMe } from "@/api/auth";
import { fetchAddressFromIP } from "@/utils/fetchAddressFromIP";

import { t } from "i18next";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { FaCheck } from "react-icons/fa";
import SideModal from "@/components/Modal/SideModal";
import GetExtraCredit from "../GetExtraCredit";
import ProfileInformationUpdate from "../ProfileInformationUpdate";

export default function PricingTable() {
  const [currenctFromCountry, setCurrencyFromCountry] = useState<any>(null);
  const [isLoadingIp, setIsLoadingIp] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isProfileModal, setProfileModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const { mutateAsync, isLoading: isLoadingCheckout } = useMutation(postIyzicoCheckoutForm, {});
  const {
    data: authData,
    refetch: refetchAuthData,
    isLoading: isLoadingAuth,
  } = useQuery("authmeProfile", authMe);
  const [user] = useRecoilState(userAtom);
  const { projectId, isLoading: isLoadingProjectId, pricingPlan } = useFetchingProjectIds();
  const [selectedPlan, setSelectedPlan] = useState(pricingPlan.id);
  const { data, isLoading, isFetched } = useQuery(
    "iyzicoPlans",
    () =>
      fetchIyzicoPlans({
        currency: currenctFromCountry,
        projectId,
      }),
    {
      retry: false,
      enabled: !!currenctFromCountry && !!projectId,
      cacheTime: 0,
    }
  );
  const [error, setError] = useState("");

  useEffect(() => {
    if (isFetched) {
      const currentPlan = data.mikrolo.find((item: any) => item.id === pricingPlan.id);

      setSelectedPlan(currentPlan);
    }
  }, [data]);

  useEffect(() => {
    setIsLoadingIp(true);
    fetchAddressFromIP().then((res) => {
      if (res.country_name === "Türkiye") {
        setCurrencyFromCountry("TRY");
      } else {
        setCurrencyFromCountry("USD");
      }
      setIsLoadingIp(false);
    });
  }, []);

  const handleClick = (tier: any) => {
    if (!authData?.invoice_type || !authData?.address?.country) {
      setProfileModal(true);
      return;
    }

    handleIyzcoRedirect(tier);
  };

  const handleIyzcoRedirect = (tier: any) => {
    mutateAsync({
      locale: "tr",
      conversation_id: projectId.toString(),
      project_id: projectId,
      callback_url: `${process.env.REACT_APP_BASE_URL}/iyzico/callback/${projectId}?redirectPath=design`,
      pricing_plan_reference_code: tier.iyzico_pricing_plan_reference_code,
      subscription_initial_status: "ACTIVE",
      customer: {
        name: user?.first_name,
        surname: user?.last_name,
        email: user?.email,
        gsm_number: "+905555555555",
        identity_number: "11111111111",
        billing_address: {
          contact_name: user?.first_name,
          city: "İstanbul",
          country: "Turkey",
          address:
            "Maslak, Teknokent ARI, Reşitpaşa Mah. Katar Cad, 3 Binası No:4 D:B208, 34467 Sarıyer/İstanbul",
        },
      },
    })
      .then((res) => {
        if (res.status === "failure") {
          alert(res.error_message);
          setError(res.error_message);
        } else {
          // open new tab
          navigate("/payment", {
            state: {
              checkoutFormContent: res.checkout_form_content,
            },
          });
          setError("");
          window.location.reload();
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setError(error.response.data.detail);
        }
      });
  };

  const handleRefetchProfileUpdate = () => {
    refetchAuthData();
    handleIyzcoRedirect(selectedPlan);
  };

  return (
    <div className=" bg-white px-6 py-6 flex justify-center w-full">
      {isLoading || isLoadingProjectId || isLoadingAuth || isLoadingIp ? (
        <LoadingSpinner size="w-8 h-8" />
      ) : (
        <div className="w-full">
          {isLoadingCheckout && (
            <div className="fixed flex justify-center items-center top-0 left-0 h-screen w-screen z-[999]">
              <div className="bg-gray-500 opacity-10 h-screen w-screen"></div>
              <div className="absolute">
                <LoadingSpinner size="w-12 h-12" />
              </div>
            </div>
          )}
          <div className=" w-full flex flex-col items-center  relative">
            <div className="py-6 flex flex-col items-center w-full">
              <h1 className="text-2xl font-medium leading-6 text-gray-900">{t("plans")}</h1>
              <p className="mt-2 mb-4 text-sm text-blac sm:text-left">
                {t(
                  "Start building for free, then add a site plan to go live. Account plans unlock additional features."
                )}
              </p>
              {error && (
                <span className="capitalize text-red-dark p-2 border border-red-900 bg-red-50 rounded-lg text-sm w-10/12 text-center">
                  {error}
                </span>
              )}
            </div>

            <div className="w-10/12 flex justify-between gap-4 items-stretch ">
              <div className="w-6/12 shadow-lg bg-white border  border-gray-300 rounded-lg flex flex-col justify-between bg-white">
                <div className="flex flex-col w-full">
                  <div className="p-4 border-b">
                    <h2 className="text-lg font-medium leading-6 text-gray-900 capitalize">
                      {t(selectedPlan?.name).toLowerCase()}
                    </h2>
                    <p className="my-2">
                      <span className="text-4xl font-bold tracking-tight text-gray-900">
                        {selectedPlan.iyzico_pricing_plan_currency === "TRY" && "₺"}
                        {selectedPlan.iyzico_pricing_plan_currency === "USD" && "$"}
                        {selectedPlan.iyzico_pricing_plan_price
                          ? selectedPlan.iyzico_pricing_plan_price
                          : "0"}
                      </span>
                    </p>
                    {selectedPlan?.id !== 1 &&
                      (selectedPlan?.id === 111 ? (
                        <p className="text-sm text-gray-900">10 {t("credit")} </p>
                      ) : (
                        <p className="text-sm text-gray-900">
                          {t("paymentPeriod") + ": " + selectedPlan?.name?.toLowerCase()}
                        </p>
                      ))}
                  </div>
                  <div className="p-4 ">
                    {selectedPlan.id !== 111 ? (
                      <>
                        <h3 className="text-sm font-medium text-gray-900">
                          {t("What's included")}
                        </h3>
                        <ul className="mt-6 space-y-4">
                          <li className="flex items-start">
                            <CheckIcon
                              className="h-5 w-5 flex-shrink-0 text-green-500"
                              aria-hidden="true"
                            />
                            <p className="ml-3 text-sm text-gray-500">
                              {/*    {tier.assignable_task_limit > 1
                        ? includedFeatures["assignable_task_limit"]
                        : "10 tasks"} */}
                              {selectedPlan?.credit} {t("credit")}
                            </p>
                          </li>
                          {selectedPlan.has_integrations && (
                            <li className="flex items-start">
                              <CheckIcon
                                className="h-5 w-5 flex-shrink-0 text-green-500"
                                aria-hidden="true"
                              />
                              <p className="ml-3 text-sm text-gray-500">{t("integrations_true")}</p>
                            </li>
                          )}

                          <li className="flex items-start">
                            <CheckIcon
                              className="h-5 w-5 flex-shrink-0 text-green-500"
                              aria-hidden="true"
                            />
                            <p className="ml-3 text-sm text-gray-500">
                              {selectedPlan.has_premium_reports
                                ? t("has_premium_rules_true")
                                : t("has_premium_rules_false")}
                            </p>
                          </li>
                          <li className="flex items-start">
                            <CheckIcon
                              className="h-5 w-5 flex-shrink-0 text-green-500"
                              aria-hidden="true"
                            />
                            <p className="ml-3 text-sm text-gray-500">
                              {selectedPlan.has_premium_rules
                                ? t("premium_rules_true")
                                : t("premium_rules_false")}
                            </p>
                          </li>
                          {/*   {tier.has_premium_support && (
                          <li className="flex items-start">
                            <CheckIcon
                              className="h-5 w-5 flex-shrink-0 text-green-500"
                              aria-hidden="true"
                            />
                            <p className="ml-3 text-sm text-gray-500">{t("supports_true")}</p>
                          </li>
                        )} */}
                        </ul>
                      </>
                    ) : (
                      <h5>{t("extraCreditDescription")}</h5>
                    )}
                  </div>
                </div>
                <div className="p-4">
                  {pricingPlan?.id === selectedPlan.id ? (
                    <div className=" text-white">
                      <button
                        disabled={true}
                        className="mt-4 block w-full rounded-md border border-green-600 bg-green-500 py-2 text-center text-sm font-semibold text-white">
                        {t("Your Plan")}
                      </button>
                    </div>
                  ) : (
                    selectedPlan?.id !== 1 &&
                    selectedPlan?.id !== 111 && (
                      <DefaultButton
                        className="mt-4 block w-full  "
                        isDisabled={selectedPlan.id === 1 ? true : false}
                        onClick={() => handleClick(selectedPlan)}>
                        {t("buyNow")}
                      </DefaultButton>
                    )
                  )}
                  {selectedPlan.id === 111 &&
                    (pricingPlan.id === 1 ? (
                      <span className="flex justify-center p-3 border border-red-400 bg-red-100 text-red-600 text-sm rounded-lg ">
                        {t("getExtraCreditRequireText")}
                      </span>
                    ) : (
                      <DefaultButton
                        onClick={() => setIsModalOpen(true)}
                        className=" block w-full  "
                        isDisabled={pricingPlan.id === 1 ? true : false}>
                        {t("buyExtraCredit")}
                      </DefaultButton>
                    ))}
                </div>
              </div>

              <div className="w-6/12 flex flex-col  gap-2  ">
                {data?.mikrolo.map((item: any, index: number) => [
                  <div
                    key={index}
                    onClick={() => setSelectedPlan(item)}
                    className={
                      "cursor-pointer flex items-center justify-between  px-4 py-4 border border-gray-300 rounded-lg  " +
                      (index === data?.mikrolo.length - 1 ? "" : " border-b ") +
                      (selectedPlan.id === item.id ? "bg-primaryColor text-white " : "bg-white")
                    }>
                    <span className="capitalize font-normal flex items-cennter">
                      <span
                        className={
                          "w-5 h-5 flex items-center justify-center border rounded-full mr-2" +
                          (selectedPlan.id === item.id
                            ? " bg-white text-primaryColor"
                            : " border-gray-400")
                        }>
                        {selectedPlan.id === item.id && <FaCheck />}
                      </span>

                      {item.name.toLowerCase() + " Plan"}
                    </span>
                    <span className="">
                      <span className="text-2xl font-normal tracking-tight ">
                        {item.iyzico_pricing_plan_currency === "TRY" && "₺"}
                        {item.iyzico_pricing_plan_currency === "USD" && "$"}
                        {item.iyzico_pricing_plan_price ? item.iyzico_pricing_plan_price : "0"}
                      </span>
                    </span>
                  </div>,
                ])}
                <div
                  onClick={() =>
                    setSelectedPlan({
                      name: "extraCredit",
                      id: 111,
                      iyzico_pricing_plan_currency: currenctFromCountry,
                      iyzico_pricing_plan_price: 30,
                    })
                  }
                  className={
                    "cursor-pointer flex items-center justify-between  px-4 py-4 border border-gray-300 rounded-lg  " +
                    (selectedPlan.id === 111 ? "bg-primaryColor text-white " : "bg-white")
                  }>
                  <span className="capitalize font-normal flex items-cennter">
                    <span
                      className={
                        "w-5 h-5 flex items-center justify-center border rounded-full mr-2" +
                        (selectedPlan.id === 111
                          ? " bg-white text-primaryColor"
                          : " border-gray-400")
                      }>
                      {selectedPlan.id === 111 && <FaCheck />}
                    </span>
                    <span className="capitalize font-semibold">Extra Capacity</span>
                  </span>
                  <span className="text-2xl font-semibold tracking-tight ">
                    {currenctFromCountry === "TRY" ? "₺1500" : "$30"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <SideModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Get Extra Credit(s)"
        size="w-4/12">
        <GetExtraCredit
          onClose={() => setIsModalOpen(false)}
          projectId={projectId}
          authData={authData}
          currenctFromCountry={currenctFromCountry}
          refetch={refetchAuthData}
        />
      </SideModal>

      <SideModal
        open={isProfileModal}
        onClose={() => setProfileModal(false)}
        title="Update Profile Information"
        size="w-4/12">
        <div className="w-full lex flex-col p-4">
          <ProfileInformationUpdate
            onClose={() => setProfileModal(false)}
            refetch={handleRefetchProfileUpdate}
          />
        </div>
      </SideModal>
    </div>
  );
}
