import ContentBar from "@/components/ContentBar";
import AssessmentLayout from "@/components/Layout/Assessment";
import { t } from "i18next";
import { useEffect, useState } from "react";
import {
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isToday,
  isSameMonth,
  format,
  addMonths,
  subMonths,
  startOfWeek,
  endOfWeek,
} from "date-fns";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import SecondaryButton from "@/components/buttons/SecondaryButton";
import {
  FaFacebook,
  FaGoogle,
  FaInstagramSquare,
  FaSortNumericUpAlt,
  FaTwitter,
} from "react-icons/fa";
import RightModal from "@/components/Modal/RightModal";
import Select from "@/components/FormElements/Select";
import TextField from "@/components/FormElements/TextField";
import { Divider } from "@/components/Divider";
import FileUploader from "@/components/FormElements/FileUploader";
import PrimaryButton from "@/components/buttons/PrimaryButton";

interface Event {
  id: number;
  name: string;
  datetime: string;
  description: string;
  time: string;
  image: string;
  status: string;
  type: string;
}

interface Day {
  date: string;
  isCurrentMonth: boolean;
  isToday: boolean;
  isSelected: boolean;
  events: Event[];
}

function generateDaysForMonth(month: string, events: Event[]): Day[] {
  const start = startOfMonth(new Date(month));
  const end = endOfMonth(new Date(month));
  const startWeek = startOfWeek(start, { weekStartsOn: 1 });
  const endWeek = endOfWeek(end, { weekStartsOn: 1 });

  const days: Day[] = eachDayOfInterval({ start: startWeek, end: endWeek }).map((date) => ({
    date: format(date, "yyyy-MM-dd"),
    isCurrentMonth: isSameMonth(date, start),
    isToday: isToday(date),
    isSelected: false,
    events: [],
  }));

  events.forEach((event) => {
    const eventDate = format(new Date(event.datetime), "yyyy-MM-dd");
    const day = days.find((d) => d.date === eventDate);
    if (day) {
      day.events.push(event);
    }
  });

  return days;
}

type IconProps = {
  icon: string;
};

export const SocialMediaIcons = (props: IconProps) => {
  const { icon } = props;
  const lowerIcon = String(icon).toLowerCase();

  if (lowerIcon.includes("google")) {
    return <FaGoogle className="text- text-primaryColor" />;
  } else if (lowerIcon.includes("facebook")) {
    return <FaFacebook className="text-lg text-red-500" />;
  } else if (lowerIcon.includes("meta")) {
    return <FaFacebook className="text-lg text-blue-500" />;
  } else if (lowerIcon.includes("twitter")) {
    return <FaTwitter className="text-lg text-green-500" />;
  } else if (lowerIcon.includes("instagram")) {
    return <FaInstagramSquare className="text-lg text-instagram" />;
  } else {
    return <FaSortNumericUpAlt className="text-lg text-secondaryHoverColor" />;
  }
};

const Calendar = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date().toISOString().slice(0, 7));
  const [days, setDays] = useState<Day[]>([]);
  const [selectedDay, setSelectedDay] = useState<Day | undefined>();
  const [isHovered, setIsHovered] = useState("");
  const [createEvent, setCreateEvent] = useState(false);
  const [formData, setFormData] = useState({
    platform: [],
    date: "",
    description: "",
    file: [],
  });
  const platforms = [
    { id: "instagram", value: "Instagram" },
    { id: "facebook", value: "Facebook" },
    { id: "x", value: "X" },
    { id: "google", value: "Google" },
  ];

  const [currentEvents, setCurrentEvents] = useState<Event[]>([
    {
      id: 1,
      name: "Boost Your Business with Google Ads! ",
      datetime: "2024-08-22T00:00:00",
      description:
        "Are you ready to take your business to new heights? At Geniusmaster, our expert Google Ads consultation will help you optimize your campaigns and maximize your ROI. Whether you're just starting or looking to refine your strategy, we've got you covered!",
      time: "",
      image:
        "https://fastly.picsum.photos/id/9/5000/3269.jpg?hmac=cZKbaLeduq7rNB8X-bigYO8bvPIWtT-mh8GRXtU3vPc",
      status: "pending",
      type: "FACEBOOK_AD",
    },
    {
      id: 2,
      name: "Master Google Ads with Geniusmaster!",
      datetime: "2024-08-22T00:00:00",
      description:
        "Struggling to make the most of your Google Ads? Our tailored consultation services at Geniusmaster are designed to help you unlock the full potential of your campaigns. From keyword research to ad creation, we’ll guide you every step of the way.",
      time: "",
      image:
        "https://fastly.picsum.photos/id/9/5000/3269.jpg?hmac=cZKbaLeduq7rNB8X-bigYO8bvPIWtT-mh8GRXtU3vPc",
      status: "pending",
      type: "FACEBOOK_AD",
    },
    {
      id: 3,
      name: "Transform Your Google Ads Strategy! ",
      datetime: "2024-08-12T00:00:00",
      description:
        "Are you getting the results you want from your Google Ads? Geniusmaster offers expert consultation to help you refine your strategy and achieve better outcomes. Our customized approach ensures that your ads reach the right audience and drive meaningful results.",
      time: "",
      image:
        "https://fastly.picsum.photos/id/21/3008/2008.jpg?hmac=T8DSVNvP-QldCew7WD4jj_S3mWwxZPqdF0CNPksSko4",
      status: "posted",
      type: "INSTAGRAM_AD",
    },
  ]);

  useEffect(() => {
    setDays(generateDaysForMonth(currentMonth, currentEvents));
  }, [currentMonth, currentEvents]);

  const handlePreviousMonth = () => {
    setCurrentMonth(format(subMonths(new Date(currentMonth), 1), "yyyy-MM"));
  };

  const handleNextMonth = () => {
    setCurrentMonth(format(addMonths(new Date(currentMonth), 1), "yyyy-MM"));
  };

  const handleSelectDay = (day: Day) => {
    setSelectedDay(day);
    setDays((prevDays) =>
      prevDays.map((d) =>
        d.date === day.date ? { ...d, isSelected: true } : { ...d, isSelected: false }
      )
    );
  };

  const handleSelect = (key: string, selected: any) => {
    setFormData({ ...formData, [key]: selected });
  };

  console.log(formData);
  return (
    <AssessmentLayout>
      <ContentBar title="Socail Media Calendar" />
      <div className="flex flex-col gap-6 w-full py-6">
        <div className="flex h-full flex-col">
          <header className="flex items-center justify-between border-b border-gray-200 py-4 lg:flex-none">
            <div className="relative flex items-stretch rounded-md bg-white shadow-sm md:items-stretch">
              <button
                type="button"
                className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-700 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
                onClick={handlePreviousMonth}>
                <span className="sr-only">Previous month</span>
                <MdChevronLeft />
              </button>
              <div className="flex items-center border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative ">
                <time dateTime={currentMonth}>
                  {new Date(currentMonth).toLocaleString("default", {
                    month: "long",
                    year: "numeric",
                  })}
                </time>
              </div>
              <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
              <button
                type="button"
                className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-700 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
                onClick={handleNextMonth}>
                <span className="sr-only">Next month</span>
                <MdChevronRight />
              </button>
            </div>

            <div className="flex items-center">
              <div className="hidden md:ml-4 md:flex md:items-center">
                <SecondaryButton onClick={() => setCreateEvent(true)} name="New Event" />
              </div>
            </div>
          </header>

          <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
            <div className="sticky top-[70px] grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none z-50">
              <div className="bg-white py-2">
                M<span className="sr-only sm:not-sr-only">onday</span>
              </div>
              <div className="bg-white py-2">
                T<span className="sr-only sm:not-sr-only">uesday</span>
              </div>
              <div className="bg-white py-2">
                W<span className="sr-only sm:not-sr-only">ednesday</span>
              </div>
              <div className="bg-white py-2">
                T<span className="sr-only sm:not-sr-only">hursday</span>
              </div>
              <div className="bg-white py-2">
                F<span className="sr-only sm:not-sr-only">riday</span>
              </div>
              <div className="bg-white py-2">
                S<span className="sr-only sm:not-sr-only">aturday</span>
              </div>
              <div className="bg-white py-2">
                S<span className="sr-only sm:not-sr-only">unday</span>
              </div>
            </div>
            <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 ">
              <div
                className={
                  " grid w-full grid-cols-7  gap-px " +
                  (days.length > 35 ? "grid-rows-6 " : "grid-rows-5")
                }>
                {days.map((day: any) => (
                  <button
                    key={day.date}
                    type="button"
                    className={
                      "flex  flex-col hover:bg-gray-100 focus:z-10 h-[200px] " +
                      (day.isCurrentMonth ? " bg-white " : " bg-gray-200 ")
                    }
                    onClick={() => handleSelectDay(day)}>
                    <span
                      className={
                        "w-8 h-8 flex items-center justify-center rounded-full m-2 font-semibold  " +
                        (day.isToday ? " bg-secondaryColor bg-opacity-30  " : "")
                      }>
                      {day.date.split("-")[2]}
                    </span>
                    {day.events.length > 0 && (
                      <div className=" w-full h-[200px]  flex  overflow-auto  flex-col items-start justify-start  gap-y-2">
                        {day.events.map((event: any, index: number) => (
                          <div
                            key={index}
                            onMouseEnter={() => setIsHovered(event.name)}
                            onMouseLeave={() => setIsHovered("")}
                            className={` group w-full flex flex-col items-center justify-start gap-y-1 bg-opacity-30 border-l-4 ${
                              event.status === "posted" ? "border-secondaryColor" : "border-yellow"
                            }`}>
                            <div className="px-3 flex flex-col justify-start gap-y-1 py-1 hover:bg-white">
                              <SocialMediaIcons icon={event.type} />
                              <span className="text-xs font-semibold text-left leading-4">
                                {event.name}
                              </span>
                            </div>
                            {isHovered === event.name && (
                              <div className="absolute mt-14  flex flex-col justify-start items-start gap-y-3 z-50 p-3 mt-2 w-[300px] bg-white border border-gray-300 rounded-lg shadow-md">
                                <img src={event.image} alt={event.name} />
                                <span className="text-left text-sm">{event.description} </span>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <RightModal
        size="w-3/12"
        open={createEvent}
        onClose={() => setCreateEvent(false)}
        action={<PrimaryButton name="Create" className="w-full" />}>
        <div className="w-full he-full flex  flex-1 flex-col justify-start items-start text-left  px-6 pt-10 gap-y-3  ">
          <h2 className="font-semibold text-lg">Create a Post</h2>
          <div className="w-full grid grid-cols-10 items-center gap-3 text-sm ">
            <div className="col-span-4 font-[500] ">Platform</div>
            <div className="col-span-6">
              <Select
                onChange={(selected: any) => handleSelect("platform", selected)}
                data={platforms}
                placeholder="Select Platform"
              />
            </div>
            <div className="col-span-4 font-[500] ">Schedule date</div>
            <div className="col-span-6">
              <TextField
                className="w-full"
                type="date"
                onChange={(selected) => handleSelect("date", selected)}
              />
            </div>
          </div>
          <Divider />
          <textarea
            className="w-full focus:outline-none"
            placeholder="Write your description here."
            onChange={(e: any) => handleSelect("description", e.target.value)}
            rows={6}
          />
          <Divider />
          <FileUploader onSelect={(files) => handleSelect("file", files)} />
        </div>
      </RightModal>
    </AssessmentLayout>
  );
};

export default Calendar;
