import React from "react";
import { Link } from "react-router-dom";
import { MdHomeFilled, MdChevronRight } from "react-icons/md";
import { useRecoilState } from "recoil";
import { adReportBreadCrumb } from "@/store/atoms/adReportBreadcrumb.atom";
import { t } from "i18next";

type Props = {
  reportId?: any;
  projectId?: any;
  clientId?: any;
  campaignId?: any;
  adGroupId?: any;
  adId?: any;
};

const Breadcrumb = (props: Props) => {
  const { projectId, reportId, clientId, campaignId, adGroupId, adId } = props;
  const [breadcrumbState] = useRecoilState(adReportBreadCrumb);

  return (
    <div className="text-sm text-[#707070] mt-8 mb-6">
      <div className="text-sm text-[#707070] flex gap-2 flex-row items-center w-fit pb-1">
        <div className="flex items-center gap-2">
          <Link to="/">
            <MdHomeFilled className="w-4 h-4" />
          </Link>

          <Link className="flex items-center gap-1" to="/reports">
            <MdChevronRight className="w-4 h-4" />
            {t("reports")}
          </Link>
          {window.location.pathname !== "/reports" && (
            <Link
              className="flex items-center gap-1"
              to={`/reports/ad-reports/${projectId}/${reportId}/${clientId}`}>
              <MdChevronRight className="w-4 h-4" />
              {t("adReports")}
            </Link>
          )}

          {breadcrumbState.map((item: any, index: number) => (
            <Link
              key={index}
              className="flex items-center gap-1 capitalized"
              to={
                item.typeStr == "CustomerClient"
                  ? `/reports/ad-reports/${projectId}/${reportId}/${clientId}/metrics`
                  : item.typeStr == "Campaign"
                  ? `/reports/ad-reports/${projectId}/${reportId}/${clientId}/metrics/campaign/${item.id}`
                  : item.typeStr === "BiddingStrategy"
                  ? `/reports/ad-reports/${projectId}/${reportId}/${clientId}/metrics/bidding-strategy/${item.id}`
                  : item.typeStr === "AdGroup"
                  ? `/reports/ad-reports/${projectId}/${reportId}/${clientId}/metrics/campaign/${campaignId}/ad-group/${item.id}`
                  : item.typeStr === "Ad"
                  ? `/reports/ad-reports/${projectId}/${reportId}/${clientId}/metrics/campaign/${campaignId}/ad-group/${adGroupId}/ad/${item.id}`
                  : item.typeStr === "Ad Group Keyword"
                  ? `/reports/ad-reports/${projectId}/${reportId}/${clientId}/metrics/campaign/${campaignId}/ad-group/${adGroupId}/ad-group-keyword/${item.id}`
                  : item.typeStr === "SearchTerm"
                  ? `/reports/ad-reports/${projectId}/${reportId}/${clientId}/metrics/campaign/${campaignId}/ad-group/${adGroupId}/ad/${adId}/search-term/${item.id}`
                  : ""
              }>
              <MdChevronRight className="w-4 h-4" />
              {item.text.slice(0, 20)}
              {item.text.length > 20 && "..."}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
