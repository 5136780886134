import React, { useState } from "react";
import { ImSortAmountDesc, ImSortAmountAsc } from "react-icons/im";
import { MdFirstPage, MdLastPage, MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import InfoBoard from "../cards/InfoCard";
import { t } from "i18next";

type Props = {
  data: any;
  tableId?: any;
  isFetching: boolean;
  handleOrder?: (value: number) => void;
  handleSortOrder?: (value: number) => void;
  handlePage?: (value: number) => void;
  handlePageSize?: (value: number) => void;
  handleClick?: (path: string, id: number) => void;
};

const DataTable = (props: Props) => {
  const {
    data,
    tableId,
    isFetching,
    handleOrder,
    handleSortOrder,
    handlePage,
    handlePageSize,
    handleClick,
  } = props;

  console.log(data);

  const { title, currentPage, pageSize, totalPageCount, totalRecordCount } = data.table;

  const columms = data?.table.header.columns;
  const rows = data?.table.rows;
  const [appearSortIcon, setAppearSortIcon] = useState("");

  const filterDataString = localStorage.getItem("table-filter");
  const filterData = filterDataString ? JSON.parse(filterDataString) : null;

  const handleShowSortIcon = (order: any) => {
    setAppearSortIcon(order);
  };

  const handleHideSortIcon = () => {
    setAppearSortIcon("");
  };

  const handleOrderCol = (value: number) => {
    if (handleOrder) {
      handleOrder(value);
      handlePageCount(1);
    }
    if (handleSortOrder) {
      handleSortOrder(value);
      handlePageCount(1);
    }
  };

  const handlePageCount = (value: number) => {
    if (handlePage) {
      handlePage(value);
    }
  };

  const handlePageSizeCount = (value: number) => {
    if (handlePageSize) {
      handlePageSize(value);
      handlePageCount(1);
    }
  };

  const handleCLickItem = (path: string, id: number) => {
    if (handleClick) {
      handleClick(path, id);
    }
  };

  return (
    <div className="relative w-full flex flex-col">
      <table className="w-full text-sm text-left text-gray-500 ">
        <thead className="text-xs text-gray-700 uppercase bg-gray-100 ">
          <tr>
            {columms.map((col: any, index: number) => (
              <th
                onClick={() => handleOrderCol(col.orderBy)}
                onMouseEnter={() => handleShowSortIcon(col.orderBy)}
                onMouseLeave={() => handleHideSortIcon()}
                className="px-4 py-4 cursor-pointer "
                key={index}>
                <div
                  className={
                    "flex items-center w-full " +
                    (col.fontWeight === "bold" ? "font-bold" : "font-normal") +
                    (col.textAlignment === "left" ? " justify-start" : " justify-end")
                  }>
                  {col.text}
                  {appearSortIcon === col.orderBy ? (
                    filterData[tableId].orderBy === col.orderBy ? (
                      filterData[tableId].sortOrder == 1 ? (
                        <ImSortAmountDesc className="ml-2" />
                      ) : (
                        <ImSortAmountAsc className="ml-2" />
                      )
                    ) : (
                      <ImSortAmountDesc className="ml-2" />
                    )
                  ) : filterData[tableId].orderBy === col.orderBy ? (
                    filterData[tableId].sortOrder == 1 ? (
                      <ImSortAmountAsc className="ml-2" />
                    ) : (
                      <ImSortAmountDesc className="ml-2" />
                    )
                  ) : null}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className=" ">
          {isFetching ? (
            <tr>
              {columms.map((col: any, index: number) => (
                <th className="px-4 py-4 cursor-pointer " key={index}>
                  <div className="animate-pulse w-full h-[10px] bg-gray-200 rounded-xl"></div>
                </th>
              ))}
            </tr>
          ) : (
            rows.map((rows: any, index: number) => (
              <tr
                key={index}
                className="odd:bg-white even:bg-gray-50 border-b text-black hover:bg-gray-200 shacow-lg">
                {rows.columns.map((row: any, index: number) => (
                  <td
                    onClick={() => row.path && handleCLickItem(row.id, row.path)}
                    key={index}
                    className={
                      "px-4 py-4 " +
                      (row.fontWeight === "bold" ? "font-bold" : "font-normal") +
                      (row.textAlignment === "left" ? " text-left" : " text-right") +
                      (row.path ? " cursor-pointer underline hover:text-primaryColor" : "")
                    }>
                    {row.text}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
      {totalRecordCount === 0 && (
        <InfoBoard title={t("noDataTitle")} description={t("noDataDescription")} />
      )}
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between p-4">
        <div className="flex items-center">
          <span>Number of rows</span>
          <select
            value={pageSize}
            onChange={(e) => handlePageSizeCount(Number(e.target.value))}
            className="ml-2 border border-gray-400 px-1 py-1 cursor-pointer focus:outline-none rounded-lg ">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <div>
          {totalPageCount > 1 && (
            <nav
              className="isolate inline-flex -space-x-px rounded-md shadow-sm border"
              aria-label="Pagination">
              <button
                onClick={() => (currentPage > 1 ? handlePageCount(1) : null)}
                className="relative inline-flex items-center px-2 py-2 text-md">
                <span className="sr-only">Previous</span>
                <MdFirstPage />
              </button>
              <button
                onClick={() => (currentPage > 1 ? handlePageCount(currentPage - 1) : null)}
                className="relative inline-flex items-center border-l px-2 py-2 text-md">
                <span className="sr-only">Previous</span>
                <MdNavigateBefore />
              </button>
              {Array.from({ length: totalPageCount }, (_, i) => i + 1).map((page) => {
                const offset = 5;
                return Math.abs(currentPage - page) <= offset ? (
                  <button
                    key={page}
                    onClick={() => handlePageCount(page)}
                    className={`relative inline-flex items-center px-4 py-2 text-sm border-l ${
                      currentPage === page ? "text-white bg-primaryColor" : "text-black bg-white"
                    }`}>
                    {page}
                  </button>
                ) : null;
              })}

              <button
                onClick={() =>
                  currentPage !== totalPageCount ? handlePageCount(currentPage + 1) : null
                }
                className="relative inline-flex items-center  px-2 py-2 text-md border-l disabled:bg-primaryColor">
                <span className="sr-only">Next</span>
                <MdNavigateNext />
              </button>
              <button
                onClick={() =>
                  currentPage < totalPageCount ? handlePageCount(totalPageCount) : null
                }
                className="relative inline-flex items-center  px-2 py-2 text-md border-l">
                <span className="sr-only">Next</span>
                <MdLastPage />
              </button>
            </nav>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataTable;
