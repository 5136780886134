import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import PricingTableForAssign from "@/components/PricingTableForAssign";
import { useState } from "react";
import { truncateString } from "@/utils/truncateString";
import { useRecoilState } from "recoil";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";

type Props = {
  data?: any;
  pricingPlan: any;
  thumbnail?: string;
  application?: any;
  reportDescription?: string;
  isRequired?: boolean;
};

const ReportCard = (props: Props) => {
  const [project] = useRecoilState(projectInfo);
  const projectId = project.id;
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const defaultClientId = props.data?.customer_client_id;

  const handleNavigateToReport = () => {
    props.application === "google_ads"
      ? navigate(
          `/reports/ad-reports/${projectId}/${
            props.data.ReportId || props.data.id
          }/${defaultClientId}`
        )
      : navigate(`/reports/analytics-reports/${projectId}/${props.data.ReportId || props.data.id}`);
  };

  return (
    <div
      className="w-[344px] h-[360px] bg-bgCardColor rounded-lg shadow pt-3 cursor-pointer"
      onClick={() => handleNavigateToReport()}>
      <PricingTableForAssign
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={t("The features covered by your plan are over!")}
        description={t(
          "If you want to contune using the features’ how about choosing a plan that suits you better?"
        )}
      />

      <div className="bg-secondaryColor mx-3  rounded-lg overflow-hidden mb-1 shadow-lg w-[320px] h-[200px] relative ">
        <img src={props.thumbnail} className="h-full w-full object-cover" alt="report card" />
        <div className=" z-[99] absolute bg-gray-100 px-2 py-1 rounded-lg right-1 bottom-1 font-medium flex items-center border border-gray-300">
          <div className="flex items-center capitalize">
            {Array.isArray(props.application) && props.application.includes("google_ads") && (
              <img src="/assets/icons/google_ads.svg" alt="" className="mr-2" width={28} />
            )}
            {!Array.isArray(props.application) && props.application === "google_analytics" && (
              <img src="/assets/icons/google_analytics.svg" alt="" className="mr-2" width={26} />
            )}
            {Array.isArray(props.application) &&
              props.application.length > 0 &&
              props.application[0].replace("_", " ")}
            {!Array.isArray(props.application) &&
              props.application &&
              props.application.replace("_", " ")}
          </div>
        </div>
      </div>

      <div className="bg-white/90 p-3 flex flex-col gap-1 rounded-b-[10px] z-[50]">
        {/* <div className="mb-3"> */}
        <div className="font-semibold text-lg text-gray-900 capitalize ">
          {props.data.ReportName || props.data.name}
        </div>
        {/* {data.has_sample || data.is_coming_soon ? (
            <div className="font-medium text-sm text-orange-500 mt-1 bg-orange-50 w-fit rounded-lg py-.5 px-2.5">
              {t("sample")}
            </div>
          ) : (
            <div className="font-medium text-xs text-green-800 mt-1 bg-green-100 w-fit rounded-lg py-.5 px-2.5 capitalize">
              {t("live")}
            </div>
          )} */}
        {/* </div> */}
        <div className="text-base text-gray-500">
          {props.reportDescription
            ? truncateString(props.reportDescription, 93)
            : "No metrics found."}
        </div>
      </div>
    </div>
  );
};

export default ReportCard;
