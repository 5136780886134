import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { useMutation } from "react-query";
import { cancelSubscription } from "@/api/iyzico";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { t } from "i18next";
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";
import { useEffect, useState } from "react";
import { fetchAddressFromIP } from "@/utils/fetchAddressFromIP";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import PopupModal from "@/components/PopupModal/PopupModal";

const CurrentBilling = () => {
  const [, setCurrencyFromCountry] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [err, setErr] = useState<any>(null);

  const {
    projectId,
    isLoading: isLoadingProjectId,
    pricingPlan,
    assignedTaskCount,
    totalUsedCredit,
    totalCredit,
  } = useFetchingProjectIds();

  useEffect(() => {
    setIsLoading(true);
    fetchAddressFromIP()
      .then((res) => {
        if (res.country_name === "Turkey") setCurrencyFromCountry("TRY");
        else setCurrencyFromCountry("USD");
      })
      .finally(() => setIsLoading(false));
  }, []);

  const { mutateAsync: cancelSubs, isLoading: isLoadingCancel } = useMutation(
    cancelSubscription,
    {}
  );

  const handleCancelSubscription = () => {
    cancelSubs(projectId)
      .then(() => {
        setIsPopup(false);
        window.location.reload();
      })
      .catch((err) => {
        setErr(
          err?.response?.data?.message || err.response?.data?.detail || t("Something went wrong")
        );
      });
  };

  return (
    <div className="py-5 max-w-[1460px]">
      {isLoadingProjectId || isLoading ? (
        <LoadingSpinner size="w-8 h-8" />
      ) : (
        <div className="relative ">
          <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow lg:flex lg:max-w-none border border-indigo-300 ">
            <div className="flex-1 bg-indigo-50 px-6 py-8 lg:p-12">
              <h3 className="text-2xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight flex items-center">
                {pricingPlan?.name} {t("membership")}
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs text-center font-medium bg-green-100 text-green-800 ml-2">
                  {t("Your current plan")}
                </span>
              </h3>
              <p className="mt-6 text-base text-gray-500">
                {/**description about current plan */}
                You are currently on the {pricingPlan?.name} plan. You can upgrade or downgrade your
                plan at any time.
              </p>
              <div className="mt-8">
                <div className="flex items-center">
                  <h4 className="flex-shrink-0 bg-transparent pr-4 text-base font-semibold text-indigo-600">
                    {t("What's included")}
                  </h4>
                  <div className="flex-1 border-t-2 border-gray-200" />
                </div>
                <ul className="mt-8 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0 mb-8">
                  <li className="flex items-start lg:col-span-1">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                    </div>
                    <p className="ml-3 text-sm text-gray-700">
                      {pricingPlan?.credit} {t("credit")}
                    </p>
                  </li>

                  {pricingPlan?.has_integratons && (
                    <li className="flex items-start lg:col-span-1">
                      <div className="flex-shrink-0">
                        <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                      </div>
                      <p className="ml-3 text-sm text-gray-700">{t("integrations_true")}</p>
                    </li>
                  )}

                  <li className="flex items-start lg:col-span-1">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                    </div>
                    <p className="ml-3 text-sm text-gray-700">
                      {pricingPlan?.has_premium_reports
                        ? t("has_premium_rules_true")
                        : t("has_premium_rules_false")}
                    </p>
                  </li>

                  <li className="flex items-start lg:col-span-1">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                    </div>
                    <p className="ml-3 text-sm text-gray-700">
                      {pricingPlan.has_premium_rules
                        ? t("premium_rules_true")
                        : t("premium_rules_false")}
                    </p>
                  </li>

                  {pricingPlan?.has_premium_support && (
                    <li className="flex items-start lg:col-span-1">
                      <div className="flex-shrink-0">
                        <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                      </div>
                      <p className="ml-3 text-sm text-gray-700">{t("supports_true")}</p>
                    </li>
                  )}
                </ul>

                {pricingPlan.id !== 1 && (
                  <DefaultButton onClick={() => setIsPopup(true)}>
                    {t("Cancel Subscription")}
                  </DefaultButton>
                )}

                <div className="mt-8 ">
                  <div className="uppercase text-xs">{t("Details")}</div>
                  <div className="flex flex-col text-gray-500 text-sm ">
                    <span>
                      {t("Number of Assigned Tasks")}: {assignedTaskCount}
                    </span>
                    <span>
                      {t("Total Credits Spent")}: {totalUsedCredit}
                    </span>
                    <span>
                      {t("Available Credit")}: {totalCredit - totalUsedCredit}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PopupModal isPopup={isPopup} closeAction={() => setIsPopup(false)} width="max-w-lg">
            <>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <div className="text-base font-semibold leading-6 text-gray-900">
                    {t("Cancel Subscription")}
                  </div>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {t("Are you sure you want to cancel your subscription?")}
                    </p>
                  </div>
                </div>
              </div>
              {err && (
                <div className="mt-5 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <div className="text-base font-semibold leading-6 text-red-600">{t("Error")}</div>
                  <div className="mt-2">
                    <p className="text-sm text-red-600">{err}</p>
                  </div>
                </div>
              )}
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  disabled={isLoadingCancel}
                  onClick={handleCancelSubscription}
                  className={`
                  disabled:opacity-50 disabled:cursor-not-allowed disabled:shadow-none disabled:bg-gray-300 disabled:text-gray-500
                  inline-flex w-full justify-center items-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto`}>
                  {isLoadingCancel && <LoadingSpinner size="h-5 w-5" />}
                  {t("Cancel Subscription")}
                </button>
                <button
                  type="button"
                  onClick={() => setIsPopup(false)}
                  className={`
                  disabled:opacity-50 disabled:cursor-not-allowed disabled:shadow-none disabled:bg-gray-300 disabled:text-gray-500
                  mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto`}>
                  {t("Cancel")}
                </button>
              </div>
            </>
          </PopupModal>
        </div>
      )}
    </div>
  );
};

export default CurrentBilling;
