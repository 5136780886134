import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { t } from "i18next";

type Props = {
  isOpenReproduceAnswer: boolean;
  setIsOpenReproduceAnswer: (open: boolean) => void;
  handleReproduceAnswer: (feedback: string) => void;
};

export default function ReproduceAnswerModal(props: Props) {
  const { isOpenReproduceAnswer, setIsOpenReproduceAnswer, handleReproduceAnswer } = props;
  const [feedback, setFeedback] = useState("");

  const handleFinish = () => {
    handleReproduceAnswer(feedback);
    setIsOpenReproduceAnswer(false);
  };

  return (
    <Transition.Root show={isOpenReproduceAnswer} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[99]"
        onClose={() => {
          setIsOpenReproduceAnswer(false);
        }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-10 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900">
                      {t("reproduceAnswer")}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{t("wouldYouLikeToLeaveAFeedback")}</p>
                    </div>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="p-2 mt-1 block w-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm rounded-md"
                        placeholder={t("leaveYourFeedbackHere")}
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex gap-3">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:col-start-1 sm:mt-0 sm:text-sm"
                    onClick={() => setIsOpenReproduceAnswer(false)}>
                    {t("Go back to task")}
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    onClick={() => handleFinish()}>
                    {t("reproduceAnswer")}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
