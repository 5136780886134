import { Transition } from "@headlessui/react";
import React, { useState } from "react";
import { t } from "i18next";
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md";

type Props = {
  children: any;
  title: string;
  description?: string;
  className?: string;
  action?: any;
  showHide?: boolean;
};

const ReportSectionCard = (props: Props) => {
  const { action, title, description, children, className, showHide = false } = props;
  const [show, setShow] = useState(true);

  return (
    <div className={"bg-white border rounded-lg shadow-xl w-full  " + className}>
      <div className="px-4 py-5 border-b flex istems-center justify-between">
        <div className="flex flex-col justify-center gap-1">
          <h2 className="font-bold text-lg capitalize">{title}</h2>
          {description && <span className="text-sm text-gray-700">{description} </span>}
        </div>
        <div className="flex gap-2 items-center justify-end">
          {action}
          {showHide && (
            <button
              className="border border-gray-400 rounded-lg p-2 flex items-center gap-1"
              onClick={() => setShow(!show)}>
              {show ? t("hide") : t("show")}
              {show ? (
                <MdOutlineArrowDropUp className="text-xl text-gray-600" />
              ) : (
                <MdOutlineArrowDropDown className="text-xl text-gray-600" />
              )}
            </button>
          )}
        </div>
      </div>
      <Transition
        show={show}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1">
        {children}
      </Transition>
    </div>
  );
};

export default ReportSectionCard;
