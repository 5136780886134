import { Fragment, useState, useEffect } from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { fetchIyzicoPlans, postIyzicoCheckoutForm } from "@/api/iyzico";
import { useMutation, useQuery } from "react-query";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { userAtom } from "@/store/atoms/user.atoms";
import { useRecoilState } from "recoil";
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";
import { authMe } from "@/api/auth";
import { Dialog, Transition } from "@headlessui/react";
import { fetchAddressFromIP } from "@/utils/fetchAddressFromIP";

import { t } from "i18next";

type Props = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  title: string;
  description: string;
};

export default function PricingTableForAssign(props: Props) {
  const { isOpen, setIsOpen, title, description } = props;
  const [currenctFromCountry, setCurrencyFromCountry] = useState<any>(null);
  const [isLoadingIp, setIsLoadingIp] = useState<boolean>(false);
  const { projectId, isLoading: isLoadingProjectId, pricingPlan } = useFetchingProjectIds();

  function closeModal() {
    setIsOpen(false);
  }

  // function openModal() {
  //   setIsOpen(true);
  // }

  useEffect(() => {
    setIsLoadingIp(true);
    fetchAddressFromIP().then((res) => {
      if (res.country_name === "Turkey") setCurrencyFromCountry("TRY");
      else setCurrencyFromCountry("USD");
      setIsLoadingIp(false);
    });
  }, []);

  const { data, isLoading } = useQuery(
    "iyzicoPlans",
    () =>
      fetchIyzicoPlans({
        projectId,
        currency: currenctFromCountry,
      }),
    {
      retry: false,
      enabled: !!currenctFromCountry && !!projectId,
      cacheTime: 0,
    }
  );

  const navigate = useNavigate();
  const { mutateAsync } = useMutation(postIyzicoCheckoutForm, {});
  const { isLoading: isLoadingAuth } = useQuery("authmeProfile", authMe);

  const [user] = useRecoilState(userAtom);

  const handleClick = (tier: any) => {
    mutateAsync({
      locale: "tr",
      conversation_id: projectId.toString(),
      project_id: projectId,
      callback_url: `${process.env.REACT_APP_BASE_URL}/iyzico/callback/${projectId}`,
      pricing_plan_reference_code: tier.iyzico_pricing_plan_reference_code,
      subscription_initial_status: "ACTIVE",
      customer: {
        name: user?.first_name,
        surname: user?.last_name,
        email: user?.email,
        gsm_number: "+905555555555",
        identity_number: "11111111111",
        billing_address: {
          contact_name: user?.first_name,
          city: "İstanbul",
          country: "Turkey",
          address:
            "Maslak, Teknokent ARI, Reşitpaşa Mah. Katar Cad, 3 Binası No:4 D:B208, 34467 Sarıyer/İstanbul",
        },
      },
    }).then((res) => {
      if (res.status === "failure") {
        alert(res.error_message);
      } else {
        // open new tab
        navigate("/payment", {
          state: {
            checkoutFormContent: res.checkout_form_content,
          },
        });
        window.location.reload();
      }
    });
  };

  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[9999]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-7xl transform overflow-hidden rounded-lg bg-white  text-left align-middle shadow-xl transition-all">
                  <div className="bg-white max-w-[1460px]">
                    <div className="flex justify-end w-full p-2">
                      <button
                        type="button"
                        className=" inline-flex self-start justify-center px-2 py-1 font-medium text-blue-900  border border-transparent rounded-md hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 transition-all"
                        onClick={closeModal}>
                        {"x"}
                      </button>
                    </div>
                    {isLoading || isLoadingProjectId || isLoadingAuth || isLoadingIp ? (
                      <LoadingSpinner size="w-8 h-8" />
                    ) : (
                      <div className="mx-auto max-w-7xl py-4 px-4 sm:px-6 lg:px-8">
                        <div className="sm:align-center sm:flex sm:flex-col">
                          <h1 className="text-5xl font-bold tracking-tight text-gray-900 sm:text-center">
                            {title}
                          </h1>
                          <p className="mt-5 text-xl text-gray-500 sm:text-center">{description}</p>
                        </div>
                        <div className="mt-12 space-y-4 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
                          {data?.mikrolo
                            // ?.sort((a: any, b: any) => a.id - b.id)
                            .map((tier: any, index: number) => (
                              <div
                                key={index}
                                className="divide-y divide-gray-200 rounded-lg border border-gray-200 shadow-sm">
                                <div className="p-6">
                                  <h2 className="text-lg font-medium leading-6 text-gray-900">
                                    {tier.name}
                                  </h2>
                                  <p className="mt-4 text-sm text-gray-500"></p>
                                  <p className="mt-8">
                                    <span className="text-4xl font-bold tracking-tight text-gray-900">
                                      {tier.iyzico_pricing_plan_currency === "TRY" && "₺"}
                                      {tier.iyzico_pricing_plan_currency === "USD" && "$"}
                                      {tier.iyzico_pricing_plan_price
                                        ? tier.iyzico_pricing_plan_price
                                        : "-"}
                                    </span>{" "}
                                    <span className="text-base font-medium text-gray-500">/mo</span>
                                  </p>
                                  {tier.id === 1 ? (
                                    <>
                                      {pricingPlan?.id === tier.id && (
                                        <div className=" text-white">
                                          <button className="mt-8 block w-full rounded-md border border-green-600 bg-green-500 py-2 text-center text-sm font-semibold text-white">
                                            {t("Your Plan")}
                                          </button>
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {pricingPlan?.id === tier.id ? (
                                        <div className=" text-white">
                                          <button className="mt-8 block w-full rounded-md border border-green-600 bg-green-500 py-2 text-center text-sm font-semibold text-white">
                                            {t("Your Plan")}
                                          </button>
                                        </div>
                                      ) : (
                                        <button
                                          onClick={() => handleClick(tier)}
                                          className="mt-8 block w-full rounded-md border border-primaryColor bg-primaryColor py-2 text-center text-sm font-semibold text-white hover:bg-primaryHoverColor">
                                          {t("buy_now")} {tier.name}
                                        </button>
                                      )}
                                    </>
                                  )}
                                </div>
                                <div className="px-6 pt-6 pb-8">
                                  <h3 className="text-sm font-medium text-gray-900">
                                    {t("What's included")}
                                  </h3>
                                  <ul className="mt-6 space-y-4">
                                    <li className="flex items-start">
                                      <CheckIcon
                                        className="h-5 w-5 flex-shrink-0 text-green-500"
                                        aria-hidden="true"
                                      />
                                      <p className="ml-3 text-sm text-gray-500">
                                        {/*    {tier.assignable_task_limit > 1
                          ? includedFeatures["assignable_task_limit"]
                          : "10 tasks"} */}
                                        {tier?.credit} {t("credit")}
                                      </p>
                                    </li>
                                    {tier.has_integratons && (
                                      <li className="flex items-start">
                                        <CheckIcon
                                          className="h-5 w-5 flex-shrink-0 text-green-500"
                                          aria-hidden="true"
                                        />
                                        <p className="ml-3 text-sm text-gray-500">
                                          {t("integrations_true")}
                                        </p>
                                      </li>
                                    )}

                                    <li className="flex items-start">
                                      <CheckIcon
                                        className="h-5 w-5 flex-shrink-0 text-green-500"
                                        aria-hidden="true"
                                      />
                                      <p className="ml-3 text-sm text-gray-500">
                                        {tier.has_premium_reports
                                          ? t("has_premium_rules_true")
                                          : t("has_premium_rules_false")}
                                      </p>
                                    </li>
                                    <li className="flex items-start">
                                      <CheckIcon
                                        className="h-5 w-5 flex-shrink-0 text-green-500"
                                        aria-hidden="true"
                                      />
                                      <p className="ml-3 text-sm text-gray-500">
                                        {tier.has_premium_rules
                                          ? t("premium_rules_true")
                                          : t("premium_rules_false")}
                                      </p>
                                    </li>
                                    {/*  {tier.has_premium_support && (
                                      <li className="flex items-start">
                                        <CheckIcon
                                          className="h-5 w-5 flex-shrink-0 text-green-500"
                                          aria-hidden="true"
                                        />
                                        <p className="ml-3 text-sm text-gray-500">
                                          {t("supports_true")}
                                        </p>
                                      </li>
                                    )} */}
                                  </ul>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
