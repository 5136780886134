import { createTicket, getTickets } from "@/api/ticket";
import React, { useEffect, useRef, useState } from "react";
import RightModal from "../Modal/RightModal";
import PrimaryButton from "../buttons/PrimaryButton";
import { MdAttachFile, MdClose, MdSend } from "react-icons/md";
import { useQuery } from "react-query";
import { calculateTimeAgo } from "@/utils/dateFormatter";
import TextField from "../FormElements/TextField";
import { useToast } from "react-toastify";
import { postProjectFile } from "@/api/project";
import { formatFileName, getFileIcon } from "@/utils/formatters";
import InfoBoard from "../cards/InfoCard";

type Props = {
  open: boolean;
  onClose: () => void;
  projectStoryId?: number;
  projectId?: number;
  userId?: number;
  limit?: number;
};

export const Tickets = (props: Props) => {
  const { open, onClose, limit, projectStoryId, projectId, userId } = props;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [ticketDetailModal, setTicketDetailModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState([]);
  const [ticketId, setTicketId] = useState("");
  const [files, setFiles] = useState<File[]>([]); // Değişiklik burada
  const [messagesUpdate, setMessaesUpdate] = useState<number>();
  const {
    data: tickets,
    isLoading,
    isFetched: fetchedTickets,
    isSuccess,
    refetch: refetchTickets,
  } = useQuery(
    ["fetchTicketData", projectStoryId],
    () =>
      getTickets({
        project_story_id: projectStoryId,
      }),
    {
      retry: false,
      enabled: Boolean(projectStoryId),
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setSelectedTicket(data?.message);
      },
    }
  );

  const handleCreate = async () => {
    setLoading(true);
    const fileIds: number[] = [];

    if (files) {
      for (const file of files) {
        try {
          const response = await postProjectFile({
            project_id: Number(projectId),
            file_in: file,
          });
          fileIds.push(response.id);
        } catch (error) {
          console.error("File upload error:", error);
        }
      }
    }

    const body = {
      message: message,
      ...(tickets.id && { ticket_id: tickets.id }),
      ...(projectStoryId && { project_story_id: projectStoryId }),
      ...(projectId && { project_id: projectId }),
      ...(userId && { use_id: userId }),
      ...(fileIds && { file_ids: fileIds }),
    };

    try {
      const response = await createTicket(body);
      setLoading(false);
      setFiles([]);
      setMessage("");
      setSelectedTicket(response);
    } catch (error) {
      setLoading(false);
      console.error("Error creating task:", error);
    }
  };

  useEffect(() => {
    if (selectedTicket) {
      setMessaesUpdate(Math.random());
    }
  }, [selectedTicket]);

  const handleFileRemove = (fileName: string) => {
    setFiles(files.filter((file: any) => file.name !== fileName));
  };
  console.log(message);

  return (
    <RightModal
      open={open}
      onClose={onClose}
      size="w-3/12"
      action={
        <PrimaryButton
          disabled={limit ? tickets?.length === limit : false}
          onClick={() => setTicketDetailModal(true)}
          name="Open New Ticket "
          className="w-full"
        />
      }>
      <div className="w-full h-full flex  flex-1 flex-col justify-start items-start text-left  p-4 gap-y-3  ">
        <h1 className="text-lg font-semibold">Tickets</h1>
        {tickets?.length !== 0 ? (
          tickets?.map((i: any, index: number) => (
            <div
              key={index}
              onClick={() => {
                setTicketDetailModal(true);
                setSelectedTicket(i.messages);
                setTicketId(i.id);
              }}
              className="p-3 w-full flex justify-between align items-center border border-gray-200 rounded-lg shadow-lg cursor-pointer">
              <div className="flex flex-col">
                <span className="font-normal t text-sm">{calculateTimeAgo(i.create_date)}</span>
              </div>
              <span className="rounded-md text-xs text-white bg-secondaryColor px-2 py-1">
                {i.status}
              </span>
            </div>
          ))
        ) : (
          <InfoBoard title="No have any ticket" />
        )}
      </div>

      <RightModal
        open={ticketDetailModal}
        backgroundColor="bg-gray-200"
        size="w-4/12"
        scrollToBottom={messagesUpdate}
        onClose={() => {
          setTicketDetailModal(false);
          setSelectedTicket([]);
        }}
        action={
          <div className="flex gap-x-1 items-center justify-between w-full h-full">
            <input
              multiple
              onChange={(e) => setFiles(Array.from(e.target.files || []))}
              id="ticketFile"
              type="file"
              hidden
            />
            <PrimaryButton
              onClick={() => document.getElementById("ticketFile")?.click()}
              outline
              icon={<MdAttachFile />}
            />

            <textarea
              value={message}
              placeholder="Enter message"
              className="flex flex-1 focus:outline-none border rounded-lg h-[40px] px-2 py-1"
              onChange={(e) => setMessage(e.target.value)}
            />
            <PrimaryButton
              loading={loading}
              className="w-auto"
              icon={<MdSend />}
              onClick={() => handleCreate()}
            />
          </div>
        }>
        <div className="p-6  flex flex-col gap-y-6 ">
          <div className="flex flex-col justify-start w-full items-start">
            <div className=" border border-gray-200 rounded-md p-3  flex justify-start items-start text-left max-w-[80%] gap-x-3 bg-white">
              <div className=" break-words text-sm"> Merhana, size nasıl yardımcı olabilirm?</div>
            </div>
          </div>
          {selectedTicket &&
            selectedTicket?.map((i: any, index: number) => (
              <div
                key={index}
                className={
                  "flex flex-col justify-start w-full  " +
                  (i.user === 1 ? "items-start" : "items-end")
                }>
                <div
                  className={
                    "relative border border-gray-200 rounded-md p-3  flex justify-start items-start text-left max-w-[80%] gap-x-3 " +
                    (i.user === 1 ? "bg-white" : "bg-primaryColor text-white")
                  }>
                  <div className="flex flex-col gap-y-3">
                    <div className=" break-words text-sm">{i.message}</div>
                    {i.files?.map((file: any, index: number) => (
                      <div
                        key={index}
                        onClick={() => (window.location.href = file.file_url)}
                        className="w-full flex flex-row items-center justify-between gap-1 cursor-pointer  ">
                        <div className="flex items-center gap-1">
                          {getFileIcon(file, file.file_name, file.file_url)}
                          <span className="text-xs er ">{formatFileName(file.file_name, 24)}</span>
                        </div>
                      </div>
                    ))}{" "}
                  </div>
                  <span className="whitespace-nowrap text-sm opacity-50	">
                    {calculateTimeAgo(i.create_date)}
                  </span>
                </div>
              </div>
            ))}
        </div>

        {files && (
          <div className="fixed gap-1 bottom-[64px] flex flex-wrap w-full px-6 ">
            {files?.map((file: any) => (
              <div
                key={file.name}
                className=" w-auto flex flex-row items-center justify-between gap-1 p-2 rounded-lg border border-gray-300 bg-white shadow-md">
                <div className="flex items-center gap-1">
                  <span className="text-xs  text-primaryColor">
                    {formatFileName(file.name, 12)}
                  </span>
                </div>
                <button
                  onClick={() => handleFileRemove(file.name)}
                  className="bg-transparent border-0 text-red flex items-center">
                  <MdClose />
                </button>
              </div>
            ))}
          </div>
        )}
      </RightModal>
    </RightModal>
  );
};
