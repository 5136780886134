import AssessmentLayout from "@/components/Layout/Assessment";
import ContentBar from "@/components/ContentBar";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Breadcrumb from "@/components/breadcrumb/breadcrumb";
import SearchTermMetricsTable from "@/features/report/components/ad-reports/tables/search-terms/SearchTermMetricsTable";

const SearchTermPage = () => {
  const { t } = useTranslation();
  const { projectId, clientId, reportId, campaignId, adGroupId, adId } = useParams();

  return (
    <div>
      <AssessmentLayout>
        <ContentBar title={t("searchTermMetrics")} />
        <Breadcrumb
          reportId={reportId}
          clientId={clientId}
          projectId={projectId}
          campaignId={campaignId}
          adId={adId}
          adGroupId={adGroupId}
        />
        <div>
          <SearchTermMetricsTable tableId="searchTermMetrics" />
        </div>
      </AssessmentLayout>
    </div>
  );
};

export default SearchTermPage;
