import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { MdNavigateBefore, MdNavigateNext, MdOutlineSearch } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { IoMdDownload } from "react-icons/io";
import SecondaryButton from "../buttons/SecondaryButton";
import PrimaryButton from "../buttons/PrimaryButton";

type Props = {
  images?: any;
  handleView: (imageUrl: string) => void;
};

const DesignCardSlider = (props: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [show, setShow] = useState(false);
  const { images, handleView } = props;
  const { t } = useTranslation();

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="relative w-full max-w-2xl mx-auto ">
      <div className="relative h-[300px] bg-whiteoverflow-hidden">
        {images.map((item: any, index: number) => (
          <Transition
            key={index}
            show={index === currentIndex}
            enter="transition-opacity duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="absolute inset-0">
            <div
              className="relative flex items-center justify-center h-full w-full p-2"
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}>
              {show && (
                <div className=" absolute bottom-0  w-full h-full z-20 p-3 bg-opacity-40 bg-white flex flex-col h-full items-center justify-center  gap-2 py-2 text-sm">
                  <PrimaryButton
                    className="w-7/12"
                    onClick={() => handleView(item.image_url)}
                    name={t("view")}
                    icon={<MdOutlineSearch className="text-lg" />}
                  />
                  <PrimaryButton
                    className="w-7/12"
                    href={item.image_url}
                    target="blank"
                    name={t("download")}
                    icon={<IoMdDownload className="text-lg" />}
                  />
                </div>
              )}
              <img
                src={item.thumbnail_url}
                alt={`Slide ${index + 1}`}
                className="max-w-full max-h-full object-fit"
              />
            </div>
          </Transition>
        ))}
      </div>
      {images.length > 1 && (
        <button
          onClick={prevSlide}
          className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white text-black text-xl p-2 rounded-r-xl hover:bg-gray-200 border border-l-0 border-gray-500 shadow-lg z-20">
          <MdNavigateBefore />
        </button>
      )}
      {images.length > 1 && (
        <button
          onClick={nextSlide}
          className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-white text-black text-xl p-2 rounded-l-xl hover:bg-gray-200 border border-r-0 border-gray-500 shadow-lg z-20">
          <MdNavigateNext />
        </button>
      )}
    </div>
  );
};

export default DesignCardSlider;
