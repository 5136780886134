import PageLoading from "@/components/Loading/PageLoading";
import React, { useEffect } from "react";

export const Logout = () => {
  useEffect(() => {
    localStorage.clear();

    window.location.reload();
  }, []);
  return <PageLoading />;
};
