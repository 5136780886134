import client from "./client";

export const fetchTaskByProjectId = async (data: { projectId: number; is_done?: boolean }) => {
  const response = await client.get(`/project/${data.projectId}/tasks`, {
    params: {
      is_done: data.is_done,
    },
  });
  return response.data;
};

export const fetchDailyTasks = async (data: { projectId: number; is_done: boolean; date: any }) => {
  // due date after 1 day
  const dueEnd = new Date(data.date);
  dueEnd.setDate(dueEnd.getDate() + 1);

  const response = await client.get(`/project/${data.projectId}/tasks`, {
    params: {
      is_done: data.is_done,
      due_dtstart: new Date().toISOString(),
      due_dtend: dueEnd.toISOString(),
    },
  });
  return response.data;
};

export const fetchWeeklyTasks = async (data: { projectId: number; is_done: boolean }) => {
  const dueEnd = new Date();
  dueEnd.setDate(dueEnd.getDate() + 7);

  const response = await client.get(`/project/${data.projectId}/main-tasks`, {
    params: {
      is_done: data.is_done,
      limit: 5,
      /* due_dtstart: dueStart.toISOString(),
      due_dtend: dueEnd.toISOString(), */
    },
  });
  return response.data;
};

// fetch project stories

export const fetchProjectMainTasks = async (data: {
  project_id: number;
  limit?: number;
  status?: string;
  project_story_id?: number;
  order_by?: string;
  order_direction?: string;
}) => {
  const response = await client.get(`/project/${data.project_id}/main-tasks`, {
    params: {
      limit: data?.limit,
      status: data?.status,
      project_story_id: data?.project_story_id,
      order_by: data?.order_by,
      order_direction: data?.order_direction,
    },
  });
  return response.data;
};

export const fetchTaskDetail = async (data: { project_story_id: number }) => {
  const response = await client.get(`/project/project_story/${data.project_story_id}`);
  return response.data;
};

export const createTask = async (data: any) => {
  const response = await client.post(`/project/task/create`, data);
  return response.data;
};

export const updateTask = async (data: any, taskId: any) => {
  const response = await client.put(`/project/task/${taskId}`, data);
  return response.data;
};

export const makeAsignTask = async (data: any) => {
  const response = await client.post(`/project/task/assign`, data);
  return response.data;
};

export const makeApproveOrReject = async (data: any) => {
  const response = await client.put(`/project/task/assign`, data);
  return response.data;
};

export const makeAccept = async (data: any) => {
  const response = await client.post(`/project/task/accept`, data);
  return response.data;
};

export const makeReject = async (data: any) => {
  const response = await client.post(`/project/task/reject`, data);
  return response.data;
};

export const changeTaskStatus = async (data: any) => {
  const response = await client.put(`/story/status`, data);
  return response.data;
};
