import { useMutation, useQuery } from "react-query";

import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";
import { useState } from "react";
import { t } from "i18next";
import FacebookLogin from "react-facebook-login";
import {
  fetchTokenStatus,
  postFacebookAccessToken,
  removeFacebookConnection,
} from "@/api/facebookAuth";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { createIntegrationEvent } from "@/analytics/mixpanel";
import { userAtom } from "@/store/atoms/user.atoms";
import { useRecoilState } from "recoil";
import PopupModal from "@/components/PopupModal/PopupModal";
import WhiteButton from "@/components/buttons/WhiteButton";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";

interface Props {
  title: string;
  description: string;
  imgSrc?: string;
}

const FacebookAuthCard = (props: Props) => {
  const { projectId, isLoading: isLoadingIds } = useFetchingProjectIds();
  const [user] = useRecoilState(userAtom);
  const [openModal, setOpenModal] = useState(false);

  const { title, description } = props;

  const { mutateAsync, isLoading } = useMutation(
    "postFacebookAccessToken",
    postFacebookAccessToken,
    {
      retry: false,
    }
  );

  const {
    data: tokenStatus,
    isLoading: isLoadingTokenStatus,
    refetch,
  } = useQuery("fetchTokenStatus", () => fetchTokenStatus(projectId), {
    retry: false,
    enabled: projectId && !isLoadingIds,
    refetchOnWindowFocus: false,
  });

  const { mutateAsync: disconnectFacebook, isLoading: isLoadingRemove } = useMutation(
    "disconnectFacebook",
    () => removeFacebookConnection(projectId),
    {}
  );

  const responseFacebook = (response: any) => {
    mutateAsync({
      project_id: projectId,
      short_lived_user_access_token: response.accessToken,
    })
      .then(() => {
        createIntegrationEvent({
          connectionStatus: "success",
          integrationName: "facebook",
          userId: user?.id.toString() || "",
          email: user?.email || "",
        });
        refetch();
      })
      .catch(() => {
        alert("Facebook not connected");
      });
    //
  };

  const handleDisconnect = () => {
    disconnectFacebook(projectId).then(() => {
      window.location.reload();
    });
  };

  return (
    <div className="bg-bgCardColor max-w-7xl rounded-lg shadow p-6 ">
      <>
        {isLoadingTokenStatus ? (
          <LoadingSpinner size="w-8 h-8" />
        ) : (
          <>
            <PopupModal
              closeAction={() => setOpenModal(false)}
              title=""
              isPopup={openModal}
              width="max-w-md">
              <>
                <div>
                  {t(
                    "Please confirm you want to disconnect your Facebook account from this project"
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <DefaultButton isLoading={isLoadingRemove} onClick={() => handleDisconnect()}>
                    {t("remove")}
                  </DefaultButton>
                  <WhiteButton onClick={() => setOpenModal(false)}>{t("cancel")}</WhiteButton>
                </div>
              </>
            </PopupModal>
            <img src="/assets/icons/facebook.svg" alt="gas" width={34} className="mb-2" />
            <div className="text-lg font-semibold ">{title}</div>
            <div className="my-3 text-base ">{description}</div>
            <div className="flex justify-end pt-3">
              {tokenStatus?.status === "valid" ? (
                <>
                  <button
                    className="my-facebook-button-class-disconnect text-red-700 bg-red-100 font-medium py-2 px-4 rounded-[10px] border border-red-400
                    hover:bg-red-200 hover:border-red-500 transition-all text-sm"
                    onClick={() => setOpenModal(true)}>
                    {t("disconnect")}
                  </button>
                </>
              ) : (
                <FacebookLogin
                  textButton={`${isLoading ? "Loading..." : t("connect")}`}
                  appId="616360543215430"
                  autoLoad={false}
                  scope="pages_show_list,
                  instagram_basic,
                  instagram_content_publish,
                  pages_read_engagement,
                  pages_manage_posts,
                  public_profile"
                  fields="name,email,picture"
                  onClick={() => console.log("clicked")}
                  cssClass="my-facebook-button-class"
                  callback={responseFacebook}
                />
              )}
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default FacebookAuthCard;
