import React from "react";

const ProgressBar = () => {
  return (
    <div className="loading-bar ">
      <div className="progress-bar"></div>
    </div>
  );
};
export default ProgressBar;
