import { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import { IStory } from "@/models/assessment/stories.interface";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import TaskContent from "@/features/tasks/components/TaskContent";
import CreateStoryPopup from "@/features/create-task/components/Popup";
import { CalendarIcon, ChevronRightIcon, CreditCardIcon } from "@heroicons/react/24/outline";
import { listenButtonClickedEvent, taskViewEvent } from "@/analytics/mixpanel";
import { userAtom } from "@/store/atoms/user.atoms";
import { useRecoilState } from "recoil";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { UsersIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import FilterInput from "@/features/tasks/components/FilterInput";
import { USER_TYPE, USER_TYPE_MVP2 } from "@/models/userType";
import PricingModal from "@/features/mvp-getting-started/components/pricingModal";
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";

interface Props {
  data: any;
  refetch: any;
}

const TaskPage = (props: Props) => {
  const { data, refetch } = props;
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedStory, setSelectedStory] = useState<IStory | null>(null);
  const [projectStoryId, setProjectStoryId] = useState("");
  const contentRef = useRef<any>();
  const [loadingRefetch] = useState(false);
  const [partnerName, setPartnerName] = useState<string | null>(null);
  const [user] = useRecoilState(userAtom);
  const [project] = useRecoilState(projectInfo);
  const [openPricing, setOpenPricing] = useState(false);

  const [openCreateTaskModal, setOpenCreateTaskModal] = useState(false);

  const [filterSettings, setFilterSettings] = useState({
    search: "",
    sort: false,
  });

  const { totalCredit, totalUsedCredit, setRefetchEnable } = useFetchingProjectIds();

  useEffect(() => {
    setRefetchEnable(true);
  }, []);

  const handleSelectTask = (story: any) => {
    setSelectedStory(story);
    setProjectStoryId(story.project_story_id);

    const getPaths = JSON.parse(localStorage.getItem("paths") as any);
    taskViewEvent({
      taskId: story.id,
      taskName: story.story_name + " - " + story.title,
      taskViewDone: true,
      userId: user?.id.toString() || "",
      email: user?.email || "",
      previousPage: getPaths?.prevPath || "",
    });

    setOpen(true);
    setPartnerName(story.partner_first_name);
  };

  const [unreadMessageItems, setUnreadMessageItems] = useState<any>(null);

  useEffect(() => {
    if (data?.items) {
      // return items that has unread message
      const t = data?.items?.filter((item: any) => {
        return item?.data?.some((task: any) => task?.unread_message_count > 0);
      });

      setUnreadMessageItems(t);
    }
  }, [data]);

  useEffect(() => {
    if (selectedStory) {
      const newData = data.items.find((item: any) => item.project_story_id === projectStoryId);
      if (newData) {
        setSelectedStory(newData);
      }
    }
  }, [data]);

  return (
    <div>
      {loadingRefetch || !data ? (
        <div className="flex h-[calc(100vh-2rem)] justify-center items-center ">
          <LoadingSpinner size="w-8 h-8" />
        </div>
      ) : (
        <>
          <div className="border-b border-gray-300 text-lg py-4 flex justify-between items-center font-medium">
            <div>{t("inProgress")}</div>
          </div>
          <div className="flex flex-col gap-2 md:flex-row justify-between pt-5">
            <DefaultButton
              className="md:w-80 bg-indigo-600 hover:bg-indigo-700 h-fit w-full"
              onClick={() => {
                if (
                  (user?.role === USER_TYPE || user?.role === USER_TYPE_MVP2) &&
                  project.pricingPlan.id === 1
                ) {
                  setOpenPricing(true);
                } else {
                  setOpenCreateTaskModal(true);
                  listenButtonClickedEvent({
                    buttonName: "Create a new task(Tasks)",
                    email: user?.email || "",
                    userId: user?.id.toString() || "",
                  });
                }
              }}>
              + {t("createNewTask")}
            </DefaultButton>

            <FilterInput filterSettings={filterSettings} setFilterSettings={setFilterSettings} />
          </div>
          <div className="flex gap-2 items-center text-sm text-gray-600 p-1">
            <CreditCardIcon className="h-5 w-5 text-gray-400" />
            {totalCredit - totalUsedCredit} {t("creditsLeft")}{" "}
          </div>
          <div className="flex xl:gap-5 ">
            <div className=" flex flex-col gap-4 w-full ">
              <div className="h-[calc(100vh-8rem)] overflow-y-auto overflow-x-visible p-2 mb-10 ">
                {data?.items
                  .filter((item: any) => {
                    if (filterSettings.search) {
                      return (
                        item?.sub_title
                          ?.toLowerCase()
                          .includes(filterSettings.search.toLowerCase()) ||
                        item?.story_name
                          ?.toLowerCase()
                          .includes(filterSettings.search.toLowerCase())
                      );
                    }
                    return true;
                  })
                  .sort((a: any, b: any) => {
                    if (filterSettings.sort) {
                      return a?.create_date > b?.create_date ? 1 : -1;
                    } else {
                      return a?.create_date < b?.create_date ? 1 : -1;
                    }
                  })
                  .map((item: any, key: any) => {
                    const unread_message_count = unreadMessageItems
                      ?.find((i: any) => i?.project_story_id === item?.project_story_id)
                      ?.tasks?.reduce((a: any, b: any) => a + b?.unread_message_count, 0);
                    return (
                      <div
                        key={key}
                        onClick={() => handleSelectTask(item)}
                        className="overflow-hidden bg-white border-b cursor-pointer ">
                        <div className="block hover:bg-gray-50 ">
                          <div className="flex items-center px-4 py-4 sm:px-6 relative">
                            {unread_message_count > 0 && (
                              <span className="w-4 h-4 bg-red-500 rounded-br text-xs text-white text-center absolute top-0 left-0">
                                {unread_message_count}
                              </span>
                            )}
                            <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                              <div className="truncate">
                                <div className="flex text-sm">
                                  <p className="truncate font-medium text-indigo-600">
                                    {(item.sub_title && item.sub_title.replace("&", "and")) ||
                                      (item.story_name && item.story_name.replace("&", "and"))}
                                  </p>
                                </div>
                                <div className="mt-2 flex">
                                  <div className="flex items-center text-sm text-gray-500">
                                    {item?.tasks?.find((t: any) => t.partner_first_name) && (
                                      <UsersIcon
                                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    )}

                                    <p>
                                      {item?.tasks?.map(
                                        (t: any, index: any) =>
                                          t.partner_first_name && (
                                            <span className="mr-2">
                                              {t.partner_first_name}
                                              {index !== item?.tasks?.length - 1 && ","}
                                            </span>
                                          )
                                      )}
                                    </p>
                                  </div>
                                  <div className="flex items-center text-sm text-gray-500">
                                    {item?.label_name && (
                                      <span className="">
                                        {item?.label_icon && (
                                          <img src={item?.label_icon} alt="" width={14} />
                                        )}
                                        <span
                                          style={{
                                            backgroundColor: item?.label_color,
                                          }}
                                          className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-gray-500">
                                          {item?.label_title}
                                        </span>
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                                <div className="flex justify-end -space-x-1 overflow-hidden h-6 ">
                                  {item.rule_name && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-50 text-gray-500">
                                      {item.rule_name}
                                    </div>
                                  )}
                                  {item?.tasks?.map(
                                    (t: any) =>
                                      t.partner_avatar && (
                                        <img
                                          className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                                          src={t.partner_avatar}
                                          alt=""
                                        />
                                      )
                                  )}
                                </div>
                                <div className="mt-2 flex justify-end">
                                  <div className="flex items-center text-sm text-gray-500">
                                    <CalendarIcon
                                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                      aria-hidden="true"
                                    />
                                    <div>
                                      <time>
                                        {item.due_date === null ? (
                                          <div className="flex gap-2">
                                            <div>{t("createDate")}:</div>
                                            {new Date(item.create_date).toLocaleString(
                                              i18n.language,
                                              {
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                                hour: "numeric",
                                                minute: "numeric",
                                              }
                                            )}
                                          </div>
                                        ) : (
                                          <div className="flex gap-2">
                                            <div>{t("dueDate")}:</div>
                                            {new Date(item.due_date).toLocaleString(i18n.language, {
                                              year: "numeric",
                                              month: "long",
                                              day: "numeric",
                                              hour: "numeric",
                                              minute: "numeric",
                                            })}
                                          </div>
                                        )}
                                      </time>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="ml-5 flex-shrink-0">
                              <ChevronRightIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className="hidden" ref={contentRef}>
              {selectedStory && open && (
                <TaskContent
                  partnerName={partnerName}
                  projectId={project.id}
                  projectStoryId={projectStoryId}
                  refetch={refetch}
                  open={open}
                  setOpen={setOpen}
                  selectedStory={selectedStory}
                  setSelectedStory={setSelectedStory}
                  hasAssignTask={project.hasAssignTask}
                  tasks={data}
                  setUnreadMessageItems={setUnreadMessageItems}
                  unreadMessageItems={unreadMessageItems}
                />
              )}
            </div>
          </div>
        </>
      )}
      <>
        {openCreateTaskModal && (
          <CreateStoryPopup
            modalState={openCreateTaskModal}
            setModalState={setOpenCreateTaskModal}
            refetch={refetch}
          />
        )}
      </>
      {openPricing && <PricingModal open={openPricing} setOpen={setOpenPricing} />}
    </div>
  );
};

export default TaskPage;
