import React from "react";
import { Link } from "react-router-dom";
import { CircleButtonType } from "./buttons.interface";
import LoadingSpinner from "../UI/LoadingSpinner";

const CircleButton = (props: CircleButtonType) => {
  const {
    count,
    onClick,
    icon,
    loading,
    disabled,
    className,
    to,
    href,
    target,
    primary,
    secondary,
  } = props;

  const buttonClass = primary
    ? "relative h-button w-button rounded-full flex text-gray-600 gap-2 items-center justify-center bg-primaryColor text-white  border border-primaryHoverColor  "
    : secondary
    ? "relative h-button w-button rounded-full flex text-gray-600 gap-2 items-center justify-center bg-secondaryColor text-white  border border-secondaryHoverColor  "
    : "relative h-button w-button rounded-full flex text-gray-600 gap-2 items-center justify-center bg-white  border border-gray-400  ";

  return to ? (
    <Link to={to} className={buttonClass + className}>
      {icon && icon}
    </Link>
  ) : href ? (
    <a href={href} target={target} className={buttonClass + className}>
      {icon && icon}
    </a>
  ) : (
    <button
      disabled={loading || disabled}
      onClick={onClick}
      className={(disabled ? " opacity-60" : " hover:shadow-md  ") + buttonClass + className}>
      {count && count !== 0 && (
        <div className="absolute -bottom-1 -right-1 text-xs bg-red w-4 h-4 rounded-full text-white">
          {count}
        </div>
      )}
      {loading ? <LoadingSpinner size="w-4 h-4" /> : icon && icon}
    </button>
  );
};

export default CircleButton;
