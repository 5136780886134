import React, { useState } from "react";

type Props = {
  type?: string;
  label?: string;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  icon?: any;
  className?: string;
  disabled?: boolean;
  component?: React.ReactNode; // Corrected prop type for component
};

const TextField = (props: Props) => {
  const {
    type = "text",
    label,
    value,
    defaultValue,
    placeholder,
    onChange,
    icon,
    className,
    disabled,
    component,
  } = props;
  const [focused, setFocused] = useState(false);

  return (
    <div className={"flex flex-col items-start justify-start gap-2 " + className}>
      {label && <label className="text-sm font-semibold">{label} </label>}
      <div
        className={
          "h-input w-full flex items-center rounded-md border   bg-white px-2 " +
          (focused ? "border-secondaryHoverColor " : "border-gray-300 ")
        }>
        {icon && <div className="h-full flex items-center  text-gray-500 text-xl ">{icon}</div>}
        {component && <div className="h-full flex items-center">{component}</div>}

        <input
          type={type}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          className=" flex-1 h-full focus:outline-none text-sm"
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
        />
      </div>
    </div>
  );
};

export default TextField;
