import LoadingSpinner from "../UI/LoadingSpinner";

const PageLoading = () => {
  return (
    <div className="w-full py-20 flex items-center justify-center">
      <LoadingSpinner />
    </div>
  );
};

export default PageLoading;
