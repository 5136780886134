import React, { useState, useEffect } from "react";
import PhoneCodeSelect from "./PhoneCodeSelect";
import TextField from "./TextField";

interface Options {
  id: any;
  name: string;
  phoneCode: string; // Corrected to match the PhoneCodeSelect interface
  avatar: string;
}
type Props = {
  label: string;
  onChange: (value: string) => void;
};

const PhoneField: React.FC<Props> = ({ label, onChange }: Props) => {
  const [countries, setCountries] = useState<Options[]>([]);
  const [defaultCountry, setDefaultCountry] = useState<Options | null>(null);
  const [phoneCode, setPhoneCode] = useState<string>("");

  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        const countryData = data.map((country: any) => ({
          id: country.name.common,
          name: country.name.common,
          phoneCode: country.idd.root + (country.idd.suffixes ? country.idd.suffixes[0] : ""),
          avatar: country.flags.png,
        }));

        // Set countries and default country using Options type
        setCountries(countryData);

        const turkey = countryData.find((country: Options) => country.name === "Turkey");
        if (turkey) {
          setDefaultCountry(turkey);
          setPhoneCode(turkey.phoneCode); // Set phoneCode to phoneCode, not name
        }
      })
      .catch((error) => console.error("Error fetching country data:", error));
  }, []);

  const handleCountryChange = (selected: Options | null) => {
    if (selected) {
      setPhoneCode(selected.phoneCode); // Set phoneCode to phoneCode, not name
    }
  };

  const handleChange = (value: string) => {
    const number: any = phoneCode + value;
    onChange(number);
  };

  return (
    <div>
      <TextField
        type="number"
        label={label}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
        component={
          <PhoneCodeSelect
            handleChange={handleCountryChange}
            data={countries}
            defaultValue={defaultCountry}
          />
        }
        placeholder="Phone number"
      />
    </div>
  );
};

export default PhoneField;
