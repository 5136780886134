import React, { useState } from "react";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import { useRecoilState } from "recoil";
import PageLoading from "@/components/Loading/PageLoading";
import FirstStep from "./Steps/FirstStep";
import SecondStep from "./Steps/SecondStep";
import { createOnboardingData } from "@/api/auth";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

const OnboardinPage = () => {
  const [project] = useRecoilState(projectInfo);
  const projectId = project.id;
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const step = searchParams.get("step");

  console.log(step);

  const handleSubmit = async (data: any) => {
    setLoading(true);
    try {
      await createOnboardingData(projectId, data);
      setLoading(false);
      setCurrentStep(2);
      navigate("/onboarding/?step=integrations");
    } catch (error) {
      console.error("Error creating task:", error);
      setLoading(false);
    }
  };

  return (
    <div>
      {!project ? (
        <PageLoading />
      ) : (
        <div className="min-h-screen w-full flex justify-between  relative">
          <div className=" w-4/12 h-screen bg-gradient-to-b from-black to-secondaryHoverColor  flex flex-col">
            <div className="w-full h-[10%] flex items-center px-8">
              <img className="h-8 w-auto" src="/assets/logos/mikrolo.svg" alt="Your Company" />
            </div>
            <div className="w-full  h-[80%] flex flex-col justify-center items-start px-20">
              {currentStep === 1 ? (
                <>
                  <h2 className="text-white text-[2rem] font-[600]">Welcome</h2>
                  <span className="text-white text-sm">
                    Finish setting up your account to get started.
                  </span>
                </>
              ) : (
                <>
                  <h2 className="text-white text-[2rem] font-[600]">What accounts do you have?</h2>
                  <span className="text-white text-sm">
                    Connect your accounts to get the most out of your SEO and PPC campaigns.
                  </span>
                </>
              )}
            </div>
            <div className="w-full h-[10%] flex items-center justify-center gap-2">
              <div
                className={
                  "rounded-xl  bg-yellow " + (currentStep === 1 ? "w-8 h-3 " : "w-3 h-3 opacity-70")
                }
              />
              <div
                className={
                  "rounded-xl  bg-yellow " + (currentStep === 2 ? "w-8 h-3 " : "w-3 h-3 opacity-70")
                }
              />
            </div>
          </div>
          <div className="w-8/12 px-40 py-10 h-screen overflow-auto ">
            {step !== "integrations" ? (
              <FirstStep loading={loading} projectId={project.id} onSubmit={handleSubmit} />
            ) : (
              <SecondStep setCurrentStep={setCurrentStep} projectId={project.id} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default OnboardinPage;
