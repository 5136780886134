import AssessmentLayout from "@/components/Layout/Assessment";
import ContentBar from "@/components/ContentBar";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Breadcrumb from "@/components/breadcrumb/breadcrumb";
import AdGroupMetricsTable from "@/features/report/components/ad-reports/tables/ad-group/AdGroupMetricsTable";
import AdGroupKeywordsTable from "@/features/report/components/ad-reports/tables/ad-group/AdGroupKeywordsTable";
import AdGroupAdTable from "@/features/report/components/ad-reports/tables/ad-group/AdGroupAdsTable";

const AdGroupPage = () => {
  const { t } = useTranslation();
  const { projectId, clientId, reportId, campaignId, adGroupId } = useParams();

  return (
    <div>
      <AssessmentLayout>
        <ContentBar title={t("adGroupMetrics")} />
        <Breadcrumb
          reportId={reportId}
          clientId={clientId}
          projectId={projectId}
          campaignId={campaignId}
        />
        <div>
          <AdGroupMetricsTable tableId="adGroupMetrics" />
          <AdGroupAdTable tableId="adGroupAdsTable" />
          <AdGroupKeywordsTable tableId="adGroupKeywords" />
        </div>
      </AssessmentLayout>
    </div>
  );
};

export default AdGroupPage;
