import React from "react";

interface Status {
  id: string;
  value: string;
}

type Props = {
  status: Status;
  className?: string;
};

const StatusLabel = (props: Props) => {
  const { status, className } = props;

  const statusId = status.id;

  const statusColor =
    statusId === "notstarted"
      ? "bg-notstarted text-notstarted"
      : statusId === "inprogress"
      ? "bg-inprogress text-inprogress"
      : statusId === "inreview"
      ? "bg-inreview text-inreview"
      : statusId === "pending"
      ? "bg-pending text-pending"
      : statusId === "accepted"
      ? "bg-accepted text-accepted"
      : statusId === "completed"
      ? "bg-completed text-completed"
      : statusId === "cancelled"
      ? "bg-cancelled text-cancelled"
      : "bg-gray-400 text-black";
  return (
    <div className={" inline-flex justify-start items-center " + className}>
      <span
        className={
          "flex justify-center text-sm w-full px-2 py-1 rounded-xl bg-opacity-20  " + statusColor
        }>
        {status.value}
      </span>
    </div>
  );
};
export default StatusLabel;
