import React from "react";
import Sidebar from "./Sidebar";

type Props = {
  children?: React.ReactNode;
  searchBar?: boolean;
};

const AssessmentLayout = (props: Props) => {
  const { searchBar = true } = props;
  return (
    <div>
      <Sidebar searchBar={searchBar}>{props.children}</Sidebar>
    </div>
  );
};

export default AssessmentLayout;
