import { Divider } from "@/components/Divider";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import { userAtom } from "@/store/atoms/user.atoms";
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { fetchStoryCategories, fetchStoryTemplates } from "@/api/stories";
import Select from "@/components/FormElements/Select";
import FileUploader from "@/components/FormElements/FileUploader";
import { createTask } from "@/api/task";
import { postProjectFile } from "@/api/project";
import RightModal from "@/components/Modal/RightModal";
import { assigneeOptions } from "@/utils/globalOptions";

type Props = {
  projectId: any;
  size?: string;
  open: boolean;
  onClose: () => void;
  reFetch?: () => void;
};

interface FormData {
  project_id: number;
  title: string;
  description: string;
  category: string;
  due_date: string;
  assignee: number;
}

const CreateTask = (props: Props) => {
  const { open, size, projectId, onClose, reFetch } = props;
  const [loading, setLoading] = useState(false);
  const [project] = useRecoilState(projectInfo);
  const [user] = useRecoilState(userAtom);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [formData, setFormData] = useState<FormData>({
    project_id: 0,
    title: "",
    description: "",
    category: "seo",
    due_date: "",
    assignee: 0,
  });
  const [files, setFiles] = useState<any>();

  const {
    data: categories,
    isLoading: categoriesLoading,
    isFetched: isFetchedCategories,
  } = useQuery("fetchCategories", () => fetchStoryCategories(), {
    retry: false,
    cacheTime: 0,
    refetchOnWindowFocus: true,
  });

  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(() => {
    if (isFetchedCategories && categories) {
      const data = categories.items.map((i: any) => ({
        id: i.id,
        value: i.value,
      }));
      setCategoryOptions(data);
    }
  }, [categoriesLoading]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [formData.title]);

  const handleChange = (key: string, value: any) => {
    console.log(value);
    if (key === "due_date") {
      const isoDate = new Date(value).toISOString();
      setFormData((prev) => ({
        ...prev,
        [key]: isoDate,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const fileIds: number[] = [];
    if (files) {
      for (const file of files) {
        try {
          const response = await postProjectFile({
            project_id: projectId,
            file_in: file,
          });
          fileIds.push(response.id);
        } catch (error) {
          console.error("File upload error:", error);
        }
      }
    }

    const formDataWithFiles = {
      ...formData,
      project_id: projectId,
      file_ids: fileIds,
    };

    try {
      const response = await createTask(formDataWithFiles);
      console.log("Task created successfully:", response);
      setLoading(false);
      onClose();
      if (reFetch) {
        reFetch();
      }
    } catch (error) {
      setLoading(false);
      console.error("Error creating task:", error);
    }
  };

  const isDisabled = () => {
    if (formData?.due_date && formData?.title) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <RightModal
      open={open}
      onClose={onClose}
      size={size}
      action={
        <PrimaryButton
          disabled={isDisabled()}
          loading={loading}
          onClick={() => handleSubmit()}
          name="Create"
          className="  w-full"
        />
      }>
      <div className="flex flex-col w-full ">
        <div className="w-full flex  flex-1 flex-col justify-start items-start text-left  px-6 pt-10 gap-y-3  ">
          <textarea
            ref={textareaRef}
            className="w-full border-b border-gray-200 text-2xl font-[500] py-2 focus:outline-0 resize-none overflow-hidden"
            placeholder="Enter task title"
            value={formData?.title}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              handleChange("title", e.target.value)
            }
            rows={1}
          />
          <div className="w-full flex-col">
            <div className="w-full grid grid-cols-10 gap-3 text-sm ">
              <div className="col-span-4 font-[500] ">Category</div>
              <div className="col-span-6">
                <Select
                  placeholder="Enter"
                  basic
                  data={categoryOptions}
                  onChange={(selected) => handleChange("category", selected)}
                />
              </div>
              <div className="col-span-4  font-[500]">Due Date</div>
              <div className="col-span-6">
                <input
                  onChange={(e) => handleChange("due_date", e.target.value)}
                  className="w-full focus:outline-0"
                  type="date"
                  id="date-picker"
                />
              </div>
              <div className="col-span-4  font-[500]">Assignee</div>
              <div className="col-span-6">
                <Select
                  placeholder="Enter"
                  basic
                  data={assigneeOptions}
                  onChange={(selected) => handleChange("assignee", selected)}
                />
              </div>
            </div>
          </div>
          <Divider />
          <textarea
            className="w-full border-b border-gray-200 text-sm  py-2 focus:outline-0 resize-none overflow-hidden"
            placeholder="Write your brief here."
            rows={6}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              handleChange("description", e.target.value)
            }
          />
          <FileUploader onSelect={(files) => setFiles(files)} />
        </div>
      </div>
    </RightModal>
  );
};

export default CreateTask;
