export const en = {
  suggestedStrategies: "Suggested Strategies",
  activeStrategies: "Active Strategies",

  createIntegration: "Create Integration",
  backToIntegrationsPage: "Back to Integrations Page",
  day: "day",
  strategyName: "Strategy Name",
  strategyLabel: "Strategy Label",
  operatingTime: "Operating Time",
  searchInRules: "Search in rules",
  creditsLeft: "Credits left",
  allYourAdditionalRequirements: "All your additional requirements",
  completeSolutionsForAllYourContentNeedsAndDevelopment:
    "Complete solutions for all your content needs and development",
  comprehensiveSolutionsForAllYourWebsiteNeedsAndDevelopment:
    "Comprehensive solutions for all your website needs and development",
  completeSolutionsForAllYourMarketingNeedsAndDevelopment:
    "Complete solutions for all your marketing needs and development",
  comprehensiveSolutionsForAllYourAdvertisingNeedsAndDevelopment:
    "Comprehensive solutions for all your advertising needs and development",
  Others: "Others",
  ContentMarketing: "Content Marketing",
  SEO: "SEO",
  SocialMedia: "Social Media",
  Advertising: "Advertising",
  theAnswerHasBeenSuccessfullyReproduced: "The answer has been successfully reproduced.",
  thereWasAnErrorReproducingTheAnswerTryAgainLater:
    "There was an error reproducing the answer. Try again later.",
  leaveYourFeedbackHere: "Leave your feedback here...",
  wouldYouLikeToLeaveAFeedback: "Would you like to leave a feedback?",
  areYouSureYouWantToReproduceAnswer: "Are you sure you want to reproduce answer?",
  reproduceAnswer: "Reproduce answer",
  autoPilotInfoText:
    "The rules used in our autopilot feature are constantly being updated and improved. Through the addition of new rules to the system day by day, we aim to enhance your user experience even further. However, there may be special rules you wish to be added that are not currently included in our system. If there are any specific rules you would like us to add to our autopilot feature, please do not hesitate to contact us.",
  recaptchaFailed: "Recaptcha verification failed, please contact support.",
  reportInfoText:
    "Extracting data from Google Ads and Google Analytics can take time depending on the volume of data and network congestion. This process is necessary to ensure the accuracy and reliability of the data presented in reports. Please wait for the data synchronization to complete to guarantee that your reports reflect the most current and accurate data. The duration of the process may vary depending on the amount of data being extracted. Thank you for your understanding and patience.",
  adsPropertiesPageSuccess:
    "Your Google Ads account linking is complete, and we've begun gathering your data. Please be aware that this process may require a bit of time. Rest assured, we'll notify you via email as soon as your reports are prepared and available for review.",
  goBack: "Go back",
  analyticsPropertiesPageSuccess:
    "Your Google Analytics account linking is complete, and we've begun gathering your data. Please be aware that this process may require a bit of time. Rest assured, we'll notify you via email as soon as your reports are prepared and available for review.",
  toCreateReportPleaseConnectAccounts: "To create a report, please connect your accounts.",
  selectMetrics: "Select Metrics",
  sampleReportName: "Sample Report Name",
  pleaseEnterReportName: "Please enter report name",
  reportTemplates: "Report Templates",
  reportName: "Report Name",
  metrics: "Metrics",
  createReportStarted: "Report generation has started!",
  createReport: "Create Report",
  executionHistoryNotFound: "Execution history not found.",
  noAssetsFoundForSettingException: "No assets found for setting exception.",
  autoPilotItemCreated: "Auto pilot item created!",
  atLeastSelectAnOption: "Please select at least one option.",
  keepThisRuleOutFor: "Keep this rule out for:",
  informationStopAutopilot: "Information about auto pilot",
  selectAnActionPauseRemove: "Select an action (Pause / Remove)",
  pause: "Pause",
  autopilotSetPopupDescription:
    "Get started by filling in the information below to set your auto pilot.",
  autopilotSetPopupTitle: "Set Autopilot",
  autopilotStopPopupDescription: "Would you like to stop auto pilot?",
  autopilotStopPopupTitle: "Are you sure to stop auto pilot?",
  noExecutionsFound: "No executions found.",
  adGroupKeyword: "Ad Group Keyword",
  adGroupName: "Ad Group Name",
  action: "Action",
  actionIkiNokta: "Action",
  campaignName: "Campaign Name",
  executionDate: "Execution Date",
  setAutoPilot: "Set Auto Pilot",
  stopAutoPilot: "Stop Auto Pilot",
  seeExecutionHistory: "See Execution History",
  executionHistoryTitle: "Execution History",
  autopilot: "Auto Pilot",
  autopilottitle: "Google Ads Auto Pilot",
  autopilotreporttitle: "Rules",
  autopilotreportdescription:
    "This table is a control tool designed to analyze the compliance of your advertising campaigns within a more specific and detailed set of rules and to facilitate improvements when necessary. This table is tailored to determine if each campaign meets its unique requirements and to plan necessary actions for optimization.",
  rule: "Rule",
  execution: "Execution",
  "Welcome to React": "Welcome to React and react-i18next",
  turkish: "Turkish",
  english: "English",
  dashboard: "Dashboard",
  stories: "Stories",
  deliverables: "Deliverables",
  reports: "Reports",
  calendar: "Content Calendar",
  activeStories: "Active stories",
  kpiAnalysis: "KPI Analysis",
  noCurentData: "There are currently no data.",
  inProgress: "In progress",
  finished: "Finished",
  logout: "Logout",
  adsManagement: "Ads Management",
  adsAnalyses: "Ads Analyses",
  campaigns: "Campaigns",
  displayedProject: "Displayed project",
  viewAllStories: "View all stories",
  showMore: "Show more",
  backToActiveStories: "Back to active stories",
  detail: "Details",
  errorDetail: "Issue details",
  totalErrors: "Total issues",
  critical: "Critical",
  high: "High",
  moderate: "Moderate",
  low: "Low",
  errorType: "Issue Summary",
  severity: "Severity",
  count: "Count",
  scope: "Scope",
  howTo: "How to",
  activities: "Activities",
  dueDate: "Due date",
  partnerName: "Partner name",
  status: "Status",
  comments: "Comments",
  sendComment: "Send comment",
  writeComment: "Add a comment",
  dragDropYourFiles: "Drag&Drop your files or",
  finishTheStory: "Finish the story",
  previous: "Previous",
  next: "Next",
  markAsDone: "Mark as done",
  assignToMikrolod: "Assign to Partner",
  assignedToMikrolo: "Assigned to Partner",
  assignedPerson: "Assigned person",
  aboutMe: "About me",
  browse: "Browse",
  hi: "Hi",
  criticalSeverity: "Critical severity",
  highSeverity: "High severity",
  moderateSeverity: "Moderate severity",
  lowSeverity: "Low severity",
  backToAdsManagement: "Back to Ads management",
  editCalendar: "Edit calendar",
  seeAssessmentReport: "See assessment report",
  seeContentCalendar: "See content calendar",
  backToStories: "Back to stories",
  backToFinishedStories: "Back to finished stories",
  lookingPartner: "Looking for a partner...",
  allTaskWereDone: "All tasks were done",
  youNeedToCompleteToFinishTheStory: "You need to complete all tasks to finish the story",
  all: "All",
  login: "Login",
  email: "E-mail",
  password: "Password",
  forgotPassword: "Forgot your password?",
  enterYourEmail: "Enter your e-mail",
  enterYourPassword: "Enter your password",
  logIn: "Log In",
  dontHaveAnAccount: "Don't have an account?",
  registerNow: "Register now",
  signup: "Sign Up",
  fullName: "Full name",
  phone: "Phone",
  bySigningUpYouAgreeToOur: "By signing up you agree to our",
  termsOfUse: "terms and conditions",
  and: "and",
  privacyPolicy: "privacy policy",
  aldreadyHaveAnAccount: "Already have an account?",
  signUp: "Sign Up",
  enterYourFullName: "Enter your full name",
  enterYourPhone: "Enter your phone",
  securityReason:
    "Enter the email address you used to join and we'll send you instructions to reset your password.  For security reasons, we do NOT store your password. Therefore, you can rest assured that we will never send your password via email.",
  send: "Send",
  save: "Save",
  selectCountry: "Select country",
  selectPartner: "Select partner",
  enterYourKeywords: "Enter your keywords",
  enterYourCompetitors: "Enter your competitors",
  youCanAddMaxium5Keywords: "You can add maximum 5 keywords",
  youCanAddMaxium5Competitors: "You can add maximum 5 competitors",
  allTasksWereDone: "All tasks were done",
  okItems: "OK items",
  new: "New",
  storyDetail: "Story Details",
  yourAssessmentReportWillBeReady: "Your assessment report will be ready in up to 5 minutes.",
  domainName: "Domain name",
  thereIsNoNotification: "There is no notification",
  createStory: "Create Story",
  storyName: "Story Name",
  selectTemplate: "Select Template",
  selectTask: "Select Task",
  fillTheForm: "Fill the form",
  writeYourStoryName: "Write your story name",
  writeYourComments: "Write your comments",
  yes: "Yes",
  no: "No",
  pleaseSelectAtLeastOneTask: "Please select at least one task",
  pleaseFillAllTheFields: "Please fill all the fields",
  somethingWentWrong: "Something went wrong",
  backToReports: "Back to reports",
  noUnreadMessages: "No unread messages",
  dueDateDescription: "The date when the task should be completed",
  back: "Back",
  done: "Done",
  thereIsNoStory: "There is no story card",
  pleaseSelectTemplate: "Please select a template",
  dontShowAgain: "Don't show again",
  unreadMessage: "Unread messages",
  current: "Current",
  potential: "Potential",
  conversion: "Conversion",
  monthlyTraffic: "monthly traffic",
  rules: "Rules",
  allRules: "All rules",
  filterRules: "Filter rules",
  phone_number_must_be_10_digits: "Phone number must be 10 digits",
  full_name_is_required: "Full name is required",
  phone_number_is_required: "Phone number is required",
  email_is_required: "Email is required",
  password_is_required: "Password is required",
  password_must_be_at_least_6_characters: "Password must be at least 6 characters",
  password_must_be_less_than_20_characters: "Password must be less than 20 characters",
  password_must_contain: "Password must contain at least one letter and one number",
  yup_required: "This field is required",
  task: "Task",
  tasks_plural: "Tasks",
  waitingDescription:
    "Firstly you need to complete the brief story. Then you can generate the report.",
  assessmentReport: "Assessment Report",
  assessmentReportDescription: "Authority Score , Page load time , Organic traffic",
  sec: "sec",
  publishDate: "Publish date",
  contentDetails: "Content details",
  signInToYourAccount: "Sign in",
  integrations: "Integrations",
  home: "Home",
  messages: "Messages",
  myTasks: "My tasks",
  backToTasks: "Back to tasks",
  first_name_is_required: "First name is required",
  last_name_is_required: "Last name is required",
  country_is_required: "Country is required",
  street_is_required: "Street is required",
  city_is_required: "City is required",
  zip_is_required: "Zip is required",
  state_is_required: "State is required",
  firstname_is_required: "First name is required",
  lastname_is_required: "Last name is required",
  firstname: "First name",
  lastname: "Last name",
  enteryourfirstname: "Enter first name",
  enteryourlastname: "Enter last name",
  has_premium_rules_false: "Basic Reports",
  has_premium_rules_true: "Basic + Premium Reports",
  premium_rules_false: "Basic Rules",
  premium_rules_true: "Basic + Premium Rules",
  integrations_true: "Integrations",
  supports_true: "7/24 Support",
  finishTask: "Finish task",
  finishAllTasks: "Finish all tasks",
  completed: "Completed",
  selectBy: "Select by",
  businessInfo: "Business info",
  targetCountry: "Target country",
  keywords: "Keywords",
  competitors: "Competitors",
  addYourBusinessData: "Add your business data",
  yourBusinessContactDetails: "Your business contact details",
  businessName: "Business name",
  businessWebsite: "Business website",
  sector: "Sector",
  finish: "Finish",
  selectYourTargetCountry: "Select your target country",
  pleaseSelectTheCountryYourBusiness:
    "Please select the country your business located in or mainly focus on. You will see the analyzes according to country you have selected.",
  addYourKeywords: "Add your keywords",
  shareWithUsTheKeywordsYouThink:
    "Share with us the keywords you think are relevant to your business. It tells about the content of your web site and we will help you to improve your SEO performance.",
  addYourCompetitors: "Add your competitors",
  dontLetYourCompetitors:
    "Don’t let your competitors to get ahead of you! Share with us a few businesses' websites you see as competitors. So, you will see your position among your competitors.",
  add: "Add",
  required: "Required",
  bookADemo: "Book a Demo",
  buy_now: "Buy",
  profile: "Profile",
  change: "Change",
  country: "Country",
  streetAddress: "Street address",
  city: "City",
  stateProvince: "State / Province",
  zipCode: "Zip code",
  cancel: "Cancel",
  photo: "Photo",
  personalInformation: "Personal information",
  projects: "Projects",
  subscriptinPlans: "Subscription Plans",
  billing: "Billing",
  connect: "Connect",
  disconnect: "Disconnect",
  sample: "Sample",
  viewProfile: "View profile",
  comingSoon: "Coming soon",
  profilePhotoEdit: "Profile photo edit",
  remove: "Remove",
  createNewTask: "Create new task",
  noTaskSelected: "No task selected",
  pleaseSelectOneTaskToRead: "Please select one task to read.",
  noAssigned: "Not assigned",
  waitingForThePartner: "Waiting for the partner",
  label: "Label",
  assign: "Assign",
  assignee: "Assignee",
  noLabel: "No label",
  createTask: "Create task",
  noMessageSelected: "No message selected",
  pleaseSelectOneMessageToRead: "Please select one message to read.",
  searchByName: "Search by name",
  verifyAccount: "Verify account",
  changePassword: "Change Password",
  newPassword: "New Password",
  confirmPassword: "Confirm Password",
  passwordResetSuccess: "Password reset success email has been sent to your email address.",
  emailSent: "Email was sent",
  emailNotSent: "Email error while sending",
  writeYourTaskName: "Write your task name",
  taskName: "Task name",
  gettingStarted: "Getting started",
  helpCenter: "Help Center",
  frequentlyAskedQuestions: "Frequently asked questions",
  yourMessage: "Your message",
  submit: "Submit",
  discoverGettingStarted: "Discover getting started for complete your free plan",
  gettingStartedIntro:
    "You can learn about the features and benefits of Mikrolo by following the steps in Getting Started. Also, you can link your accounts here so that we can provide you a personalized digital marketing service based on data.",
  contactUs: "Contact us",
  ruleDetails: "Rule Details",
  roadmaps: "Roadmaps",
  noDueDate: "No due date",
  description: "Description",
  content: "Content",
  summary: "Summary",
  totalTasks: "Total tasks",
  adsSnapshotFor: "Ads snapshot -",
  adsMetricsFor: "Ads metrics -",
  accept: "Accept",
  accepted: "Accepted",
  connected: "Connected",
  freeNoCreditCard: "No credit card required.",
  getStartedForFree: "Get started for free",
  roadmapDetail: "Roadmap Detail",
  loginWithGoogle: "Login with Google",
  createANewTask: "Create a new task",
  upgradeYourPlan: "Upgrade your plan",
  writeYourDescription: "Write your description",
  active: "Active",
  start: "Start",
  loading: "Loading",
  filterRoadmaps: "Filter roadmaps",
  goals: "Needs",
  recommendedKeywords: "Recommended keywords",
  accounts: "Accounts",
  skip: "Skip",
  target: "Target",
  writeYourBrief: "Write your brief",
  brief: "Brief",
  create: "Create",
  selectExperts: "Select experts",
  messageIntroTitle: "Experts on the way. You will match with them soon",
  messageIntroContent:
    "We are looking to you the best. It can take a while to find an expert. When they are founded you can start to communication.",
  welcomeToMikrolo: "Welcome to Mikrolo",
  strategies: "Strategies",
  registerWithGoogle: "Register with Google",
  viewAll: "View all",
  assigned: "Assigned",
  preview: "Preview",
  confirm: "Confirm",
  "Task completed successfully, you can continue with the next task":
    "Task completed successfully, you can continue with the next task",
  "Share in": "Share in",
  sort: "Sort",
  contentCalendar: "Content Calendar",
  search: "Search",
  "Your post has been scheduled successfully": "Your post has been scheduled successfully",
  "Please remove http or https from the website address":
    "Please remove http or https from the website address",
  "Your Plan": "Your Plan",
  "What's included": "What's included",
  "Upgrade Your Account": "Upgrade Your Account",
  "To create and assign more tasks to our experts, let’s upgrade your account!":
    "To create and assign more tasks to our experts, let’s upgrade your account!",
  "Please connect your Facebook account to schedule a post":
    "Please connect your Facebook account to schedule a post",
  "Please connect your Linkedin account to schedule a post":
    "Please connect your Linkedin account to schedule a post",
  "Share to Facebook": "Share to Facebook",
  "Share to Instagram": "Share to Instagram",
  "Share to Linkedin": "Share to Linkedin",
  "Select a Facebook page": "Select a Facebook page",
  "Select a page": "Select a page",
  "Please add a page to your account to share": "Please add a page to your account to share",
  "Select a Linkedin page": "Select a Linkedin page",
  "Share now": "Share now",
  "If you want to schedule your post, please select a date and time":
    "If you want to schedule your post, please select a date and time",
  "Select timezone": "Select timezone",
  "Select a date": "Select a date",
  share: "Share",
  "This roadmap has been already started": "This roadmap has been already started",
  "There are rules that start working for you in line with the strategy you have started":
    "There are rules that start working for you in line with the strategy you have started",
  "Go to Rules": "Go to Rules",
  "Are you sure you want to disconnect Google Analytics?":
    "Are you sure you want to disconnect Google Analytics?",
  "Are you sure you want to disconnect Google Ads?":
    "Are you sure you want to disconnect Google Ads?",
  "Please confirm you want to disconnect your Facebook account from this project":
    "Please confirm you want to disconnect your Facebook account from this project",
  "Are you sure you want to disconnect Linkedin?": "Are you sure you want to disconnect Linkedin?",
  "Coming Soon": "Coming Soon",
  "Start My Strategy": "Start My Strategy",
  "See what's the next steps": "See what's the next steps",
  "When you complete this stage, the strategic plan that a digital marketing manager can prepare and the task list for this plan will be created automatically":
    "When you complete this stage, the strategic plan that a digital marketing manager can prepare and the task list for this plan will be created automatically",
  "View Strategy": "View Strategy",
  "Assign My Task": "Assign My Task",
  "Create a task based on your needs": "Create a task based on your needs",
  "Lets our talents all over the world works for you! Decide your need and we will assign your task to relevant talent":
    "Lets our talents all over the world works for you! Decide your need and we will assign your task to relevant talent",
  "Connect My Account": "Connect My Account",
  "Let’s connect your accounts": "Let’s connect your accounts",
  "No data, no accurate outcomes. Let us detect upcoming threats and opportunities":
    "No data, no accurate outcomes. Let us detect upcoming threats and opportunities",
  "Connect My Accounts": "Connect My Accounts",
  "Strategies For You": "Strategies For You",
  "View All": "View All",
  "Task List": "Task List",
  "Connecting your Google Analytics account allows us to generate reports with your data, detect some issues and create custom tasks for you.":
    "Connecting your Google Analytics account allows us to generate reports with your data, detect some issues and create custom tasks for you.",
  "Connecting your Google Ads account allows us to provide you some reports on your ads, detect errors and create tasks about your ads.":
    "Connecting your Google Ads account allows us to provide you some reports on your ads, detect errors and create tasks about your ads.",
  "Connecting your Facebook account allows us to share your posts on your Facebook page and create tasks about your Facebook page.":
    "Connecting your Facebook account allows us to share your posts on your Facebook page and create tasks about your Facebook page.",
  "Connecting your Linkedin account allows us to share your posts on your Linkedin page and create tasks about your Linkedin page.":
    "Connecting your Linkedin account allows us to share your posts on your Linkedin page and create tasks about your Linkedin page.",
  "Connecting your Facebook Ads account allows us to provide you some reports on your ads, detect errors and create tasks about your ads.":
    "Connecting your Facebook Ads account allows us to provide you some reports on your ads, detect errors and create tasks about your ads.",
  "Continue with Facebook": "Continue with Facebook",
  "There is no event for this project. If you want to add an event, please click the button below and go to the task page. You can schedule your tasks from there.":
    "There is no event for this project. If you want to add an event, please click the button below and go to the task page. You can schedule your tasks from there.",
  "Add Event": "Add Event",
  "Todays Files": "Todays Files",
  "No content found": "No content found",
  "This Month": "This Month",
  "How can we help?": "How can we help?",
  "Everything you need to know about the product. Can’t find the answer you’re looking for? Please contact us.":
    "Everything you need to know about the product. Can’t find the answer you’re looking for? Please contact us.",
  "Can’t find your answer you’re looking for? Our friendly team would love to hear from you!":
    "Can’t find your answer you’re looking for? Our friendly team would love to hear from you!",
  "Upgrade Plan": "Upgrade Plan",
  live: "Live",
  preparing: "Preparing",
  "Learn more about this report": "Learn more about this report",
  "Top organic keywords": "Top organic keywords",
  "Create a new task": "Create a new task",
  "Get started by filling in the information below to create your new task.":
    "Get started by filling in the information below to create your new task.",
  "Please select a template to see the list of experts!":
    "Please select a template to see the list of experts!",
  "Upon creation of this task, it will be assigned to the relevant talents who will begin working on it. The task will be delivered to you within one day.":
    "Upon creation of this task, it will be assigned to the relevant talents who will begin working on it. The task will be delivered to you within one day.",
  about: "About",
  "Comments with talents": "Comments with talents",
  commented: "Commented",
  daily: "Daily",
  weekly: "Weekly",
  monthly: "Monthly",
  "Authority Score": "Authority Score",
  desktop: "Desktop",
  mobile: "Mobile",
  "Working rules for you": "Working rules for you",
  "When system finish to read audits, you will see the rules working for you!":
    "When system finish to read audits, you will see the rules working for you!",
  "View All Rules": "View All Rules",
  "Which keywords best describe your business?": "Which keywords best describe your business?",
  "Share with us the keywords you think are relevant to your business. We will help you to improve your SEO.":
    "Share with us the keywords you think are relevant to your business. We will help you to improve your SEO.",
  "Who are your competitors?": "Who are your competitors?",
  "Don’t let your competitors to get ahead of you! Share with us a few businesses' websites you see as competitors. So, you will see your position among your competitors.":
    "Don’t let your competitors to get ahead of you! Share with us a few businesses' websites you see as competitors. So, you will see your position among your competitors.",
  "What accounts do you have?": "What accounts do you have?",
  "Connect your accounts to get the most out of your SEO and PPC campaigns.":
    "Connect your accounts to get the most out of your SEO and PPC campaigns.",
  "Getting Started": "Getting Started",
  "Understand how the task structure works!": "Understand how the task structure works!",
  "Connect your accounts!": "Connect your accounts!",
  "See your first report!": "See your first report!",
  "Complete your profile!": "Complete your profile!",
  "Connection your accounts!": "Connection your accounts!",
  "You need to connect accounts to some rules to start working for you in line with the strategy you have started!":
    "You need to connect accounts to some rules to start working for you in line with the strategy you have started!",
  "Upgrade Your Plan": "Upgrade Your Plan",
  "Don’t let websites you see as competitors. So, you will see your position among your competitors.":
    "Don’t let websites you see as competitors. So, you will see your position among your competitors.",
  "Did you like the work?": "Did you like the work?",
  "If you like the work, please click the 'Accept' button.":
    "If you like the work, please click the 'Accept' button.",
  "What’s your digital marketing needs?": "What’s your digital marketing needs?",
  "Select the situations and needs below that are appropriate for you.":
    "Select the situations and needs below that are appropriate for you.",
  "Which country is the destination for your business?":
    "Which country is the destination for your business?",
  "Please select the country your business located in or mainly focus on. You will see the analyzes according to country you have selected.":
    "Please select the country your business located in or mainly focus on. You will see the analyzes according to country you have selected.",
  "You are one step away from feeling happy about digital marketing.":
    "You are one step away from feeling happy about digital marketing.",
  membership: "Membership",
  "Your current plan": "Your current plan",
  "Pricing Plans": "Pricing Plans",
  "Start building for free, then add a site plan to go live. Account plans unlock additional features.":
    "Start building for free, then add a site plan to go live. Account plans unlock additional features.",
  "You can only add 5 competitors": "You can only add 5 competitors",
  "You can only add 5 keywords": "You can only add 5 keywords",
  "Please enter a valid URL. Example: https://www.example.com":
    "Please enter a valid URL. Example: https://www.example.com",
  objectives: "Objectives",
  "Social Media Audit": "Social Media Audit",
  "If you don't get verification email, please again click on verify account button":
    "If you don't get verification email, please again click on verify account button",
  position: "Position",
  "Traffic Percent": "Traffic Percent",
  "Roadmap start to work for you!": "Roadmap start to work for you!",
  "Let's see what tasks you need to do.": "Let's see what tasks you need to do.",
  "See my tasks": "See my tasks",
  "What you need?": "What you need?",
  "You can select any expert to do your task. Select what you need!":
    "You can select any expert to do your task. Select what you need!",
  experts: "Experts",
  "Here all tasks in your plan. You will find information for each for completion.":
    "Here all tasks in your plan. You will find information for each for completion.",
  "Please select at least one platform": "Please select at least one platform",
  "Please select a page": "Please select a page",
  "Please select a linkedin page": "Please select a linkedin page",
  "Please fill all fields": "Please fill all fields",
  "There was an error assigning the tasks": "There was an error assigning the tasks",
  "Please select at least one objective": "Please select at least one objective",
  "You can select maximum 3 objectives": "You can select maximum 3 objectives",
  "I need Digital Marketing Manager!": "I need Digital Marketing Manager!",
  "Create my digital marketing strategy and plans.":
    "Create my digital marketing strategy and plans.",
  "I need experts to do the digital marketing works!":
    "I need experts to do the digital marketing works!",
  "Assign my Digital Marketing tasks to relevant experts.":
    "Assign my Digital Marketing tasks to relevant experts.",
  "I need ad images and content!": "I need ad images and content!",
  "Create my ad images and content.": "Create my ad images and content.",
  "I need to do benchmarks in my industry to be successful!":
    "I need to do benchmarks in my industry to be successful!",
  "Give me digital marketing insights about my industry and drivers for success.":
    "Give me digital marketing insights about my industry and drivers for success.",
  "I need daily/monthly budget suggestion for my ads!":
    "I need daily/monthly budget suggestion for my ads!",
  "Find and scale my test budget and my sales drivers":
    "Find and scale my test budget and my sales drivers",
  "I need to know opportunities and threats and enable me to take action.":
    "I need to know opportunities and threats and enable me to take action.",
  "Show where opportunities and threats occur automatically and regularly":
    "Show where opportunities and threats occur automatically and regularly",
  "Maximum 5 keywords allowed": "Maximum 5 keywords allowed",
  "Domain name is required": "Domain name is required",
  "Competitors are required": "Competitors are required",
  credit: "Credit(s)",
  "The features covered by your plan are over!": "The features covered by your plan are over!",
  "If you want to contune using the features’ how about choosing a plan that suits you better?":
    "If you want to contune using the features’ how about choosing a plan that suits you better?",
  "There was an error finishing the task. Try again later.":
    "There was an error finishing the task. Try again later.",
  "Are you sure you want to complete the task without uploading any file?":
    "Are you sure you want to complete the task without uploading any file?",
  "Go back to task": "Go back to task",
  ourSuggestions: "Our Suggestions",
  images: "Images",
  captions: "Captions",
  "Create post now": "Create post now",
  "Please select image or caption": "Please select image or caption",
  "Post created successfully, you can preview": "Post created successfully, you can preview",
  viewSuggestions: "View Suggestions",
  "Please fill country field": "Please fill country field",
  "Invoice Information": "Invoice Information",
  "We need your billing address for your invoice.":
    "We need your billing address for your invoice.",
  "Let's reach your digital marketing goals.": "Let's reach your digital marketing goals.",
  Tasks: "Tasks",
  "Upload your content": "Upload your content",
  "Total Credit": "Total Credit",
  "Everything you need to know about the how to finish a task. Can’t find the answer you’re looking for? Please contact us.":
    "Everything you need to know about the how to finish a task. Can’t find the answer you’re looking for? Please contact us.",
  "Tax Identification Number": "Tax Identification Number",
  "Tax Office": "Tax Office",
  Channel: "Channel",
  Count: "Count",
  "Page Path": "Page Path",
  "Page Views": "Page Views",
  "Everything you need to know about the product. Can’t find the answer you’re looking for?":
    "Everything you need to know about the product. Can’t find the answer you’re looking for?",
  "How can I assign my task to a talent?": "How can I assign my task to a talent?",
  "You can view the video below to learn how to assign a task to a talent.":
    "You can view the video below to learn how to assign a task to a talent.",
  "Start strategy": "Start strategy",
  "You can view the video below to learn how to start a strategy and use the strategy builder.":
    "You can view the video below to learn how to start a strategy and use the strategy builder.",
  "You can view the video below to learn how to create a new task.":
    "You can view the video below to learn how to create a new task.",
  "Upload your files": "Edit your files",
  "No image": "No image",
  "No message": "No message",
  "Preview on your task": "Preview on your task",
  "Upload your image": "Upload your image",
  "Your message": "Your message",
  upload: "Upload",
  "Write your thoughts here...": "Write your thoughts here...",
  createDate: "Create Date",
  "Share your work": "Share your work",
  "Select a Instagram page": "Select a Instagram page",
  "Number of Assigned Tasks": "Number of Assigned Tasks",
  "Total Credits Spent": "Total Credits Spent",
  "Available Credit": "Available Credit",
  businessType: "Business Type",
  "What product/ services does the business provide?":
    "What product/ services does the business provide?",
  "Describe your business": "Describe your business",
  "Buy Extra Credit": "Buy Extra Credit",
  buy: "Buy",
  invoiceTitle: "Business Name/Name Surname",
  invoiceType: "Invoice Type",
  "Selected image": "Selected image",
  "Rules regularly monitor and audit your digital assets. As a result, it presents you with potential threats and opportunities so you don't overlook them. It helps you strengthen your digital marketing by alerting you to anomalies in your account and ad campaigns and creating tasks for you. In this section, you can review the rules that work for you and see what's under audit.":
    "Rules regularly monitor and audit your digital assets. As a result, it presents you with potential threats and opportunities so you don't overlook them. It helps you strengthen your digital marketing by alerting you to anomalies in your account and ad campaigns and creating tasks for you. In this section, you can review the rules that work for you and see what's under audit.",
  "Cancel Subscription": "Cancel Subscription",
  Details: "Details",
  "Are you sure you want to cancel your subscription?":
    "Are you sure you want to cancel your subscription?",
  earnadoPlans: "Earnado Plans",
  "Earnado Plans": "Earnado Plans",
  "Do you have online sales?": "Do you have online sales?",
  "How much is the monthly budget you set for your ads?":
    "How much is the monthly budget you set for your ads?",
  "Have you ever advertised before? If yes, in which channels?":
    "Have you ever advertised before? If yes, in which channels?",
  "Create your profile": "Create your profile",
  "Let's define the needed information to the talents":
    "Let's define the needed information to the talents",
  "Let's set up your profile that will customize your project and better identify your needs.":
    "Let's set up your profile that will customize your project and better identify your needs.",
  startNow: "Start now",
  createdBy: "Created by",
  totalCredit: "Total Credit",
  assets: "Assets",
  marketingTools: "Marketing Tools",
  clicks: "Clicks",
  impressions: "Impressions",
  averageCpc: "Average CPC",
  cost: "Cost",
  "Number of users coming to your website from Social media channels.":
    "Number of users coming to your website from Social media channels.",
  "Select Campaign": "Select Campaign",
  accountIssues: "Account Issues",
  name: "Name",
  view: "View",
  searchKeywords: "Search Keywords",
  adGroup: "Ad Group",
  impression: "Impression",
  ctr: "CTR",
  cpc: "CPC",
  keywordPerformance: "Keyword Performance",
  "Keywords are the words or phrases that trigger your ads to appear.":
    "Keywords are the words or phrases that trigger your ads to appear.",
  allAdsCampaignMetrics: "All Ads Campaign Metrics",
  "You can see finished tasks on that range by click button.":
    "You can see finished tasks on that range by click button.",
  noDataTitle: "No Results Found",
  noDataDescription:
    "No data could be found for this client. Please try using different search criteria or ensure your connection is correct. If you need further assistance, feel free to contact our support team.",
  campaignMetrics: "Campaign Metrics",
  adsReport: "Ads Report",
  biddingStrategies: "Bidding Strategies",
  biddingStrategiesMetrics: "Bidding Strategies Metrics",
  adReports: "Ad Reports",
  adGroups: "Ad Groups",
  show: "Show",
  hide: "Hide",
  automatedDesigner: "Automated Designer",
  myDesigns: "My Designs",
  download: "Download",
  regenerate: "Regenerate",
  createNewDesign: "Create New Design",
  noDesignGeneratedYet: "  No Design Generated Yet",
  noDesignGeneratedYetDescription:
    "You haven't generated any designs yet. To start, simply click the button below to create your first one. Need assistance? Feel free to contact our support team.",
  adGroupMetrics: "Ad Group Metrics",
  adGroupAdMetrics: "Ad Group Ad Metrics",
  ads: "Ads",
  adGroupKeywords: "Ad Group Keywords",
  adMetrics: "Ad Metrics",
  adSearchTerms: "Ad Search Terms",
  searchTermMetrics: "Search Term Metrics",
  adGroupKeywordMetrics: "Ad Group Keyword Metrics",
  buyExtraCredit: "Buy Extra Credit",
  buyNow: "Buy Now",
  extraCredit: "Extra Credit",
  extraCreditDescription: "You can increase your credit limit by purchasing additional credit.",
  paymentPeriod: "Payment period",
  getExtraCreditRequireText: "To receive extra credit, you need to subscribe to a plan.",
  credits: "Credits",
  buyCredits: "Buy Credits",
  subscribeNow: "Subscribe Now",
  createDesignLoadingDescription:
    "Design is being created. please do not close or refresh the page",
  needSubscribeForUseDesigner: "To use the automated designer, you need to subscribe to a plan.",
  plans: "Plans",
  imageType: "Image Type",
};
