import LoadingSpinner from "@/components/UI/LoadingSpinner";
import AssessmentLayout from "@/components/Layout/Assessment/index";
import { useRecoilState } from "recoil";
import { userAtom } from "@/store/atoms/user.atoms";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import { projectDataOnboarding } from "@/store/atoms/projectDataOnboarding.atoms";
import { fetchProjectData } from "@/api/project";
import { useQuery } from "react-query";
import { MdCheck, MdOutlinedFlag, MdOutlineRemoveRedEye } from "react-icons/md";
import welcomeGif from "../../../assets/images/welcome.gif";
import { FaCheckCircle, FaFontAwesomeFlag } from "react-icons/fa";
import { IoMdPaperPlane } from "react-icons/io";
import path from "path";
import SecondaryButton from "@/components/buttons/SecondaryButton";

const DashboardPage = () => {
  const [project] = useRecoilState(projectInfo);
  const [projectOnboardingData] = useRecoilState(projectDataOnboarding);
  const projectId = project.id;
  const [user] = useRecoilState(userAtom);

  const checkList = [
    {
      icon: <MdCheck />,
      title: "Create your account",
      description: "This step is done, but you can always update your profile settings.",
      checked: true,
      buttonName: "",
      path: null,
    },
    {
      icon: <MdOutlinedFlag />,
      title: "Select your first strategy",
      description: "This step is done, but you can always update your profile settings.",
      checked: false,
      buttonName: "Select",
      path: "/strategies",
    },
    {
      icon: <IoMdPaperPlane />,
      title: "Assing your first task",
      description: "This step is done, but you can always update your profile settings.",
      checked: false,
      buttonName: "Assign",
      path: "/tasks",
    },
    {
      icon: <MdOutlineRemoveRedEye />,
      title: "Review and approve your completed task",
      description: "This step is done, but you can always update your profile settings.",
      checked: false,
      buttonName: "Review",
      path: "/tasks",
    },
  ];

  const { isLoading, data: projectStartedData } = useQuery(
    "fetchProjectGettingStartedData",
    () => fetchProjectData({ projectId: projectId, name: "GETTING_STARTED" }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!projectId,
    }
  );

  return (
    <AssessmentLayout searchBar={false}>
      <div className="flex flex-col gap-6 justify-start items-center pb-8">
        <div className="flex flex-col items-center gap-2 w-full md:w-6/12">
          <img className="w-[70px] -rotate-45" src={welcomeGif} alt="welcome" />
          <h2 className="text-3xl text-center capitalize leading-6 text-gray-900 font-semibold  ">
            Welcome to Mikrolo, {user?.first_name}
          </h2>
          <span className="text-md text-center">
            We help businesses execute impressive marketing strategies that drive business results.
            Let’s get started.
          </span>
        </div>
        {!projectOnboardingData || isLoading ? (
          <LoadingSpinner size="w-8 h-8" />
        ) : (
          <div className="flex flex-col w-full  md:w-8/12 border rounded-md">
            <div className="px-4 py-3 w-full flex justify-between items-center">
              <span className="font-bold text-lg">Onboarding checklist </span>
              <span className="text-gray-500">%25 Completed</span>
            </div>
            <div className="flex justify-between w-full">
              {checkList.map((item: any, index: number) => (
                <div
                  key={index}
                  className={
                    "w-4/12 border-b-2 " +
                    (item.checked ? "border-secondaryHoverColor " : "border-gray-100")
                  }
                />
              ))}
            </div>
            {checkList.map((item: any, index: number) => (
              <div key={index} className="flex justify-between items-center px-4 py-6 border-b ">
                <div className="flex items-center gap-4">
                  {item.checked ? (
                    <span className="text-3xl text-secondaryHoverColor">
                      <FaCheckCircle />{" "}
                    </span>
                  ) : (
                    <span className="text-3xl">{item.icon}</span>
                  )}

                  <div className="flex flex-col">
                    <span className="font-semibold text-md"> {item.title} </span>
                    <span className="font-regular text-gray-500 text-sm"> {item.description} </span>
                  </div>
                </div>
                {item.path && <SecondaryButton outline name={item.buttonName} to={item.path} />}
              </div>
            ))}
          </div>
        )}
      </div>
    </AssessmentLayout>
  );
};

export default DashboardPage;
