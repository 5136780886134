import AssessmentLayout from "@/components/Layout/Assessment";
import ContentBar from "@/components/ContentBar";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AdGroupsTable from "@/features/report/components/ad-reports/tables/campaign-metrics/AdGroupsTable";
import Breadcrumb from "@/components/breadcrumb/breadcrumb";
import CampaignMetricsTable from "@/features/report/components/ad-reports/tables/campaign-metrics/CampaignMetricsTable";

const CampaignMetricsPage = () => {
  const { t } = useTranslation();
  const { projectId, clientId, reportId, campaignId } = useParams();

  return (
    <div>
      <AssessmentLayout>
        <ContentBar title={t("campaignMetrics")} />
        <Breadcrumb reportId={reportId} clientId={clientId} projectId={projectId} />
        <div>
          <CampaignMetricsTable tableId="campaignMetrics" />
          <AdGroupsTable tableId="adGroup" />
        </div>
      </AssessmentLayout>
    </div>
  );
};

export default CampaignMetricsPage;
