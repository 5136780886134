import { useEffect, useRef, useState } from "react";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { t } from "i18next";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import EditAvatarModal from "../EditAvatarModal";
import { authMe, puthAuthMe } from "@/api/auth";
import { useMutation, useQuery } from "react-query";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import BlurInput from "@/components/BlurInput";
import { fetchAllCountries } from "@/api/country";
import Select from "react-select";
import { resendVerificationEmail } from "@/api/verification";
import toast, { Toaster } from "react-hot-toast";
import SelectLanguage from "@/components/Layout/Assessment/Sidebar/SelectLanguage";

const ProfileInformation = () => {
  const [, setShowDialog] = useState<boolean>(false);
  /*   const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);
   */
  /*   useEffect(() => {
    if (showPrompt) {
      const response = confirm("Are you sure you want to leave?");

      if (response) {
        if (typeof confirmNavigation === "function") confirmNavigation();
      }

      if (!response) {
        if (typeof cancelNavigation === "function") cancelNavigation();
      }
    }
  }, [showPrompt]); */

  const [image, setImage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const imageRef = useRef<any>(null);

  const [selectedCountry, setSelectedCountry] = useState<any>(null);

  const { data, isLoading } = useQuery("authmeProfile", authMe, {});

  const schema = yup
    .object({
      first_name: yup.string().required(t("first_name_is_required")),
      last_name: yup.string().required(t("last_name_is_required")),
      email: yup.string().email().required(t("email_is_required")),
      phone_number: yup.string().required(t("phone_number_is_required")),
      // country: yup.string().required(t("country_is_required")),
      street: yup.string().required(t("street_is_required")),
      city: yup.string().required(t("city_is_required")),
      zip: yup.string().required(t("zip_is_required")),
      state: yup.string().required(t("state_is_required")),
      tax_identification_number: yup.string(),
      tax_office: yup.string(),
      invoice_title: yup.string(),
      invoice_type: yup.string(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (incomingData: any) => {
    if (!selectedCountry) {
      toast.error("Please select a country");
      return;
    }
    setShowDialog(false);

    if (incomingData.tax_identification_number) {
      if (
        incomingData.tax_identification_number.length !== 10 &&
        incomingData.tax_identification_number.length !== 11
      ) {
        toast.error("Please enter a valid tax identification number, 10 or 11 digits");
        return;
      }
    }
    mutateAsync({
      userId: data.id,
      email: incomingData.email,
      phone_number: incomingData.phone_number,
      first_name: incomingData.first_name,
      last_name: incomingData.last_name,
      address: incomingData.street,
      city: incomingData.city,
      state: incomingData.state,
      zip_code: incomingData.zip,
      tax_identification_number: incomingData.tax_identification_number,
      tax_office: incomingData.tax_office,
      invoice_title: incomingData.invoice_title,
      invoice_type: incomingData.invoice_type,
      country: selectedCountry,
      imageFile: imageFile,
    })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        toast.error("There was an error updating your profile. Please try again later.");
      });
  };

  useEffect(() => {
    if (data) {
      setImage(data.avatar);
    }
  }, [data]);

  const { mutateAsync, isLoading: isLoadingUpdate } = useMutation(puthAuthMe, {});

  const { data: countries, isLoading: isLoadingCountries } = useQuery(
    "fetchACountries",
    fetchAllCountries,
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleCountryChange = (e: any) => {
    setSelectedCountry(e.value);
  };

  const { mutateAsync: mutateSendEmail, isLoading: isLoadingSendEmail } =
    useMutation(resendVerificationEmail);

  const handleSendEmail = () => {
    console.log("send email", data.id);
    mutateSendEmail({
      user_id: data.id,
      verification_type: "email_verification",
    })
      .then(() => {
        toast.success(t("emailSent"));
      })
      .catch(() => {
        toast.success(t("emailNotSent"));
      });
  };

  useEffect(() => {
    if (data?.address?.country) {
      setSelectedCountry(data.address.country);
    }
  }, [data]);

  return (
    <>
      <Toaster />
      {isLoading ? (
        <div className="bg-white">
          <LoadingSpinner size="w-8 h-8" />
        </div>
      ) : (
        <form
          className="space-y-4 divide-y divide-gray-200 bg-bgCardColor mb-6 pb-3 max-w-[1460px] relative"
          onSubmit={handleSubmit(onSubmit)}>
          <div className=" divide-gray-200 p-6 ">
            <div>
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900 ">
                  {t("personalInformation")}
                </h3>
              </div>
              <div className="absolute right-5 top-3 z-[99]">
                <SelectLanguage />
              </div>

              <EditAvatarModal
                modalState={isOpen}
                setModalState={() => setIsOpen(false)}
                setFile={setImageFile}
                setImage={setImage}>
                <div></div>
              </EditAvatarModal>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
                    {t("photo")}
                  </label>
                  <div className="mt-1 flex items-center">
                    <img
                      ref={imageRef}
                      src={image ? image : "/assets/icons/placeholder.png"}
                      alt=""
                      className="rounded-full border border-borderStroke h-12 w-12"
                      width={100}
                    />

                    <button
                      type="button"
                      onClick={() => setIsOpen(!isOpen)}
                      className="ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-offset-2">
                      {t("change")}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-2">
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                    {t("firstname")}
                  </label>
                  <div className="mt-1">
                    <BlurInput>
                      {({ onblur, setOnblur }: any) => (
                        <input
                          {...register("first_name")}
                          onBlur={() => {
                            setOnblur(true);
                            setShowDialog(true);
                          }}
                          type="text"
                          name="first-name"
                          defaultValue={data?.first_name}
                          id="first-name"
                          autoComplete="given-name"
                          className={`
                          ${onblur ? "valid:border-primaryColor" : "border-gray-300"}
                          block w-full appearance-none rounded-md border  px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                        />
                      )}
                    </BlurInput>
                  </div>
                  {errors.first_name?.message && (
                    <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                      {errors.first_name?.message}
                    </span>
                  )}
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                    {t("lastname")}
                  </label>
                  <div className="mt-1">
                    <BlurInput>
                      {({ onblur, setOnblur }: any) => (
                        <input
                          {...register("last_name")}
                          type="text"
                          name="last-name"
                          onBlur={() => {
                            setOnblur(true);
                            setShowDialog(true);
                          }}
                          defaultValue={data?.last_name}
                          id="last-name"
                          autoComplete="family-name"
                          className={`
                          ${onblur ? "valid:border-primaryColor" : "border-gray-300"}
                          block w-full appearance-none rounded-md border  px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                        />
                      )}
                    </BlurInput>
                  </div>
                  {errors.last_name?.message && (
                    <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                      {errors.last_name?.message}
                    </span>
                  )}
                </div>

                <div className="sm:col-span-4">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    {t("email")}
                  </label>
                  <div className="mt-1">
                    <BlurInput>
                      {({ onblur, setOnblur }: any) => (
                        <input
                          {...register("email")}
                          defaultValue={data?.email}
                          id="email"
                          onBlur={() => {
                            setOnblur(true);
                            setShowDialog(true);
                          }}
                          name="email"
                          type="email"
                          autoComplete="email"
                          className={`
                          ${onblur ? "valid:border-primaryColor" : "border-gray-300"}
                          block w-full appearance-none rounded-md border  px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                        />
                      )}
                    </BlurInput>
                  </div>
                  {errors.email?.message && (
                    <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                      {errors.last_name?.message}
                    </span>
                  )}
                </div>

                <div className="sm:col-span-4">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    {t("phone")}
                  </label>
                  <div className="mt-1 ">
                    <BlurInput>
                      {({ onblur, setOnblur }: any) => (
                        <input
                          {...register("phone_number")}
                          defaultValue={data?.phone_number || ""}
                          type="text"
                          name="phone_number"
                          onBlur={() => {
                            setOnblur(true);
                            setShowDialog(true);
                          }}
                          // only numbers
                          onInput={(e: any) => {
                            e.target.value = e.target.value.replace(/[^0-9+]/g, "");
                          }}
                          required
                          className={`
                          ${onblur ? "valid:border-primaryColor" : "border-gray-300"}
                          block w-full appearance-none rounded-md border  px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                        />
                      )}
                    </BlurInput>
                  </div>
                  {errors.phone_number?.message && (
                    <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                      {errors.phone_number?.message}
                    </span>
                  )}
                </div>

                <div className="sm:col-span-3">
                  <div className="mb-3">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                      {t("Invoice Information")}
                    </h3>
                    <p className="mt-1 text-xs text-gray-500">
                      *{t("We need your billing address for your invoice.")}
                    </p>
                  </div>

                  <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                    {t("country")}
                  </label>
                  <div className="mt-1 capitalize">
                    {isLoadingCountries ? (
                      <LoadingSpinner size="w-5 h-5" />
                    ) : (
                      <>
                        {/*          <BlurInput>
                          {({ onblur, setOnblur }: any) => (
                            <select
                              defaultValue={data?.address?.country}
                              {...register("country")}
                              autoComplete="country-name"
                              onBlur={() => setOnblur(true)}
                              className={`
                        ${onblur ? "valid:border-primaryColor" : "border-gray-300"}
                        block w-full rounded-md border  px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}>
                              <option className="text-gray-400" value="" disabled selected hidden>
                                Select a Country
                              </option>
                              {countries.map((country: any) => (
                                <option value={country.id}>{country.name}</option>
                              ))}
                            </select>
                          )}
                        </BlurInput> */}
                        <Select
                          isSearchable={false}
                          defaultValue={{
                            label:
                              countries?.find(
                                (country: any) => +country?.id === +data?.address?.country
                              )?.name || "Select a Country",
                            value: +data?.address?.country || "",
                          }}
                          onChange={handleCountryChange}
                          styles={{
                            control: (provided: any) => ({
                              ...provided,
                              border: "1px solid #d9dbdd",

                              borderRadius: "0.375rem",
                              boxShadow: "none",
                              "&:hover": {
                                borderColor: "#e2e8f0",
                              },
                              textTransform: "capitalize",
                              fontSize: "0.875rem",
                              padding: "0rem 0.3rem",
                            }),
                          }}
                          options={countries.map((country: any) => ({
                            value: country.id,
                            label: country.name,
                          }))}
                        />
                      </>
                    )}
                  </div>
                  {errors.country?.message && (
                    <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                      {errors.country?.message}
                    </span>
                  )}
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="street-address"
                    className="block text-sm font-medium text-gray-700">
                    {t("streetAddress")}
                  </label>
                  <div className="mt-1">
                    <BlurInput>
                      {({ onblur, setOnblur }: any) => (
                        <input
                          {...register("street")}
                          defaultValue={data?.address?.address}
                          type="text"
                          autoComplete="street-address"
                          onBlur={() => {
                            setOnblur(true);
                            setShowDialog(true);
                          }}
                          className={`
                          ${onblur ? "valid:border-primaryColor" : "border-gray-300"}
                          block w-full appearance-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                        />
                      )}
                    </BlurInput>
                  </div>
                  {errors.street?.message && (
                    <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                      {errors.street?.message}
                    </span>
                  )}
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="street-address"
                    className="block text-sm font-medium text-gray-700">
                    {t("invoiceType")}
                  </label>
                  <div className="mt-1 flex gap-4 items-center  text-sm">
                    <label className="flex gap-1" htmlFor="field-rain">
                      <input
                        {...register("invoice_type")}
                        type="radio"
                        value="personal"
                        id="field-rain"
                        defaultChecked={data?.invoice_type === "personal"}
                      />
                      Personal
                    </label>
                    <label className="flex gap-1" htmlFor="field-wind">
                      <input
                        {...register("invoice_type")}
                        type="radio"
                        value="commercial"
                        id="field-wind"
                        defaultChecked={data?.invoice_type === "commercial"}
                      />
                      Commercial
                    </label>
                  </div>
                  {errors.invoice_type?.message && (
                    <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                      {errors.invoice_type?.message}
                    </span>
                  )}
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="street-address"
                    className="block text-sm font-medium text-gray-700">
                    {t("invoiceTitle")}
                  </label>
                  <div className="mt-1">
                    <BlurInput>
                      {({ onblur, setOnblur }: any) => (
                        <input
                          {...register("invoice_title")}
                          defaultValue={data?.invoice_title}
                          type="text"
                          onBlur={() => {
                            setOnblur(true);
                            setShowDialog(true);
                          }}
                          className={`
                          ${onblur ? "valid:border-primaryColor" : "border-gray-300"}
                          block w-full appearance-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                        />
                      )}
                    </BlurInput>
                  </div>
                  {errors.invoice_title?.message && (
                    <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                      {errors.invoice_title?.message}
                    </span>
                  )}
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="tax_identifaction_number"
                    className="block text-sm font-medium text-gray-700">
                    {t("Tax Identification Number")}
                  </label>
                  <div className="mt-1">
                    <BlurInput>
                      {({ onblur, setOnblur }: any) => (
                        <input
                          defaultValue={data?.tax_identification_number}
                          type="text"
                          {...register("tax_identification_number")}
                          onBlur={() => {
                            setOnblur(true);
                            setShowDialog(true);
                          }}
                          className={`
                          ${onblur ? "valid:border-primaryColor" : "border-gray-300"}
                          block w-full appearance-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                        />
                      )}
                    </BlurInput>
                  </div>
                  {errors.tax_identification_number?.message && (
                    <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                      {errors.tax_identification_number?.message}
                    </span>
                  )}
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="tax_office" className="block text-sm font-medium text-gray-700">
                    {t("Tax Office")}
                  </label>
                  <div className="mt-1">
                    <BlurInput>
                      {({ onblur, setOnblur }: any) => (
                        <input
                          defaultValue={data?.tax_office}
                          type="text"
                          {...register("tax_office")}
                          onBlur={() => {
                            setOnblur(true);
                            setShowDialog(true);
                          }}
                          className={`
                          ${onblur ? "valid:border-primaryColor" : "border-gray-300"}
                          block w-full appearance-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                        />
                      )}
                    </BlurInput>
                  </div>
                  {errors.tax_office?.message && (
                    <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                      {errors.tax_office?.message}
                    </span>
                  )}
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                    {t("city")}
                  </label>
                  <div className="mt-1">
                    <BlurInput>
                      {({ onblur, setOnblur }: any) => (
                        <input
                          {...register("city")}
                          defaultValue={data?.address?.city}
                          type="text"
                          autoComplete="address-level2"
                          onBlur={() => {
                            setOnblur(true);
                            setShowDialog(true);
                          }}
                          className={`
                          ${onblur ? "valid:border-primaryColor" : "border-gray-300"}
                          block w-full appearance-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                        />
                      )}
                    </BlurInput>
                  </div>
                  {errors.city?.message && (
                    <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                      {errors.city?.message}
                    </span>
                  )}
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                    {t("stateProvince")}
                  </label>
                  <div className="mt-1">
                    <BlurInput>
                      {({ onblur, setOnblur }: any) => (
                        <input
                          {...register("state")}
                          defaultValue={data?.address?.state}
                          type="text"
                          autoComplete="address-level1"
                          onBlur={() => {
                            setOnblur(true);
                            setShowDialog(true);
                          }}
                          className={`
                          ${onblur ? "valid:border-primaryColor" : "border-gray-300"}
                          block w-full appearance-none rounded-md border  px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                        />
                      )}
                    </BlurInput>
                  </div>
                  {errors.state?.message && (
                    <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                      {errors.state?.message}
                    </span>
                  )}
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                    {t("zipCode")}
                  </label>
                  <div className="mt-1">
                    <BlurInput>
                      {({ onblur, setOnblur }: any) => (
                        <input
                          {...register("zip")}
                          defaultValue={data?.address?.zip_code}
                          type="text"
                          onBlur={() => {
                            setOnblur(true);
                            setShowDialog(true);
                          }}
                          autoComplete="postal-code"
                          className={`
                          ${onblur ? "valid:border-primaryColor" : "border-gray-300"}
                          block w-full appearance-none rounded-md border  px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                        />
                      )}
                    </BlurInput>
                  </div>
                  {errors.zip?.message && (
                    <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                      {errors.zip?.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5 pr-6">
            <div className="flex justify-end gap-4">
              <button
                type="button"
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-offset-2">
                {t("cancel")}
              </button>
              <DefaultButton isLoading={isLoadingUpdate} className="w-fit">
                {t("save")}
              </DefaultButton>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default ProfileInformation;
