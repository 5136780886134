import client from "./client";

export const fetchProjectMetrics = async (data: { projectId: string; metricId: string }) => {
  const response = await client.get(`/metric/project_metric/${data.metricId}`, {
    params: {
      project_id: data.projectId,
    },
  });

  return response.data;
};

export const fetchClientMetricReport = async (data: {
  clientId: number;
  page: string;
  pageSize: string;
  orderBy: string;
  startDate: string;
  endDate: string;
  sortOrder: string;
}) => {
  const response = await client.get(
    `/ea/report/campaigns-with-cumulative-metrics-table/${data.clientId}`,
    {
      params: {
        current_page: data.page,
        page_size: data.pageSize,
        date_begin_current_period: data.startDate,
        date_end_current_period: data.endDate,
        order_by: data.orderBy,
        sort_order: data.sortOrder,
      },
    }
  );

  return response.data;
};

export const fetchBiddingStrategies = async (data: {
  clientId: number;
  page: string;
  pageSize: string;
  orderBy: string;
  startDate: string;
  endDate: string;
  sortOrder: string;
}) => {
  const response = await client.get(
    `/ea/report/bidding_strategies_with_cumulative_metrics_table/${data.clientId}`,
    {
      params: {
        current_page: data.page,
        page_size: data.pageSize,
        date_begin_current_period: data.startDate,
        date_end_current_period: data.endDate,
        order_by: data.orderBy,
        sort_order: data.sortOrder,
      },
    }
  );

  return response.data;
};

export const fetchBiddingStrategiesMetrics = async (data: {
  clientId: number;
  campaignId: number;
  page: string;
  pageSize: string;
  orderBy: string;
  startDate: string;
  endDate: string;
  sortOrder: string;
}) => {
  const response = await client.get(
    `ea/report/bidding_strategy_metrics_table/${data.clientId}/${data.campaignId}`,
    {
      params: {
        current_page: data.page,
        page_size: data.pageSize,
        date_begin_current_period: data.startDate,
        date_end_current_period: data.endDate,
        order_by: data.orderBy,
        sort_order: data.sortOrder,
      },
    }
  );

  return response.data;
};

export const fetchCampaignMetrics = async (data: {
  clientId: number;
  campaignId: number;
  page: string;
  pageSize: string;
  orderBy: string;
  startDate: string;
  endDate: string;
  sortOrder: string;
}) => {
  const response = await client.get(
    `/ea/report/campaign-metrics-table/${data.clientId}/${data.campaignId}`,
    {
      params: {
        current_page: data.page,
        page_size: data.pageSize,
        date_begin_current_period: data.startDate,
        date_end_current_period: data.endDate,
        order_by: data.orderBy,
        sort_order: data.sortOrder,
      },
    }
  );

  return response.data;
};

export const fetchAdGroups = async (data: {
  clientId: number;
  campaignId: number;
  page: string;
  pageSize: string;
  orderBy: string;
  startDate: string;
  endDate: string;
  sortOrder: string;
}) => {
  const response = await client.get(
    `/ea/report/adgroups-with-cumulative-metrics-table-by-campaign/${data.clientId}/${data.campaignId}`,
    {
      params: {
        current_page: data.page,
        page_size: data.pageSize,
        date_begin_current_period: data.startDate,
        date_end_current_period: data.endDate,
        order_by: data.orderBy,
        sort_order: data.sortOrder,
      },
    }
  );

  return response.data;
};

export const fetchAdGroupMetrics = async (data: {
  clientId: number;
  adGroupId: number;
  page: string;
  pageSize: string;
  orderBy: string;
  startDate: string;
  endDate: string;
  sortOrder: string;
}) => {
  const response = await client.get(
    `/ea/report/adgroup-metrics-table/${data.clientId}/${data.adGroupId}`,
    {
      params: {
        current_page: data.page,
        page_size: data.pageSize,
        date_begin_current_period: data.startDate,
        date_end_current_period: data.endDate,
        order_by: data.orderBy,
        sort_order: data.sortOrder,
      },
    }
  );

  return response.data;
};

export const fetchAdGroupAdsReports = async (data: {
  clientId: number;
  adGroupId: number;
  page: string;
  pageSize: string;
  orderBy: string;
  startDate: string;
  endDate: string;
  sortOrder: string;
}) => {
  const response = await client.get(
    `/ea/report/ads-with-cumulative-metrics-table-by-adgroup/${data.clientId}/${data.adGroupId}`,
    {
      params: {
        current_page: data.page,
        page_size: data.pageSize,
        date_begin_current_period: data.startDate,
        date_end_current_period: data.endDate,
        order_by: data.orderBy,
        sort_order: data.sortOrder,
      },
    }
  );

  return response.data;
};

export const fetchAdMetrics = async (data: {
  clientId: number;
  adGroupId: number;
  adId: number;
  page: string;
  pageSize: string;
  orderBy: string;
  startDate: string;
  endDate: string;
  sortOrder: string;
}) => {
  const response = await client.get(
    `/ea/report/ad-metrics-table/${data.clientId}/${data.adGroupId}/${data.adId}`,
    {
      params: {
        current_page: data.page,
        page_size: data.pageSize,
        date_begin_current_period: data.startDate,
        date_end_current_period: data.endDate,
        order_by: data.orderBy,
        sort_order: data.sortOrder,
      },
    }
  );

  return response.data;
};

export const fetchAdSearchTerms = async (data: {
  clientId: number;
  adGroupId: number;
  adId: number;
  page: string;
  pageSize: string;
  orderBy: string;
  startDate: string;
  endDate: string;
  sortOrder: string;
}) => {
  const response = await client.get(
    `/ea/report/searchterms-with-cumulative-metrics-table-byad/${data.clientId}/${data.adGroupId}/${data.adId}`,
    {
      params: {
        current_page: data.page,
        page_size: data.pageSize,
        date_begin_current_period: data.startDate,
        date_end_current_period: data.endDate,
        order_by: data.orderBy,
        sort_order: data.sortOrder,
      },
    }
  );

  return response.data;
};

export const fetchAdGroupKeywordsReports = async (data: {
  clientId: number;
  adGroupId: number;
  page: string;
  pageSize: string;
  orderBy: string;
  startDate: string;
  endDate: string;
  sortOrder: string;
}) => {
  const response = await client.get(
    `/ea/report/adgroup_keywords_with_cumulative_metrics_table_by_adgroup/${data.clientId}/${data.adGroupId}`,
    {
      params: {
        current_page: data.page,
        page_size: data.pageSize,
        date_begin_current_period: data.startDate,
        date_end_current_period: data.endDate,
        order_by: data.orderBy,
        sort_order: data.sortOrder,
      },
    }
  );

  return response.data;
};

export const fetchAdGroupKeywordsMetrics = async (data: {
  clientId: number;
  searchTermId: number;
  page: string;
  pageSize: string;
  orderBy: string;
  startDate: string;
  endDate: string;
  sortOrder: string;
}) => {
  const response = await client.get(
    `/ea/report/ad-group-keyword-metrics-table/${data.clientId}/${data.searchTermId}`,
    {
      params: {
        current_page: data.page,
        page_size: data.pageSize,
        date_begin_current_period: data.startDate,
        date_end_current_period: data.endDate,
        order_by: data.orderBy,
        sort_order: data.sortOrder,
      },
    }
  );

  return response.data;
};

export const fetchSearchTermMetrics = async (data: {
  clientId: number;
  adGroupKeywordId: number;
  page: string;
  pageSize: string;
  orderBy: string;
  startDate: string;
  endDate: string;
  sortOrder: string;
}) => {
  const response = await client.get(
    `/ea/report/searchterm-metrics-table/${data.clientId}/${data.adGroupKeywordId}`,
    {
      params: {
        current_page: data.page,
        page_size: data.pageSize,
        date_begin_current_period: data.startDate,
        date_end_current_period: data.endDate,
        order_by: data.orderBy,
        sort_order: data.sortOrder,
      },
    }
  );

  return response.data;
};
