import { atom } from "recoil";

interface BreadcrumbItem {
  level: number;
  id: number;
  text: string;
  type: number;
  typeStr: string;
}

export const adReportBreadCrumb = atom<BreadcrumbItem[]>({
  key: "projectDataOnboarding",
  default: [],
});
