import React, { useState, Fragment, useEffect, useRef } from "react";
import { Popover, Transition } from "@headlessui/react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
type Props = {
  startDate: string;
  endDate: string;
  handleChangeDate: (startDate: any, endDate: any) => void;
};

const DateRangeSelect = (props: Props) => {
  const { startDate, endDate, handleChangeDate } = props;
  const popoverRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState<any>([
    {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      key: "selection",
    },
  ]);

  const handleDate = () => {
    handleChangeDate(state[0].startDate, state[0].endDate);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "short", day: "2-digit" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <Popover className="relative h-auto " ref={popoverRef}>
      <Popover.Button
        onClick={() => setIsOpen(!isOpen)}
        className="inline-flex items-center  text-sm font-semibold focus:outline-none text-gray-900 p-2 ">
        <div className="flex justify-between gap-4 items-center border p-2   rounded-lg border-gray-400">
          <span className="text-sm">{formatDate(state[0].startDate)}</span>
          <span className="text-sm">
            <HiOutlineSwitchHorizontal />
          </span>
          <span className="text-sm">{formatDate(state[0].endDate)}</span>
        </div>
      </Popover.Button>
      <Transition
        as={Fragment}
        show={isOpen}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1">
        <Popover.Panel className="absolute right-0 z-10 mt-5 flex   px-4">
          <div className=" flex-auto overflow-hidden rounded-lg bg-white text-sm leading-6 shadow-lg ">
            <DateRangePicker
              onChange={(item: any) => setState([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              ranges={state}
              direction="horizontal"
              months={2}
              rangeColors={["#4F4999"]}
            />
            <div className="w-full flex justify-end px-4 pb-4">
              <button
                onClick={handleDate}
                className="px-4 py-2 rounded-lg bg-primaryColor text-white">
                Apply
              </button>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default DateRangeSelect;
