import { Combobox, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { useQuery, UseQueryResult, useMutation } from "react-query";
import { fetchAllPartners } from "@/api/partners";
import { fetchProjectData, postProjectData } from "@/api/project";
import { t } from "i18next";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { ProjectDataType } from "@/models/projectDataType";
import { toast } from "react-toastify";

interface Props {
  selectedPartner: any;
  setSelectedPartner: any;
  projectId: number;
}

function PartnerSelectBox(props: Props) {
  const { selectedPartner, setSelectedPartner, projectId } = props;

  /*********** */

  const {
    status,
    isError,
    data: partners,
    isLoading: partnersIsLoading,
    error,
  }: UseQueryResult<any, Error> = useQuery<any, Error>("partners", fetchAllPartners, {
    retry: false,
  });

  const { data: projectData, isLoading: projectIsLoading } = useQuery<any, Error>(
    "fetchAutoAssignPartner",
    () =>
      fetchProjectData({
        projectId,
        name: "AUTO_ASSIGN_PARTNER",
      }),
    {
      retry: false,
      enabled: !!projectId,
    }
  );

  useEffect(() => {
    if (projectData && projectData.data.value && partners) {
      const sPartner: any = {};
      for (const key of Object.keys(sPartner)) {
        sPartner[key] = projectData?.data?.value?.[key];
      }
      setSelectedPartner(projectData.data.value);
    }
  }, [projectData, partners]);

  const {
    mutateAsync,
    isLoading: isLoadingUpdate,
    status: statusForm,
  } = useMutation(postProjectData, {});

  const handleSubmit = () => {
    if (selectedPartner && projectId)
      mutateAsync({
        projectId: projectId,
        project: {
          datatype_name: ProjectDataType.AUTO_ASSIGN_PARTNER,
          data: {
            value: selectedPartner,
          },
        },
      }).then(() => {
        toast.info("Partner assigned successfully");
      });

    if (statusForm === "error") alert("Error while saving data");
  };

  /*********** */

  const [query, setQuery] = useState("");

  const filteredPartner = (role: string, lastQuery: string) => {
    if (lastQuery === "") return partners[role]?.partners;
    return partners[role]?.partners.filter((field: any) => {
      const fullName = field.first_name + " " + field.last_name;
      return fullName
        .toLowerCase()
        .replace(/\s+/g, "")
        .includes(lastQuery.toLowerCase().replace(/\s+/g, ""));
    });
  };

  if (status === "idle" || status === "loading") {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>{error.message}</div>;
  }

  const handleChange = (role: string, value: any) => {
    setSelectedPartner((prev: any) => {
      if (prev?.[role] === value) return { ...prev, [role]: undefined };
      return { ...prev, [role]: value };
    });
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 z-40 gap-4 flex-wrap my-4 ">
      {projectIsLoading || partnersIsLoading ? (
        <LoadingSpinner />
      ) : (
        Object.keys(partners)?.map((role) => {
          const filteredPeople = filteredPartner(role, query);
          return (
            <div className="relative col-span-auto" key={role}>
              <label className="block text-sm font-medium text-gray-700">
                {partners[role]?.title}
              </label>
              <Combobox value={selectedPartner?.[role]} onChange={(e) => handleChange(role, e)}>
                <div className="relative mt-1">
                  <div className="relative w-full text-left bg-white cursor-default focus:outline-none focus-visible:ring-2 rounded-md border border-gray-300 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-teal-300 focus-visible:ring-offset-2 sm:text-sm overflow-hidden">
                    <Combobox.Input
                      className="w-full border-none focus:outline-none focus:ring-0 py-2 pl-3 pr-10 text-sm leading-5 text-gray-900"
                      displayValue={(person: any) => {
                        const selectedPerson = partners[role]?.partners.find(
                          (p: any) => p.id === person
                        );
                        return selectedPerson
                          ? selectedPerson.first_name + " " + selectedPerson.last_name
                          : "";
                      }}
                      onChange={(event) => setQuery(event.target.value)}
                      placeholder={t("selectPartner")}
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2 ">
                      <ChevronDownIcon className="w-5 h-5 text-black" aria-hidden="true" />
                    </Combobox.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery("")}>
                    <Combobox.Options className="absolute z-30 w-full py-1 mt-1 overflow-auto text-sm bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {filteredPeople.length === 0 && query !== "" ? (
                        <div className="cursor-default select-none relative py-2 px-4 text-gray-700">
                          Nothing found.
                        </div>
                      ) : (
                        filteredPeople.map((field: any) => (
                          <Combobox.Option
                            key={field.id}
                            className={({ active }) =>
                              `cursor-default select-none relative py-2 pl-3 pr-4 ${
                                active ? "text-black bg-[#E1E1E1]" : "text-gray-900"
                              }`
                            }
                            value={field.id}>
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}>
                                  {field.first_name + " " + field.last_name}
                                </span>
                                {selected && (
                                  <span
                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                      active ? "text-black" : "text-teal-600"
                                    }`}></span>
                                )}
                              </>
                            )}
                          </Combobox.Option>
                        ))
                      )}
                    </Combobox.Options>
                  </Transition>
                </div>
              </Combobox>
            </div>
          );
        })
      )}
      <div className="col-span-full flex flex-row justify-end">
        <DefaultButton onClick={handleSubmit} className="w-max" isLoading={isLoadingUpdate}>
          Submit
        </DefaultButton>
      </div>
    </div>
  );
}

export default PartnerSelectBox;
