import { Divider } from "@/components/Divider";
import ModalLoading from "@/components/Loading/ModalLoading";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import { userAtom } from "@/store/atoms/user.atoms";
import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import {
  changeTaskStatus,
  fetchTaskDetail,
  makeAccept,
  makeApproveOrReject,
  makeAsignTask,
  makeReject,
  updateTask,
} from "@/api/task";
import { useQuery } from "react-query";
import CategoryLabel from "@/components/CategoryLabel";
import RightModal from "@/components/Modal/RightModal";
import StatusLabel from "@/components/StatusLabel";

import { getFileIcon, formatFileName } from "@/utils/formatters";
import { FaDownload, FaRegSadTear } from "react-icons/fa";
import Select from "@/components/FormElements/Select";
import { assigneeOptions } from "@/utils/globalOptions";
import DangerButton from "@/components/buttons/DangerButton";
import StatusSelect from "@/components/FormElements/StatusSelect";
import SecondaryButton from "@/components/buttons/SecondaryButton";
import FileUploader from "@/components/FormElements/FileUploader";
import { fetchStoryCategories } from "@/api/stories";
import { FormatDate } from "@/utils/FormatDate";
import { filterProps } from "recharts/types/util/types";
import { deleteProjectFile, fetchTaskFiles, postProjectFile } from "@/api/project";
import { dateFormatter, dateFormatterSelect } from "@/utils/dateFormatter";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { MdAdd, MdDelete, MdFileDownload } from "react-icons/md";
import BasicButton from "@/components/buttons/BasicButton";
import clock from "../../../assets/images/clock.gif";
import working from "../../../assets/images/working.gif";
import questionGif from "../../../assets/images/question.gif";
import sadGif from "../../../assets/images/sad.gif";
import { authMe } from "@/api/auth";
import PaymentModal from "@/features/profile/components/PaymentModal";
import ProfileInformationUpdate from "@/features/profile/components/ProfileInformationUpdate";
import { Tickets } from "@/components/Tickets";

type Props = {
  projectId: any;
  projectStoryId: any;
  open: boolean;
  size?: string;
  onClose: () => void;
  refetch?: () => void;
};

interface TaskFields {
  title?: string;
  description?: string;
  category?: any;
  status?: any;
  assignee?: any;
  dueDate?: string;
  comment?: string;
  file_ids?: [];
}

const TaskDetail = (props: Props) => {
  const { projectId, open, size, projectStoryId, onClose, refetch } = props;
  const [project] = useRecoilState(projectInfo);
  const planId = project.pricingPlan.id;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [comment, setComment] = useState("");
  const [selectedAssignee, setSelectedAssignee] = useState();
  const [loading, setLoading] = useState(false);
  const textareaTitleRef = useRef<HTMLTextAreaElement>(null);
  const textareaDescriptionRef = useRef<HTMLTextAreaElement>(null);

  const [formData, setFormData] = useState<TaskFields>();
  const [fileUpdateLoading, setFileUpdateLoading] = useState<any>(false);
  const [fileDeleteLoading, setFileDeleteLoading] = useState(false);
  const [modelOpen, setModalOpen] = useState("");
  const [rejectMessage, setRejectMessage] = useState("");

  useEffect(() => {
    if (textareaTitleRef.current) {
      textareaTitleRef.current.style.height = "auto";
      textareaTitleRef.current.style.height = `${textareaTitleRef.current.scrollHeight}px`;
    }
  }, [title]);

  useEffect(() => {
    if (textareaDescriptionRef.current) {
      textareaDescriptionRef.current.style.height = "auto";
      textareaDescriptionRef.current.style.height = `${textareaDescriptionRef.current.scrollHeight}px`;
    }
  }, [description]);

  const {
    data: categories,
    isLoading: categoriesLoading,
    isFetched: isFetchedCategories,
  } = useQuery("fetchCategories", () => fetchStoryCategories(), {
    retry: false,
    cacheTime: 0,
    refetchOnWindowFocus: true,
  });

  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(() => {
    if (isFetchedCategories && categories) {
      const data = categories.items.map((i: any) => ({
        id: i.id,
        value: i.value,
      }));
      setCategoryOptions(data);
    }
  }, [categoriesLoading]);

  const {
    data: taskData,
    isLoading,
    isFetched: isFetchedTaskData,
    isSuccess,
    refetch: refetchTaskDetail,
  } = useQuery(
    ["fetchTaskDetail", projectStoryId],
    () =>
      fetchTaskDetail({
        project_story_id: Number(projectStoryId),
      }),
    {
      retry: false,
      enabled: Boolean(projectStoryId),
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      setSelectedAssignee(taskData?.assignee?.id);
      setTitle(taskData?.title);
      setDescription(taskData.description);
    }
  }, [isFetchedTaskData]);

  const {
    data: taskFiles,
    isLoading: isLoadingTaskFiles,
    isFetched: isFetchedTaskFiles,
    isSuccess: isSuccessTaskFiles,
    refetch: refetchTaskFiles,
  } = useQuery(
    ["fetchTaskFiles", projectStoryId],
    () =>
      fetchTaskFiles({
        project_story_id: Number(projectStoryId),
      }),
    {
      retry: false,
      enabled: Boolean(projectStoryId),
      cacheTime: 0,
      onError: (error: any) => {
        if (error.response?.status === 404) {
          console.error("Dosyalar bulunamadı (404)");
        } else {
          console.error("Bir hata oluştu:", error);
        }
      },
    }
  );

  const handleAsign = async () => {
    setLoading(true);
    const body = {
      project_story_id: taskData.id,
      comment: formData?.comment,
    };
    try {
      const response = await makeAsignTask(body);
      console.log("Task assigned successfully:", response);
      setLoading(false);
      setFormData(undefined);
      refetchTaskDetail();
      if (refetch) {
        refetch();
      }
    } catch (error) {
      setLoading(false);
      console.error("Error creating task:", error);
    }
  };

  const handleApproveOrReject = async (status: string) => {
    setLoading(true);
    const body = {
      id: taskData.task_assign_request_id,
      status: status,
      ...(status === "rejected" && { reject_reason: rejectMessage }),
    };

    try {
      const response = await makeApproveOrReject(body);
      console.log("Task assigned successfully:", response);
      setLoading(false);
      refetchTaskDetail();
      if (status === "rejected") {
        setModalOpen("");
      }
      refetchTaskDetail();
      if (refetch) {
        refetch();
      }
    } catch (error) {
      setLoading(false);
      console.error("Error creating task:", error);
    }
  };

  const handleAccept = async () => {
    setLoading(true);
    const body = {
      project_story_id: taskData.id,
    };

    try {
      const response = await makeAccept(body);
      console.log("Task assigned successfully:", response);
      setLoading(false);
      if (refetch) {
        refetch();
      }
      refetchTaskDetail();
      setModalOpen("");
    } catch (error) {
      setLoading(false);
      console.error("Error creating task:", error);
    }
  };

  const handleReject = async () => {
    setLoading(true);
    const body = {
      project_story_id: taskData.id,
      reject_reason: rejectMessage,
    };

    try {
      const response = await makeReject(body);
      console.log("Task rejected successfully:", response);
      setLoading(false);
      if (refetch) {
        refetch();
      }
      refetchTaskDetail();
      setModalOpen("");
    } catch (error) {
      setLoading(false);
      console.error("Error creating task:", error);
    }
  };

  const handleChange = (key: string, value: any) => {
    if (key === "due_date") {
      const isoDate = new Date(value).toISOString();
      setFormData((prev) => ({
        ...prev,
        [key]: isoDate,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [key]: value ? value : "",
      }));
    }
  };

  const fields = [
    {
      label: "Title",
      value: "title",
      visible: true,
      editable: !taskData?.is_assigned && taskData?.status.id !== "cancelled",
    },
    {
      label: "Description",
      value: "description",
      visible: taskData?.description ? true : false,
      editable: !taskData?.is_assigned && taskData?.status.id !== "cancelled",
    },
    {
      label: "Pro Tip",
      value: "proTip",
      visible: taskData?.proTip ? true : false,
      editable: false,
    },
    {
      label: "Category",
      value: "label",
      visible: true,
      editable: !taskData?.is_assigned && taskData?.status.id !== "cancelled",
      renderCell: (data: any) =>
        taskData?.is_assigned ? (
          data
        ) : (
          <Select
            disabled={taskData?.asignee?.id === 1 ? true : false}
            placeholder="Enter"
            basic
            data={categoryOptions}
            defaultValue={categoryOptions.find((item: any) => item.value === data)}
            onChange={(selected: any) => handleChange("category", selected)}
          />
        ),
    },
    {
      label: "Due Date",
      value: "due_date",
      visible: true,
      editable: !taskData?.is_assigned && taskData?.status.id !== "cancelled",
      renderCell: (data: any) => (
        <>
          <input
            disabled={taskData?.is_assigned}
            type="date"
            defaultValue={dateFormatterSelect(data)}
            className="focus:outline-none w-full"
            onChange={(e) => handleChange("dueDate", e.target.value)}
          />
        </>
      ),
    },
    {
      label: "Status",
      value: "status",
      visible: true,
      editable: !taskData?.is_assigned && taskData?.status.id !== "cancelled",
      renderCell: (data: any) =>
        selectedAssignee === 1 ? (
          <StatusLabel status={data} />
        ) : (
          <StatusSelect
            disabled={selectedAssignee === 1 ? true : false}
            placeholder="Enter"
            basic
            defaultValue={taskData?.status}
            onChange={(selected: any) => handleChange("status", selected)}
          />
        ),
    },
    {
      label: "Assignee",
      value: "assignee",
      visible: true,
      editable: !taskData?.is_assigned && taskData?.status.id !== "cancelled",
      renderCell: (data: any) =>
        data && taskData?.is_assigned ? (
          data?.value
        ) : (
          <Select
            placeholder="Enter"
            basic
            data={assigneeOptions}
            defaultValue={data}
            onChange={(selected: any) => setSelectedAssignee(selected)}
          />
        ),
    },
    {
      label: "Price",
      value: "price",
      visible: taskData?.price ? true : false,
      editable: false,
      renderCell: (data: any) => "$" + data,
    },

    {
      label: "Operating Time",
      value: "operating_time",
      visible: taskData?.operating_time ? true : false,
      editable: false,
      renderCell: (data: any) => data + " days",
    },
    {
      label: "Files",
      value: "files",
      visible: true,
      editable: !taskData?.is_assigned && taskData?.status.id !== "cancelled",
      renderCell: (data: any) => (
        <>
          <>
            <Divider />
            {fileUpdateLoading || isLoadingTaskFiles ? (
              <>
                <span className="text-sm font-[600] ">Files</span>
                <div className="flex justify-center w-full">
                  <LoadingSpinner size="w-6 h-6" />
                </div>
              </>
            ) : (
              taskFiles?.length !== 0 && (
                <>
                  <span className="text-sm font-[600] ">Files</span>
                  {taskFiles?.map((file: any, index: number) => (
                    <div
                      key={index}
                      className="w-full flex flex-row items-center justify-between gap-1 p-2 rounded-lg border border-gray-300 bg-white shadow-md">
                      <div className="flex items-center gap-1">
                        {getFileIcon(file, file.file_name, file.file_url)}
                        <span className="text-xs  text-primaryColor">
                          {formatFileName(file.file_name, 24)}
                        </span>
                      </div>
                      <div className="flex items-center ">
                        <BasicButton
                          onClick={() => window.open(file.file_url, "_blank")}
                          icon={<MdFileDownload />}
                        />

                        {!taskData?.is_assigned && (
                          <BasicButton
                            loading={fileDeleteLoading}
                            onClick={() => handleDeleteFile(file.id)}
                            icon={<MdDelete className="text-red" />}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </>
              )
            )}
          </>
          {!taskData?.is_assigned && (
            <FileUploader showFileList={false} onSelect={(files) => uploadFiles(files)} />
          )}
        </>
      ),
    },
    {
      label: "Comment",
      value: "comment",
      visible: taskData?.is_assigned && !taskData?.comment ? false : true,
      editable: !taskData?.is_assigned && taskData?.status.id !== "cancelled",
    },
    {
      label: "rejectReason",
      value: "reject_reason",
      visible: taskData?.reject_reason,
      editable: false,
      renderCell: (data: any) => (
        <div className="flex flex-col rounded-lg w-full py-2 px-3  text-red-900 bg-red bg-opacity-20 gap-y-1 text-sm">
          <span className="font-semibold">Reject reason</span>
          {taskData?.reject_reason}
        </div>
      ),
    },
  ];

  const uploadFiles = async (files: any) => {
    const fileIds: number[] = [];
    if (files) {
      setFileUpdateLoading(true);
      for (const file of files) {
        try {
          const response = await postProjectFile({
            project_id: projectId,
            file_in: file,
          });
          fileIds.push(response.id);
        } catch (error) {
          console.error("File upload error:", error);
        }
      }
    }

    handleChange("file_ids", fileIds);
  };

  const handleSubmit = async () => {
    try {
      const response = await updateTask(formData, taskData?.id);

      if (fileUpdateLoading) {
        refetchTaskFiles();
      }

      setFormData(undefined);
      setFileUpdateLoading(false);
      refetchTaskDetail();
      if (refetch) {
        refetch();
      }
    } catch (error) {
      console.error("Error creating task:", error);
    }
  };

  useEffect(() => {
    if (formData) {
      handleSubmit();
    }
  }, [formData]);

  const handleDeleteFile = async (id: number) => {
    setFileDeleteLoading(true);
    try {
      await deleteProjectFile(id);
      refetchTaskFiles();
      setFileDeleteLoading(false);
    } catch (error) {
      console.error("File upload error:", error);
      setFileDeleteLoading(false);
    }
  };

  const handleClose = () => {
    setTitle("");
    setDescription("");
    setFormData(undefined);
    onClose();
  };

  return (
    <RightModal
      size={size}
      open={open}
      onClose={() => handleClose()}
      action={
        isLoading ||
        (!taskData?.is_assigned &&
          selectedAssignee !== 1) ? null : taskData?.show_approve_button ? (
          <>
            <DangerButton
              loading={loading}
              disabled={loading}
              onClick={() => setModalOpen("reject")}
              name="Reject"
              className="  w-6/12"
            />
            <SecondaryButton
              loading={loading}
              disabled={loading}
              onClick={() => {
                taskData?.status.id === "inreview"
                  ? handleAccept()
                  : handleApproveOrReject("accepted");
              }}
              name="Approve"
              className="  w-6/12"
            />
          </>
        ) : taskData?.status.id === "pending" || taskData?.status.id === "inprogress" ? (
          <div className="flex w-full justify-between items-center gap-2">
            <span className="text-xs text-left font-semibold text-primaryColor ">
              {taskData?.status.id === "pending"
                ? "The Mikrolo team will determine the pricing and timeline, and you’ll receive an update soon."
                : "The Mikrolo team is currently working on it, and it will be submitted for your approval shortly."}
            </span>
            <img
              src={taskData?.status.id === "pending" ? clock : working}
              className="w-10 h-10"
              alt="clock"
            />
          </div>
        ) : !taskData?.is_assigned &&
          selectedAssignee === 1 &&
          taskData?.status.id !== "cancelled" ? (
          <PrimaryButton
            loading={loading}
            onClick={() => handleAsign()}
            name="Assign"
            className="  w-full"
          />
        ) : null
      }>
      {isLoading || !projectStoryId ? (
        <ModalLoading />
      ) : (
        <div className="flex flex-col w-full">
          <div className="w-full he-full flex  flex-1 flex-col justify-start items-start text-left  px-6 pt-10 gap-y-3  ">
            {fields.map(
              (item: any, index: number) =>
                item.visible &&
                (item.label === "Title" ? (
                  <textarea
                    disabled={!item.editable}
                    ref={textareaTitleRef}
                    className="w-full  text-2xl font-[500] py-2 focus:outline-0 resize-none overflow-hidden"
                    placeholder="Enter task title"
                    value={title}
                    defaultValue={taskData[item.value]}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      setTitle(e.target.value)
                    }
                    onBlur={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      handleChange("title", e.target.value)
                    }
                    rows={1}
                  />
                ) : item.label === "Description" ? (
                  <textarea
                    disabled={!item.editable}
                    ref={textareaDescriptionRef}
                    className="w-full border-b border-gray-300 text-sm  pb-3  focus:outline-0 resize-none overflow-hidden"
                    placeholder="Write your brief here."
                    value={description}
                    defaultValue={taskData[item.value]}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      setDescription(e.target.value)
                    }
                    onBlur={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      handleChange("description", e.target.value)
                    }
                    rows={1}
                  />
                ) : item.label !== "Files" &&
                  item.label !== "Comment" &&
                  item.label !== "rejectReason" ? (
                  item.visible && (
                    <div className="flex items-center justify-between text-sm w-full">
                      <div className="w-5/12 font-[500] ">{item.label} </div>
                      <div className="w-7/12">
                        {item.renderCell
                          ? item.renderCell(taskData[item.value])
                          : taskData[item.value]}
                      </div>
                    </div>
                  )
                ) : item.label === "Comment" ? (
                  <>
                    <Divider />
                    <div className="flex flex-col gap-2 w-full">
                      <span className="text-sm font-semibold">Comment </span>
                      <textarea
                        disabled={!item.editable}
                        className="w-full  text-sm  py-2 focus:outline-0 resize-none overflow-hidden bg-white"
                        placeholder="Add comment"
                        defaultValue={taskData[item.value]}
                        onBlur={(e) => handleChange("comment", e.target.value)}
                        rows={4}
                      />
                    </div>
                  </>
                ) : (
                  item.renderCell(taskData[item.value])
                ))
            )}
            {taskData?.status.id === "inprogress" && taskData?.is_assigned === true && (
              <div className="border border-gray-300 rounded-lg w-full h-auto p-3 gap-3 shadow-lg flex items-center justify-start">
                <div className="flex flex-col items-start ">
                  <h2 className="text-md font-semibold">Have a question?</h2>
                  <span className="text-sm font-light ">
                    Our team is always here to answer your questions.
                  </span>
                  <button
                    className="text-left font-semibold mt-1  w-auto text-sm bg-primaryColor text-white px-2 py-1 rounded-md"
                    onClick={() => setModalOpen("ticket")}>
                    Contact Us
                  </button>
                </div>
                <img src={questionGif} className="w-16 h-16" alt="question-icon" />
              </div>
            )}
          </div>
        </div>
      )}

      <RightModal
        size="w-3/12"
        open={modelOpen === "reject"}
        onClose={() => setModalOpen("")}
        action={
          <DangerButton
            disabled={rejectMessage ? false : true}
            loading={loading}
            onClick={() => {
              taskData?.status.id === "inreview"
                ? handleReject()
                : handleApproveOrReject("rejected");
            }}
            name="Reject"
            className="w-full"
          />
        }>
        <div className="flex flex-col w-full">
          <div className="w-full he-full flex  flex-1 flex-col justify-start items-center text-center  px-6 pt-14 gap-y-3  ">
            <img src={sadGif} className="w-14 h-14" alt="sad-face" />
            <h2 className="text-lg text-center font-bold">We’re Sorry to Hear That</h2>
            <span>
              Please provide your reason in the notes section below. We will address your concerns
              promptly.
            </span>
            <textarea
              className="w-full  text-md p-4 focus:outline-0 resize-none overflow-hidden border border-gray-300 rounded-lg  "
              placeholder="Write your reason here."
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setRejectMessage(e.target.value)
              }
              rows={8}
            />
          </div>
        </div>
      </RightModal>

      {modelOpen === "payment" && (
        <PaymentModal
          onClose={() => setModalOpen("")}
          size="w-3/12"
          open={modelOpen === "payment"}
        />
      )}

      {modelOpen === "ticket" && (
        <Tickets
          projectStoryId={taskData?.id}
          projectId={projectId}
          onClose={() => setModalOpen("")}
          open={modelOpen === "ticket"}
          limit={1}
        />
      )}
    </RightModal>
  );
};

export default TaskDetail;
