import React from "react";
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { fetchGoogleAdsAccounts, postGoogleAdsAccounts } from "@/api/eA";
import { useMutation, useQuery } from "react-query";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import Select from "react-select";
import { createIntegrationEvent } from "@/analytics/mixpanel";
import { userAtom } from "@/store/atoms/user.atoms";
import { useRecoilState } from "recoil";
import { fetchProjectData, postProjectData } from "@/api/project";
import { ProjectDataType } from "@/models/projectDataType";
import { t } from "i18next";

const AdsPropertiesPage = () => {
  const { projectId, isLoading: isLoadingIds } = useFetchingProjectIds();
  const [selectedAccount, setSelectedAccount] = React.useState<number | null>(null);
  const [selectedCustomerClient, setSelectedCustomerClient] = React.useState<number | null>(null);

  const [showSuccess, setShowSuccess] = React.useState(false);
  const [user] = useRecoilState(userAtom);
  const [showError, setShowError] = React.useState("");

  const { data, isLoading: isLoadingAccounts } = useQuery(
    "fetchGoogleAdsAccounts",
    () => fetchGoogleAdsAccounts(projectId),
    {
      enabled: !!projectId,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const { mutateAsync, isLoading } = useMutation(postGoogleAdsAccounts);

  const handleAddAccount = () => {
    setShowError("");
    if (!selectedAccount) {
      alert("Please select an account");
      return;
    }
    if (!selectedCustomerClient) {
      alert("Please select a customer client");
      return;
    }

    mutateAsync({
      project_id: projectId,
      google_ads_account_id: selectedCustomerClient,
    })
      .then(() => {
        createIntegrationEvent({
          connectionStatus: "success",
          integrationName: "google_ads",
          userId: user?.id.toString() || "",
          email: user?.email || "",
        });
        setShowSuccess(true);
        handleGettingStart("connectAccount");
      })
      .catch((e) => {
        if (e.response && e.response?.data && e.response?.data?.detail) {
          const message = JSON.parse(e.response?.data?.detail);
          setShowError(message?.message);
        }
      });
  };

  const { mutateAsync: postMutate } = useMutation("postProjectDomainData", postProjectData, {
    retry: false,
  });

  const handleGettingStart = (key: string) => {
    fetchProjectData({ projectId: projectId, name: "GETTING_STARTED" })
      .then((res) => {
        const data = res.data.value;

        postMutate({
          projectId,
          project: {
            datatype_name: ProjectDataType.GETTING_STARTED,
            data: {
              value: {
                ...data,
                [key]: true,
              },
            },
          },
        });
      })
      .catch(() => {
        postMutate({
          projectId,
          project: {
            datatype_name: ProjectDataType.GETTING_STARTED,
            data: {
              value: {
                [key]: true,
              },
            },
          },
        });
      });
  };

  const selectedAccountData = data?.find((item: any) => item.id === selectedAccount);

  return (
    <div className="h-screen w-screen flex justify-center items-center relative">
      {isLoadingIds || isLoadingAccounts ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className=" w-full flex flex-col items-center justify-center ">
            <div className="w-full  text-gray-700 italic underline absolute left-8 top-5 ">
              <a href="/integrations">{"<"} Back to Integrations page </a>
            </div>
            <div className="flex flex-col items-center justify-center w-full  max-w-lg gap-8 border p-8 rounded">
              <h1 className="text-2xl font-bold">Google Ads Accounts</h1>
              <div className="w-full">
                <div>
                  <label className="text-gray-700" htmlFor="account">
                    Select Account:
                  </label>
                </div>
                <Select
                  onChange={(e: any) => setSelectedAccount(Number(e.value))}
                  options={data?.map((item: any) => ({
                    value: item.id,
                    label: item.descriptiveName || item.id,
                  }))}
                />
                {selectedAccount && selectedAccountData?.customerClients && (
                  <>
                    <div>
                      <label className="text-gray-700" htmlFor="customerClient">
                        Select Customer Client:
                      </label>
                    </div>
                    <Select
                      onChange={(e: any) => {
                        setSelectedCustomerClient(Number(e.value))
                      }}
                      options={selectedAccountData.customerClients.map((client: any) => ({
                        value: client.id,
                        label: client.descriptiveName || client.id,
                      }))}
                    />
                  </>
                )}
              </div>

              <div className="flex justify-end w-full">
                <DefaultButton onClick={handleAddAccount} isLoading={isLoading} className="w-fit">
                  Add Account
                </DefaultButton>
              </div>
            </div>
            {showSuccess && (
              <div className=" max-w-lg p-3 bg-green-200 rounded mt-3 text-sm">
                {t("adsPropertiesPageSuccess")}
                <a href="/integrations" className="text-blue-500 underline">
                  {t("goBack")}
                </a>
              </div>
            )}
            {showError && (
              <div className="normal-case max-w-lg p-3 bg-red-200 rounded mt-3 text-sm">
                Error: {showError}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AdsPropertiesPage;
