import React, { Fragment, useContext, useEffect, useState } from "react";
import { Transition, Popover } from "@headlessui/react";
import {
  CalendarDaysIcon,
  CalendarIcon,
  MapIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import IntegrationIcon from "@/assets/jsx/integration";
import TasksIcon from "@/assets/jsx/tasks";
import ReportIcon from "@/assets/jsx/report";

import { Link, NavLink, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userAtom } from "@/store/atoms/user.atoms";
import { t } from "i18next";
import { USER_TYPE, USER_TYPE_MVP2 } from "@/models/userType";
//import { tr } from "date-fns/locale";
import { MdMenu, MdOutlineCreate, MdSearch } from "react-icons/md";
import { TbReportAnalytics, TbRocket } from "react-icons/tb";

import SecondaryButton from "@/components/buttons/SecondaryButton";
import TextField from "@/components/FormElements/TextField";
import CircleButton from "@/components/buttons/CircleButton";
import { GlobalContext, GlobalState } from "@/context/GlobalStateProvider";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import { Notification } from "./Notification";
import { ProfileMenu } from "./ProfileMenu";
import { FaRegCalendarAlt, FaRegMap } from "react-icons/fa";
import { BiTask } from "react-icons/bi";
import { Icon } from "@mui/material";
import { BsPlug } from "react-icons/bs";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

type Props = {
  children: React.ReactNode;
  searchBar?: boolean;
};

export default function Sidebar(props: Props) {
  const { globalState, setGlobalState } = useContext(GlobalContext);
  const location = useLocation();
  const { searchBar } = props;

  const navigation: any = [
    {
      key: "strategies",
      name: "Strategies",
      href: "/strategies",
      icon: <FaRegMap />,
      current: false,
    },
    { key: "tasks", name: "Tasks", href: "/tasks", icon: <BiTask />, current: false },
    {
      key: "reports",
      name: t("reports"),
      href: "/reports",
      icon: <TbReportAnalytics />,
      current: false,
    },
    {
      key: "contentCalendar",
      name: "Social Media Calendar",
      href: "/calendar",
      icon: <FaRegCalendarAlt />,
      current: false,
    },
    {
      key: "autoDesign",
      name: "Auto Design",
      href: "/automated-designer",
      icon: <MdOutlineCreate />,
      current: false,
    },
    {
      key: "autoPilot",
      name: "Auto Pilot",
      href: "/auto-pilot",
      icon: <TbRocket />,
      current: false,
    },
    {
      key: "integrations",
      name: "Integrations",
      href: "/integrations",
      icon: <BsPlug />,
      current: false,
    },
  ];

  const [user] = useRecoilState(userAtom);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1024px)");

    const handleMediaQueryChange = (event: any) => {
      setSidebarOpen(event?.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    setSidebarOpen(mediaQuery.matches);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  const handleSearch = (value: string) => {
    setGlobalState((prev: GlobalState) => ({
      ...prev,
      searchQuery: value,
    }));
  };

  useEffect(() => {
    setGlobalState((prev) => ({
      ...prev,
      searchQuery: "",
    }));
  }, [location.pathname]);

  return (
    <>
      <div className="min-h-screen">
        <Popover>
          <Transition
            as={Fragment}
            show={sidebarOpen}
            enter="transition ease-out duration-200"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in duration-150"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full">
            <div className="fixed h-screen inset-y-0  w-7/12 md:w-4/12 lg:w-64 flex flex-col border-r border-gray-200  z-40 bg-white">
              <div className="h-[70px] flex flex-shrink-0 items-center px-6">
                <Link to="/">
                  <img
                    className="h-8 w-auto"
                    src="/assets/icons/logoPrivate.svg"
                    alt="Your Company"
                  />
                </Link>
              </div>
              <div className=" flex flex-shrink-0 flex-1 flex-col overflow-y-auto">
                <nav className="pt-3 px-3">
                  <div className="space-y-1">
                    {navigation.map((item: any) => {
                      return (
                        <React.Fragment key={item.key}>
                          {(user?.role === USER_TYPE || user?.role === USER_TYPE_MVP2) &&
                          item.key !== "home" &&
                          item.key !== "strategies" &&
                          item.key !== "tasks" ? (
                            <>
                              <div
                                className={classNames(
                                  "text-gray-700 hover:text-gray-900",
                                  "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                )}>
                                <>
                                  <span className="text-xl mr-2">{item.icon}</span>
                                  <div className="flex justify-between w-full text-gray-300">
                                    {item.name}
                                  </div>
                                </>
                              </div>
                            </>
                          ) : (
                            <NavLink
                              to={item.href}
                              children={({ isActive }) => (
                                <>
                                  <span className="text-xl mr-2">{item.icon}</span>
                                  <div className="flex justify-between w-full">{item.name}</div>
                                </>
                              )}
                              className={({ isActive }) =>
                                classNames(
                                  isActive
                                    ? "bg-gray-100 text-black "
                                    : "text-gray-700 hover:text-gray-900 hover:bg-gray-50",
                                  "group flex items-center px-2 py-2 text-sm font-medium rounded-md "
                                )
                              }
                            />
                          )}
                        </React.Fragment>
                      );
                    })}
                  </div>
                </nav>
              </div>
              <div className="px-5 py-2">
                <Link to="/help-center">
                  <div className="flex items-center cursor-pointer">
                    <QuestionMarkCircleIcon
                      className="text-gray-500 group-hover:text-gray-600 mr-3 flex-shrink-0 h-6 w-6"
                      aria-hidden="true"
                    />
                    <div className="group flex items-center  py-2 text-sm font-medium rounded-md text-gray-700 hover:text-gray-900 ">
                      Help Center
                    </div>
                  </div>
                </Link>
              </div>
              <div className="px-5 pb-2  ">
                <a href="https://calendly.com/alican-ercan/mikrolo-product" target={"_blank"}>
                  <div className="flex items-center cursor-pointer">
                    <CalendarDaysIcon
                      className="text-gray-500 group-hover:text-gray-600 mr-3 flex-shrink-0 h-6 w-6"
                      aria-hidden="true"
                    />
                    <div className="group flex items-center  py-2 text-sm font-medium rounded-md text-gray-700 hover:text-gray-900 ">
                      Book a Demo
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </Transition>
          {sidebarOpen && (
            <div
              onClick={() => setSidebarOpen(false)}
              className="fixed lg:hidden z-20 bg-black opacity-20	 w-screen h-screen"
            />
          )}
        </Popover>

        <div className="flex flex-col  relative">
          <div
            className={
              "fixed keft-0 lg:left-[16rem] bg-white flex justify-between items-center w-full border-b h-[70px] px-6 gap-2 z-10 " +
              (sidebarOpen ? "w-full lg:w-[calc(100vw-16rem)] " : " w+full")
            }>
            <div className="flex  w-3/12 lg:flex-1 items-center gap-2">
              <PrimaryButton
                outline
                className="md:block lg:hidden"
                onClick={() => setSidebarOpen(!sidebarOpen)}
                icon={<MdMenu />}
              />
              {searchBar && (
                <TextField
                  className="hidden lg:flex w-6/12"
                  placeholder="Search"
                  icon={<MdSearch />}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              )}
            </div>
            <div className="flex lg:hidden items-center">
              <Link to="/">
                <img
                  className="h-8 w-auto"
                  src="/assets/icons/logoPrivate.svg"
                  alt="Your Company"
                />
              </Link>
            </div>
            <div className="flex w-3/12 lg:w-auto justify-end items-center gap-3">
              <Notification />
              <ProfileMenu />
            </div>
          </div>
          <main
            className={
              "min-h-screen pt-[100px] px-4 sm:px-6 lg:px-6 " +
              (sidebarOpen ? "lg:ml-64" : " lg:ml-0")
            }>
            {props.children}
          </main>
        </div>
      </div>
    </>
  );
}
