import { getBrowserDefaultLanguage } from "@/api/client";
import { USER_TYPE } from "@/models/userType";
import { userAtom } from "@/store/atoms/user.atoms";
import {
  BuildingOfficeIcon,
  CreditCardIcon,
  UserIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/20/solid";
import { TrophyIcon } from "@heroicons/react/24/outline";
import { t } from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface Props {
  setSelectedTab: (tab: string) => void;
  selectedTab: string;
}

export default function Tabs(props: Props) {
  const navigate = useNavigate();
  const [user] = useRecoilState(userAtom);
  const language = getBrowserDefaultLanguage();

  const tabs: any = [
    {
      name: t("personalInformation"),
      identifier: "profile",
      href: "/profile",
      icon: UserIcon,
      display: true,
    },
    {
      name: t("projects"),
      identifier: "projects",
      href: "/profile/projects",
      icon: BuildingOfficeIcon,
      display: true,
    },
    {
      name: t("subscriptinPlans"),
      identifier: "subscription",
      href: "/profile/plans",
      icon: CurrencyDollarIcon,
      display: true,
    },
    {
      name: t("earnadoPlans"),
      identifier: "earnado",
      href: "/profile/earnado-plans",
      icon: TrophyIcon,
      display: false,
    },
    {
      name: t("billing"),
      identifier: "billing",
      href: "/profile/billing",
      icon: CreditCardIcon,
      display: true,
    },
  ];

  const { setSelectedTab, selectedTab } = props;
  const location = useLocation();

  return (
    <div>
      <div className="sm:hidden my-2">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          onChange={(e) => {
            setSelectedTab(e.target.value);
            navigate(e.target.value);
          }}
          className="block py-2 w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
          {tabs.map((tab: any) => (
            <option selected={location.pathname === tab.href} value={tab.href} key={tab.name}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs
              .filter((tab: any) =>
                user?.role === USER_TYPE
                  ? tab.identifier === "profile" || tab.identifier === "projects"
                  : tab.display
                  ? true
                  : false
              )
              .map((tab: any) => (
                <button
                  key={tab.name}
                  onClick={() => {
                    navigate(tab.href);
                  }}
                  className={classNames(
                    selectedTab === tab.identifier
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm"
                  )}>
                  <tab.icon
                    className={classNames(
                      selectedTab === tab.identifier
                        ? "text-indigo-500"
                        : "text-gray-400 group-hover:text-gray-500",
                      "-ml-0.5 mr-2 h-5 w-5"
                    )}
                    aria-hidden="true"
                  />
                  <span>{tab.name} </span>
                </button>
              ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
