import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { MdOutlineRadioButtonChecked } from "react-icons/md";
import { FaCaretDown } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

type Props = {
  clientId: string;
  options?: any;
};

const ClientSwitchMenu = (props: Props) => {
  const navigate = useNavigate();
  const params: any = useParams();
  const projectId = params?.id;
  const reportId = params?.reportId;
  const { clientId, options } = props;
  const [activeClient, setActiveClient] = useState<any>([]);

  useEffect(() => {
    if (clientId) {
      const client = options.find((i: any) => i.clientId == clientId);
      setActiveClient(client);
    }
  }, [options, clientId]);

  const handleChange = (id: number) => {
    navigate(`/reports/ad-reports/${projectId}/${reportId}/${id}`);
  };

  return (
    <Menu as="div" className="relative inline-block text-left w-full lg:w-[30%] ">
      <Menu.Button className="flex justify-between items-center min-w-6/12  w-full">
        <div className="flex flex-col items-start ">
          <span className="text-sm">Client</span>
          <h2 className="flex items-center text-2xl leading-6 text-gray-900 font-bold ">
            {activeClient?.clientName}
            <span className="ml-2 font-medium text-sm text-gray-500">
              {"(" + activeClient?.clientId + ")"}
            </span>
          </h2>
        </div>
        {options.length > 1 && <FaCaretDown className="text-gray-500" />}
      </Menu.Button>

      {options.length > 1 && (
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="absolute left-0 z-10 mt-4 w-full origin-top-right border rounded-md bg-white shadow-xl ">
            <div className="">
              {options.map((i: any, index: number) => (
                <Menu.Item
                  onClick={() => handleChange(i.clientId)}
                  key={index}
                  as="button"
                  className="flex justify-between items-center w-full hover:bg-gray-100 p-3 border-b">
                  <div className="flex flex-col items-start">
                    <span className="font-semibold text-md">{i.clientName} </span>
                    <span className="text-xs text-gray-500">{"Client ID : " + i.clientId} </span>
                  </div>
                  {clientId == i.clientId ? (
                    <MdOutlineRadioButtonChecked className="text-primaryColor" />
                  ) : null}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      )}
    </Menu>
  );
};

export default ClientSwitchMenu;
