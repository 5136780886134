import { BsFileZipFill } from "react-icons/bs";
import { FaFileAlt, FaFileExcel, FaFilePdf, FaFileWord } from "react-icons/fa";

export const getFileIcon = (file: any, fileName: string, fileUrl?: string) => {
  const extension = fileName?.split(".").pop()?.toLowerCase();
  switch (extension) {
    case "zip":
      return <BsFileZipFill className="text-3xl text-primaryColor" />;
    case "pdf":
      return <FaFilePdf className="text-3xl text-red-500" />;
    case "doc":
    case "docx":
      return <FaFileWord className="text-3xl text-blue-500" />;
    case "xls":
    case "xlsx":
      return <FaFileExcel className="text-3xl text-green-500" />;
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      return (
        <img
          src={fileUrl ? fileUrl : URL.createObjectURL(file)}
          alt={fileName}
          className="w-[30px] h-[30px] object-cover"
        />
      );
    default:
      return <FaFileAlt className="text-3xl text-secondaryHoverColor" />;
  }
};

export const formatFileName = (name: string, count: number) => {
  const dotIndex = name.lastIndexOf(".");
  const fileName = name.substring(0, dotIndex);
  const extension = name.substring(dotIndex);
  return fileName.length > count ? `${fileName.substring(0, count)}..${extension}` : name;
};
